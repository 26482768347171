// Application types

const USER = {
  CHECKING: "CHECKING",
  LOADING_AUTH: "LOADING_AUTH",
  ALL_USERS: "ALL_USERS",
  SEARCHING: "SEARCHING",
  SEARCH_USERS: "SEARCH_USERS",
  FILTERING: "FILTERING",
  FILTER_USERS: "FILTER_USERS",
  GET_USER: "GET_USER",
  DOWNLOADING: "DOWNLOADING",
  SINGLE_USER: "SINGLE_USER",
  SHOW_USER_ROLES: "SHOW_USER_ROLES",
};

export default USER;
