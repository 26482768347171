import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as CopyIcon } from "../../../../assets/images/icons/copy-outline.svg";
import { getSingleStoreOrder } from "../../../../redux/storeOrders/storeOrderAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function OrderStoreDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleStoreOrder } = useSelector((state) => state.storeOrder);

  useEffect(() => {
    if (id) {
      dispatch(getSingleStoreOrder(id));
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div data-test="singleOrder-page">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 pt-6">
        {/* Grid 1 */}
        <div className="h-full">
          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 pb-28 h-full">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Order Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order date
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {moment(singleStoreOrder?.created_at).format("MMM DD, YYYY")}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] dark:text-white text-sm font-bold flex">
                  {singleStoreOrder?.id}
                  <CopyIcon className="h-4 w-4 ml-1" />
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Insurance Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_insurance_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Status
                </label>
                <p className="text-primary text-sm font-bold">
                  {singleStoreOrder?.status === "unshipped" ? (
                    <p className="text-primary text-sm font-bold">unshipped</p>
                  ) : singleStoreOrder?.status === "shipped" ? (
                    <p className="text-[#046ECF] text-sm font-bold">Shipped</p>
                  ) : singleStoreOrder?.status === "closed" ? (
                    <p className="text-primary text-sm font-bold">Closed</p>
                  ) : singleStoreOrder?.status === "delivered" ? (
                    <p className="text-[#00D220] text-sm font-bold">
                      Delivered
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Total Amount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Payment Status
                </label>
                <p className=" text-sm font-bold">
                  {singleStoreOrder?.order_paid ? (
                    <span className="text-[#00D220]">Paid</span>
                  ) : (
                    <span className="text-[#9CA3AF] dark:text-[#BCBCBC]">Unpaid</span>
                  )}
                </p>
              </div>
              {singleStoreOrder?.order_payment_method !== null && (
                <div>
                  <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                    Payment Method
                  </label>
                  <p className="text-[#1634A1] text-sm font-bold">
                    {singleStoreOrder?.order_payment_method}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Grid 2 */}
        <div>
          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 mb-4 flex justify-between items-center">
              <h3 className="text-lg font-medium">
                Product Details (Optional)
              </h3>
            </div>

            {singleStoreOrder?.order_items?.length > 0 &&
              singleStoreOrder?.order_items?.map((product, i) => (
                <div key={i}>
                  <div className="flex justify-between items-start mb-5">
                    <div className="flex">
                      <span className="w-6 mr-3 sm:mr-10">
                        {product.quantity}x
                      </span>
                      <div>
                        <p className="mb-1">{product?.product_name}</p>
                        {product?.product_attributes?.length > 0 &&
                          product?.product_attributes?.map((attr, i) => (
                            <p key={i} className="text-sm text-[#676565]">
                              {attr.quantity}x {attr?.title} - NGN {attr.amount}
                            </p>
                          ))}
                      </div>
                    </div>
                    <p>NGN {product?.product_amount}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Store Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Store Name
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singleStoreOrder?.store?.store_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Store Email
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singleStoreOrder?.store?.store_email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Store Phone Number
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singleStoreOrder?.store?.store_phone}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Completed Orders
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">{0}</p>
              </div>
            </div>
          </div>

          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Customer Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singleStoreOrder?.recipient_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
                <p className="text-[#151515] dark:text-white text-sm  font-bold">
                  {singleStoreOrder?.recipient_email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Phone Number
                </label>
                <p className="text-[#151515] dark:text-white text-sm  font-bold">
                  {singleStoreOrder?.recipient_phone}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singleStoreOrder?.recipient_address}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
