import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../helpers/history";
import { Mixpanel } from "../../../plugins/mixpanel";
import Input from "../../inputs/inputs";
import { Beat } from "../../../plugins/spinners.plugin";
import { cleanInput } from "../../../helpers/cleanInput";
import { checkAUserAction } from "../../../redux/users/userActions";
import { registerUserAction } from "../../../redux/auth/authActions";

export default function RegisterForm({ formWizardRef, props }) {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [userCheck, setUserCheck] = useState({});
  const [input, setInput] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    referrer_code: "",
  });
  const { name, email, password } = input;
  const { isLoading } = useSelector((state) => state.auth);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
    // Check whether the username or email is taken
    if (value?.length > 3) {
      const checker = { [name]: value };
      try {
        const response = await dispatch(checkAUserAction(checker));
        if (response?.status === "success") {
          setUserCheck((userCheck) => ({
            ...userCheck,
            [name]: response?.data?.[name], // Merge new result while keeping previous ones
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (name && email && password) {
      cleanInput(input);
      try {
        const response = await dispatch(
          registerUserAction({ ...input, password_confirmation: password })
        );
        if (response?.status === "success") {
          props.onClose();
          formWizardRef.current.goTo(1);
          history("/user/home");
        }
      } catch (error) {
        console.log(error);
        if (
          error?.response?.data?.message?.includes(
            "check your mailbox for the verification link"
          )
        ) {
          Mixpanel.track("Unsuccessful login");
          formWizardRef.current.goTo(7);
        }
      }
    }
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
      <div className="mt-12">
        <Input.Label
          title={"Username"}
          type="text"
          name="name"
          id="fake-hidden-user-to-stop-google-address-lookup"
          autoComplete="off"
          className="w-full h-12 border border-[#E4E4F3] rounded-lg focus-within:border-primary focus:border-primary focus:ring-0 sm:text-md"
          placeholder="Enter Username"
          onChange={handleChange}
        />
        {userCheck?.name && (
          <div className="text-xs text-red-500">Username is already taken</div>
        )}
        {submitted && !name && (
          <div className="text-xs text-red-500">Username is required</div>
        )}
      </div>

      <div className="mt-4">
        <Input.Label
          title={"Email Address"}
          type="text"
          name="email"
          id="fake-hidden-email-to-stop-google-address-lookup"
          autoComplete="off"
          className="w-full h-12 border border-[#E4E4F3] rounded-lg focus-within:border-primary focus:border-primary focus:ring-0 sm:text-md"
          placeholder="Enter Email Address"
          onChange={handleChange}
        />
        {userCheck?.email && (
          <div className="text-xs text-red-500">Email is already taken</div>
        )}
        {submitted && !email && (
          <div className="text-xs text-red-500">Email is required</div>
        )}
      </div>
      <div className="mt-4">
        <Input.Password
          title={"Password"}
          name="password"
          id="fake-hidden-password-to-stop-google-address-lookup"
          autoComplete="new-password"
          className="w-full h-12 border border-[#E4E4F3] rounded-lg focus-within:border-primary focus:border-primary focus:ring-0 sm:text-md"
          placeholder="Enter Password"
          onChange={handleChange}
        />
        {submitted && !password && (
          <div className="text-xs text-red-500">Password is required</div>
        )}
      </div>
      <div className="mt-4">
        <label
          htmlFor="referrer_code"
          className="block text-sm font-bold text-gray-700"
        >
          Referral Code (Optional)
        </label>
        <input
          type="text"
          name="referrer_code"
          id="fake-hidden-referral-to-stop-google-address-lookup"
          autoComplete="off"
          className="mt-3 h-12 py-4 rounded-lg px-2 border border-gray-300 focus:border-primary focus:outline-none sm:text-md w-full"
          placeholder="Enter referral code"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="mt-10 mb-6">
        <button
          type="submit"
          disabled={isLoading}
          className=" mt-5 w-full inline-flex justify-center h-12 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-bold text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
        >
          {isLoading ? <Beat color={"#ffffff"} /> : "Register"}
        </button>
      </div>
    </form>
  );
}
