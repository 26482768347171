import React, { useEffect,  } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Container from "../../../../components/container/container";
import Input from "../../../../components/inputs/inputs";
import { Beat } from "../../../../plugins/spinners.plugin";
import Background from "../../../../assets/images/pictures/create-store.png";
import { getAllTags } from "../../../../redux/productTags/productTagActions";
import { getAllCategories } from "../../../../redux/productCatergory/productCategoryAction";

export default function OnboardStore({
  handleChange,
  data,
  setData,
  handleSubmit,
  submitted,
  Location,
  locationId,
  locationList,
  setLocationList,
  setLocationId,
  handleRegion,
  store_phone,
  setStorePhone,
  handleBack,
  categories,
}) {
  const dispatch = useDispatch();
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const {
    store_name,
    store_email,
    store_address,
    store_city,
    store_state,
    store_country,
    store_description,
    store_categories,
  } = data;

  useEffect(() => {
    dispatch(getAllTags());
    dispatch(getAllCategories());
    // eslint-disable-next-line
  }, []);

  // const { allTags } = useSelector((state) => state.productTag);
  // const { allCategories } = useSelector((state) => state.productCatergory);
  const { isLoading } = useSelector((state) => state.onboard);

  return (
    <div>
      <div
        className="pt-4 sm:pt-2 sm:flex items-stretch items-center py-4 max-w-7xl w-full mx-auto sm:px-6 lg:px-6"
        data-test="createStoreComponent"
      >
        <div className="dark:bg-[#121212] sm:max-w-[550px] flex-1 px-4 sm:px-10 py-10 sm:rounded-tl-[25px] sm:rounded-bl-[25px] sm:border dark:border-none">
          <div className="mb-4">
            <h3 className="text-3xl mb-3 font-semibold text-black dark:text-white">
              Let's work together!
            </h3>
            <p>
              Ogwugo is your dedicated business service agency, helping you
              automate daily operations and grow your customer base. Let’s bring
              your vision to life! 🚀
            </p>
          </div>
          <div className="w-full py-4">
            <form name="form" id="business-form" onSubmit={handleSubmit}>
              <div className="">
                <div
                  className={
                    "mt-4" + (submitted && store_name ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Store Name"}
                    htmlFor={"store_name"}
                    type={"text"}
                    value={store_name}
                    name={"store_name"}
                    placeholder={"Enter store name"}
                    onChange={handleChange}
                  />
                </div>

                <div
                  className={
                    "mt-4" +
                    (submitted && store_address ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Store Address *"}
                    htmlFor={"store_address"}
                    value={store_address}
                    type={"text"}
                    name={"store_address"}
                    placeholder={"Enter store Address"}
                    onChange={handleChange}
                  />
                </div>

                {/* Phone and email */}
                <div className="grid sm:grid-cols-2 gap-4">
                  <div
                    className={
                      submitted && store_email ? " border-red-500" : ""
                    }
                  >
                    <Input.Label
                      title={"Store Email *"}
                      htmlFor={"store_email"}
                      value={store_email}
                      type={"email"}
                      name={"store_email"}
                      placeholder={"Enter store email address"}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={
                      submitted && store_phone ? " border-red-500" : ""
                    }
                  >
                    <Input.Phone
                      defaultCountry="NG"
                      title={"Store Phone Number"}
                      htmlFor={"store_phone"}
                      value={store_phone}
                      onChange={setStorePhone}
                    />
                  </div>
                </div>

                <div
                  className={
                    "mt-4" +
                    (submitted && store_description ? " border-red-500" : "")
                  }
                >
                  <Input.TextArea
                    title={"Store Description "}
                    htmlFor={"store_description"}
                    value={store_description}
                    type={"text"}
                    name={"store_description"}
                    placeholder={"Describe your store to us"}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    submitted && !store_categories ? "  border-red-500" : ""
                  }
                >
                  <label
                    htmlFor="store_categories"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    Store Category*
                  </label>
                  <div className="mt-2 ">
                    <select
                      name="store_categories"
                      className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[#121212]  focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                      onChange={handleChange}
                    >
                      <option>
                        {store_categories ?? "Select a store category"}
                      </option>
                      {categories()}
                    </select>
                    {submitted && store_categories?.length === 0 && (
                      <div className="text-primary text-xs ">
                        Store category is required
                      </div>
                    )}
                  </div>
                </div>
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({
                      ...locationList,
                      countryList: value,
                    });
                  }}
                  stateList={(value) => {
                    setLocationList({
                      ...locationList,
                      stateList: value,
                    });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                />
                <div
                  className={
                    "mt-4" +
                    (submitted && store_country ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="store_country"
                    className="block text-sm font-medium text-[#151515] dark:text-white"
                  >
                    Country*
                  </label>
                  <select
                    name="store_country"
                    className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF] dark:bg-[#121212]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select Country</option>

                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>

                {/* State and city */}
                <div className="grid sm:grid-cols-2 gap-4">
                  <div
                    className={
                      submitted && store_state ? " border-red-500" : ""
                    }
                  >
                    <label
                      htmlFor="store_state"
                      className="block text-sm font-medium text-[#151515] dark:text-white"
                    >
                      {" "}
                      State{" "}
                    </label>
                    <select
                      name="store_state"
                      className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF] dark:bg-[#121212]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          state_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                    >
                      <option>Select state</option>

                      {locationList.stateList &&
                        locationList.stateList.length > 0 &&
                        locationList.stateList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({
                                name: item.name,
                                id: item.id,
                              })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div
                    className={submitted && store_city ? " border-red-500" : ""}
                  >
                    <label
                      htmlFor="store_city"
                      className="block text-sm font-medium text-[#151515] dark:text-white"
                    >
                      {" "}
                      City{" "}
                    </label>
                    <select
                      name="store_city"
                      className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[#121212] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          city_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                    >
                      <option>Select a city</option>
                      {locationList.cityList &&
                        locationList.cityList.length > 0 &&
                        locationList.cityList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({
                                name: item.name,
                                id: item.id,
                              })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="items-center flex justify-between gap-4 sm:mt-10">
            <div className="items-center flex gap-2">
              <span className="w-[10px] h-[10px] rounded-full inline-block bg-[#A39D9D] dark:bg-white"></span>
              <span className="w-[10px] h-[10px] rounded-full inline-block bg-primary"></span>
              <span className="text-sm ml-4">Phase 2 of 2</span>
            </div>

            <div className="flex gap-4">
              <button
                onClick={handleBack}
                className="rounded-md px-4 sm:mb-0 shadow-sm px-3 py-3 border border-primary text-primary text-base font-medium bg-white text-sm"
              >
                Back
              </button>

              <button
                form="business-form"
                type="submit"
                className=" rounded-md min-w-[100px] shadow-sm px-4 py-3 bg-primary text-base font-medium text-white hover:bg-red-700 text-sm"
              >
                {isLoading ? <Beat color={"#ffffff"} /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
        <div
          className="hidden sm:block w-full flex-1 rounded-tr-[25px] rounded-br-[25px] bg-cover bg-center"
          style={{ backgroundImage: `url(${Background})` }}
        />{" "}
      </div>
    </div>
  );
}
