import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Switch } from "@headlessui/react";
import Button from "../../../../components/buttons/buttons";
// import AddFoodVariationModal from "../../../../components/modals/admin/attributes/addFoodVariationModal";
import EditFoodPriceModal from "../../../../components/modals/admin/food/editFoodPriceModal";
import { FeaturePaymentModal } from "../../../../components/modals/feature/featurePayment";
import FeatureFoodModal from "../../../../components/modals/feature/featureFood";
import ShareProductModal from "../../../../components/modals/shareProductModal";
import { getAllAttributesAction } from "../../../../redux/attribute/attributeActions";
import { getAllCategories } from "../../../../redux/productCatergory/productCategoryAction";
import { getAFoodAction } from "../../../../redux/food/foodActions";
// import { FeatureFoodItemModal } from "../../../../components/modals/feature/featureFoodItem";
import { getOutletFromCookies } from "../../../../utils/Auth";
import {
  approveFoodBySelfAction,
  unapproveFoodBySelfAction,
} from "../../../../redux/food/foodActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FoodDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const featureFoodRef = useRef(null);
  const shareProductRef = useRef(null);

  const { singleFood } = useSelector((state) => state.food);
  // const [isOpen, setIsOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [enabled, setEnabled] = useState();
  const [price, setPrice] = useState({
    id: singleFood?.id,
    food_amount: singleFood?.food_amount,
    food_discount: singleFood?.food_discount,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(getAFoodAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllAttributesAction());
    dispatch(getAllCategories());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEnabled(singleFood?.business_approved);
  }, [singleFood?.business_approved]);

  // function closeModal() {
  //   setIsOpen(false);
  // }
  // function openModal() {
  //   setIsOpen(true);
  // }
  function closeUpdateModal() {
    setOpenUpdate(false);
  }
  function openUpdateModal() {
    setOpenUpdate(true);
  }

  const handleApprove = () => {
    dispatch(approveFoodBySelfAction({ id: singleFood?.id }));
  };

  const handleUnapprove = () => {
    dispatch(unapproveFoodBySelfAction({ id: singleFood?.id }));
  };

  return (
    <>
      {/* <AddFoodVariationModal show={isOpen} onClose={closeModal} /> */}
      <EditFoodPriceModal
        show={openUpdate}
        onClose={closeUpdateModal}
        price={price}
        setPrice={setPrice}
      />
      <div data-test="editFood-page">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-3 sm:py-5 px-4 sm:px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">
              Food: {singleFood?.food_name}
            </h3>
            <div className="flex gap-3">
              <Button.Secondary
                title={"Edit Food"}
                onClick={() =>
                  navigate(
                    `/admin/${outlet?.restaurant_name}/food/edit/${singleFood?.id}`,
                    {
                      state: { food: singleFood },
                    }
                  )
                }
                className="px-5"
                type={"button"}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Food ID
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.id}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Category
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.food_categories?.length > 0 &&
                  singleFood?.food_categories.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Stock
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.stock}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Status
              </label>
              <p className="text-[#151515] dark:text-white text-sm capitalize">
                {singleFood?.food_operating_status}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Created On
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Number Sold
              </label>
              <p className="text-[#151515] dark:text-white text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Flag Count
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.flag}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                {singleFood?.business_approved
                  ? "Unpublish Food"
                  : "Publish Food"}
              </label>
              <Switch.Group as="div" className="flex">
                <Switch
                  checked={enabled}
                  onChange={(e) => {
                    setEnabled(e);
                    singleFood?.business_approved
                      ? handleUnapprove()
                      : handleApprove();
                  }}
                  className={classNames(
                    enabled ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            {/* Split payment details */}
            {/* <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Split Payment
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Initial Payment
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Payment Intervals
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Subsequent Payment
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Duration</label>
              <p className="text-[#151515] dark:text-white text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div> */}
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-3 sm:py-5 px-4 sm:px-8 mt-6 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Food Pricing</h3>
            <Button.Primary title={"Edit Price"} onClick={openUpdateModal} />
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Price
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                NGN {numberWithCommas(singleFood?.food_amount)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Discount
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                NGN {numberWithCommas(singleFood?.food_discount)}
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-3 sm:py-5 px-4 sm:px-8 mt-6 sm:mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Food Category</h3>
            </div>
            <div className="mt-6">
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-3">
                Category
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.food_categories?.length > 0 &&
                  singleFood?.food_categories?.map((category, i) => (
                    <span key={i}>{category}</span>
                  ))}
              </p>
            </div>
          </div>

          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-3 sm:py-5 px-4 sm:px-8 mt-6 sm:mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Food Tags</h3>
            </div>
            <div className="mt-6">
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-3">
                Tags
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.food_tags?.length > 0 &&
                  singleFood?.food_tags?.map((tag, i) => (
                    <span key={i}>{tag}, </span>
                  ))}
              </p>
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-3 sm:py-5 px-4 sm:px-8 mt-6 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <FeaturePaymentModal
              amount={singleFood?.featured?.[0]?.featured_amount}
              reference_id={singleFood?.featured?.[0]?.payment_id}
              isOpen={openPayment}
              setOpen={setOpenPayment}
            />
            <h3 className="text-lg font-medium">Feature Details</h3>
            {singleFood?.featured?.length > 0 ? (
              singleFood?.featured?.[0]?.featured_paid ? (
                <Button.Primary
                  type={"button"}
                  title={"Feature Food"}
                  disabled={singleFood?.featured?.[0]?.featured_paid}
                  onClick={() => {
                    featureFoodRef.current.open();
                    featureFoodRef.current.getAmount(singleFood?.food_amount);
                  }}
                />
              ) : (
                <Button.Primary
                  type={"button"}
                  title={"Pay For Feature"}
                  disabled={singleFood?.featured?.[0]?.featured_paid}
                  onClick={() => setOpenPayment(true)}
                />
              )
            ) : (
              <Button.Primary
                type={"button"}
                title={"Feature Food"}
                disabled={singleFood?.featured?.[0]?.featured_paid}
                onClick={() => {
                  featureFoodRef.current.open();
                  featureFoodRef.current.getAmount(singleFood?.food_amount);
                }}
              />
            )}
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-6 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Cost
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.featured?.[0]?.featured_amount
                  ? "NGN " +
                    numberWithCommas(singleFood?.featured?.[0]?.featured_amount)
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Status
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.featured?.[0]?.status ?? "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Duration
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.featured?.[0]?.featured_duration_in_hours
                  ? singleFood?.featured?.[0]?.featured_duration_in_hours +
                    "hours"
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Time Left
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleFood?.featured?.[0]?.featured_duration_in_hours
                  ? singleFood?.featured?.[0]?.featured_duration_in_hours +
                    "hours"
                  : "Not available"}
              </p>
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-3 sm:py-5 px-4 sm:px-8 mt-6 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">Food Images</h3>
              <p className="text-sm">
                Accepts .jpg, .png and .jpeg image formats
              </p>
            </div>
            <Button.Primary
              type={"button"}
              title={"Share Product"}
              onClick={() => {
                shareProductRef.current.open();
                shareProductRef.current.getProductImages(
                  singleFood?.food_pictures
                );
                shareProductRef.current.getOutletType("restaurant");
                shareProductRef.current.getOutletId(outlet?.id);
                shareProductRef.current.getProductId(singleFood?.id);
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-6 sm:mt-8">
            {singleFood?.food_pictures?.map((image, index) => (
              <figure className="w-full h-[160px] relative" key={index}>
                <img
                  src={image}
                  className="object-cover w-full h-full"
                  alt="food"
                />
              </figure>
            ))}
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-3 sm:py-5 px-4 sm:px-8 mt-6 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Food Attributes</h3>
          </div>
          {singleFood?.attributes?.length > 0 ? (
            singleFood?.attributes.map((attr, i) => (
              <div key={i} className="border-b border-[#E4E4F3] mt-6 sm:mt-8">
                <p className="text-md my-6">
                  Title : <span className="font-medium">{attr.title}</span>
                </p>
                <div className="grid grid-cols-2 gap-24 px-4">
                  <p className="py-2 text-md">Name</p>
                  <p className="py-2 text-md">Value</p>
                </div>
                <div key={i} className="grid grid-cols-2 gap-24 px-4">
                  <p className="py-2 font-medium">{attr.name}</p>
                  <p className="py-2 font-medium">NGN {attr.amount}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center mx-auto max-w-2xl py-6">
              <h4 className="text-2xl font-medium mb-2">
                You have no food attributes yet
              </h4>
              <p className="max-w-[473px] mx-auto text-lg mb-4">
                When you create or add food attributes, it will be dispalyed
                here.
              </p>
              {/* <button
                className="text-primary text-lg"
                type="button"
                onClick={openModal}
              >
                + Add variation
              </button> */}
            </div>
          )}
        </div>
      </div>
      <FeatureFoodModal ref={featureFoodRef} />
      <ShareProductModal ref={shareProductRef} />
    </>
  );
}
