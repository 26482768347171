import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Location from "../../../components/includes/location";
import { history } from "../../../helpers/history";
import OnboardStore from "./storeComponents/OnboardStore";
import CreateBusinessDetails from "./storeComponents/CreateBusinessDetails";
import { onboardBusiness } from "../../../redux/onBoarding/onBoardingAction";
import { logoutUserLocallyAction } from "../../../plugins/axios.plugin";
import {
  // getAllCategories,
  filterProductCategory,
} from "../../../redux/productCatergory/productCategoryAction";
import { filterProductTag } from "../../../redux/productTags/productTagActions";

const tabs = [
  { name: "Create Business", href: "create business" },
  { name: "Outlet Details", href: "store details" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CreateStoreBusiness() {
  const dispatch = useDispatch();
  const [active_tab, setActive_tab] = useState("create business");

  const { allCategories } = useSelector((state) => state.productCatergory);
  const storeCategories = allCategories?.data?.filter((item) => {
    return item?.type === "store";
  });
  // const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([
    {
      business_email: "",
      business_name: "",
      business_city: "",
      business_address: "",
      business_state: "",
      business_country: "",
      business_description: "",
      business_type: "store",
      store_email: "",
      store_name: "",
      store_address: "",
      store_city: "",
      store_state: "",
      store_country: "",
      store_description: "",
      store_categories: [],
    },
  ]);
  const {
    business_email,
    business_name,
    business_type,
    business_city,
    business_address,
    business_state,
    business_country,
    // store_name,
    // store_email,
    // store_address,
    // store_city,
    // store_state,
    // store_country,
    // store_description,
    // store_categories,
  } = data;

  useEffect(() => {
    // dispatch(getAllCategories());
    dispatch(filterProductTag({ type: "store" }, 1));
    dispatch(filterProductCategory({ type: "store" }, 1));
    // eslint-disable-next-line
  }, []);

  let [business_phone, setBusinessPhone] = useState("");
  let [store_phone, setStorePhone] = useState("");

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    event.preventDefault();
    if (name === "store_categories") {
      setData({ ...data, store_categories: [value] });
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const toggle = (tab) => {
    setActive_tab(tab.href);
  };

  function categories() {
    let dataToMap = storeCategories;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((category, i) => {
        let singleRowArray = [
          <option key={i} value={category.name}>
            {category.name}
          </option>,
        ];
        outputArray.push(singleRowArray);

        return true;
      });

      return outputArray;
    }
  }
  business_phone = business_phone?.slice(1);
  store_phone = store_phone?.slice(1);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      business_name &&
      business_phone &&
      business_email &&
      business_type &&
      business_city &&
      business_state &&
      business_address &&
      business_country
    ) {
      // dispatch action to create data
      const response = await dispatch(
        onboardBusiness({
          ...data,
          business_phone,
          store_phone,
        })
      );
      if (response === true) {
        logoutUserLocallyAction();
        history("/success");
      }
    }
  };
  const handleNext = () => {
    setSubmitted(true);
    if (
      business_name &&
      business_phone &&
      business_email &&
      business_city &&
      business_state &&
      business_address &&
      business_country
    ) {
      setActive_tab("store details");
    }
  };
  const handleBack = () => {
    setActive_tab("create business");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className="px-4 sm:mt-10">
      <nav
        className="-mb-px flex sm:hidden space-x-8 overflow-x-auto sm:max-w-[629px] mx-auto mt-10"
        aria-label="Tabs"
      >
        {tabs.map((tab) => (
          <button
            key={tab.name}
            onClick={() => {
              toggle(tab);
            }}
            className={classNames(
              tab.href === active_tab
                ? "border-primary text-primary font-bold"
                : "border-transparent text-[#BCBCBC] hover:text-gray-700 hover:border-gray-300 dark:hover:text-primary",
              "whitespace-nowrap py-2 px-2 border-b-2 dark:border-b-2 font-medium text-sm"
            )}
            aria-current={tab.href === active_tab ? "page" : undefined}
          >
            {tab.name}
          </button>
        ))}
      </nav>
      {active_tab === "create business" && (
        <CreateBusinessDetails
          handleChange={handleChange}
          data={data}
          business_phone={business_phone}
          setBusinessPhone={setBusinessPhone}
          handleSubmit={handleSubmit}
          submitted={submitted}
          Location={Location}
          locationId={locationId}
          locationList={locationList}
          setLocationList={setLocationList}
          setLocationId={setLocationId}
          handleRegion={handleRegion}
          handleNext={handleNext}
        />
      )}

      {active_tab === "store details" && (
        <OnboardStore
          store_phone={store_phone}
          setStorePhone={setStorePhone}
          handleChange={handleChange}
          data={data}
          setData={setData}
          handleSubmit={handleSubmit}
          submitted={submitted}
          Location={Location}
          locationId={locationId}
          locationList={locationList}
          setLocationList={setLocationList}
          setLocationId={setLocationId}
          handleRegion={handleRegion}
          handleBack={handleBack}
          categories={categories}
        />
      )}
    </div>
  );
}
