import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getToken, getMessaging } from "firebase/messaging";
import { isAuthenticatedUser } from "../../utils/Auth";
import { storeAPushNotification } from "../../redux/pushNotification/pushNotificationActions";

export default function FirebaseNotification() {
  const dispatch = useDispatch();
  const isAuth = isAuthenticatedUser();
  const firebaseUrl = process.env.REACT_APP_FIREBASE_URL;
  const firebaseKey = process.env.REACT_APP_FIREBASE_SERVER_KEY;
  const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

  const messaging = getMessaging();

  useEffect(() => {
    const requestPermission = async () => {
      try {
        // const token = await messaging.getToken();
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          // Get FCM token for this device
          const token = await getToken(messaging, {
            vapidKey: vapidKey, // Replace with your Firebase VAPID key
          });
          console.log("token", token);

          if (token) {
            // Save the token to your backend or use it as needed

            if (isAuth) {
              const uuid = async () => {
                // generate a unique id for the browser
                let uuid = await localStorage.getItem("UUID");
                if (!uuid) {
                  uuid = Math.random().toString(36).slice(2) + Date.now();
                  localStorage.setItem("UUID", uuid);
                }
                return uuid;
              };

              const createPayload = async () => {
                const deviceId = await uuid();
                const payLoad = {
                  url: firebaseUrl,
                  server_key: firebaseKey,
                  device_token: token,
                  device_id: deviceId,
                  device_type: "web",
                  notification_type: "direct",
                  notification_agent: "firebase",
                };
                return payLoad;
              };

              const payLoad = await createPayload();

              await dispatch(storeAPushNotification({ ...payLoad }));
            }
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        } else {
          console.log("Notification permission not granted.");
        }
      } catch (error) {
        console.error("Permission Denied", error);
      }
    };

    requestPermission();
  });

  return null;
}
