import React, { lazy, Suspense, useEffect } from "react";
// Toasts Notification
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
// Router
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./utils/privateRoute";
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
// Layouts
import AdminLayout from "./layouts/AdminLayout";
import UserLayout from "./layouts/UserLayout";
import CommonLayout from "./layouts/CommonLayout";
import TrackOrder from "./pages/user/trackOrder";
import LazyLoader from "./components/LazyLoader";
// import LandingPage from "./pages/common/Landing";
// import Home from "./pages/user/home";
import FooterCommon from "./components/footer/FooterCommon";
import NavBarUser from "./components/navigation/NavBarUser";
import NavBarCommon from "./components/navigation/NavBarCommon";
import FirebaseNotification from "./components/firebase/FirebaseNotification";
import FirebaseMessage from "./components/firebase/FirebaseMessage";
import { messaging } from "./components/firebase/firebaseInit";
import { onMessage } from "firebase/messaging";
// import { register } from "./serviceWorker";

function App() {
  const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const UserHome = lazy(() => import("./pages/user/home"));
  const savedTheme = localStorage.getItem("theme");

  onMessage(messaging, (payload) => {
    console.log("fb payload", payload);
    toast(<FirebaseMessage notification={payload.notification} />);
  });

  useEffect(() => {
    // Add or remove the 'dark' class based on the theme
    if (savedTheme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    // Save savedTheme preference in localStorage
    // localStorage.setItem("savedTheme", savedTheme);
  }, [savedTheme]);

  return (
    <div className="App" data-test="appComponent">
      <GoogleOAuthProvider clientId={client_id}>
        <Provider store={store}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
          />
          <FirebaseNotification />
          <BrowserRouter>
            <Routes>
              <Route
                path="/admin/*"
                element={
                  <PrivateRoute>
                    <AdminLayout />
                  </PrivateRoute>
                }
              />

              <Route
                path="/"
                // element={
                //   <main className="relative bg-white min-h-screen">
                //     <NavBarCommon />
                //     <NavBarUser />
                //     <Home />
                //     <FooterCommon />
                //   </main>
                // }
                element={
                  <Suspense fallback={<LazyLoader />}>
                    <main className="relative bg-white min-h-screen">
                      <NavBarCommon />
                      <NavBarUser />
                      {/* <Home /> */}
                      <UserHome />
                      <FooterCommon />
                    </main>
                  </Suspense>
                }
              />
              <Route path="/*" element={<CommonLayout />} />
              <Route path="*" element={<CommonLayout />} />
              <Route path="/user/*" element={<UserLayout />} />
              <Route
                path="/track/:id"
                exact
                element={
                  <PrivateRoute>
                    <TrackOrder />
                  </PrivateRoute>
                }
              />
              <Route
                path="/track/:id/:active_tab"
                exact
                element={
                  <PrivateRoute>
                    <TrackOrder />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </Provider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
