// Application types

const ADDRESS = {
  LOADING_ADDRESS: "LOADING_ADDRESS",
  CREATING: "CREATING",
  UPDATING: "UPDATING",
  DELETING:'DELETING',
  GET_MY_ADDRESSES: "GET_MY_ADDRESSES",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  ADD_ADDRESS: "ADD_ADDRESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  DEFAULT_ADDRESS: "DEFAULT_ADDRESS",
  FILTER_ADDRESSES: "FILTER_ADDRESSES",
};

export default ADDRESS;
