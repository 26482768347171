import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Restaurants from "./tabs/restaurant";
import Stores from "./tabs/store";
import Business from "./tabs/business";

// const tabs = [
//   { name: "Restaurants", href: "restaurant" },
//   { name: "Stores", href: "stores" },
// ];

export default function BusinessReport() {
  const DEFAULT_ACTIVE_TAB = "business";
  const navigate = useNavigate();
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/business-report/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line
  }, []);

  // const toggle = (tab) => {
  //   if (active_tab !== tab) {
  //     navigate(`/admin/business-report/${tab.href}/1`);
  //   }
  // };

  return (
    <div data-test="business-report-page">
      <div className="mt-4">
        {active_tab === "restaurant" && <Restaurants />}
        {active_tab === "stores" && <Stores />}
        {active_tab === "business" && <Business />}
      </div>
    </div>
  );
}
