import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import LogoBlack from "../../../../assets/images/icons/ogwugo-black-logo.svg";
import LogoWhite from "../../../../assets/images/icons/ogwugo-white-logo.svg";
import MINUS from "../../../../assets/images/icons/decreament.svg";
import PLUS from "../../../../assets/images/icons/increament.svg";
import EmptyState from "../../../../assets/images/emptyState/orders.svg";
import { cleanInput } from "../../../../helpers/cleanInput";
import AddStoreOrderModal from "../../../../components/modals/admin/addOrderItem";
import { createAStoreOrder } from "./../../../../redux/storeOrders/storeOrderAction";

export default function RightSide({
  data,
  setData,
  submitted,
  setSubmitted,
  handleChange,
  recipient_phone,
}) {
  const { order_discount, order_amount, recipient_name, order_type } = data;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [displayData, setDisplayData] = useState([]);
  const [subtotalAmount, setSubtotalAmount] = useState(0);
  const { creating } = useSelector((state) => state.storeOrder);

  const incrementCounter = (index) => {
    let items = { ...data };
    let num = Number(items.order_items[index].quantity);
    num++;
    items.order_items[index].quantity = num;
    setData(items);
    //do same for display data
    let display_items = [...displayData];
    let display_num = Number(display_items[index].quantity);
    display_num++;
    display_items[index].quantity = display_num;
    setDisplayData(display_items);
  };

  const decrementCounter = (index) => {
    let items = { ...data };
    let num = Number(items.order_items[index].quantity);
    if (num > 1) {
      num--;
    }
    items.order_items[index].quantity = num;
    setData(items);
  };

  const removeItem = (item_index) => {
    let newDisplayData = [...displayData];
    newDisplayData.splice(item_index, 1);
    setDisplayData(newDisplayData);

    //do same for data array
    let items = { ...data };
    items.order_items.splice(item_index, 1);
    setData(items);
  };

  useEffect(() => {
    let total_amount =
      displayData?.reduce((total, item) => {
        return (
          total +
          Number(item?.merchandise_amount) * Number(item?.quantity) +
          (item?.attributes?.length > 0 &&
            item?.attributes
              .filter((attr) => typeof attr !== "undefined")
              .reduce(
                (arr_total, e) =>
                  arr_total + Number(e.quantity) * Number(e.amount),
                0
              ))
        );
      }, 0) - Number(order_discount);

    let sub_total_amount = displayData?.reduce((total, item) => {
      return (
        total +
        Number(item?.merchandise_amount) * Number(item?.quantity) +
        (item?.attributes?.length > 0 &&
          item?.attributes
            .filter((attr) => typeof attr !== "undefined")
            .reduce(
              (arr_total, e) =>
                arr_total + Number(e.quantity) * Number(e.amount),
              0
            ))
      );
    }, 0);

    setSubtotalAmount(sub_total_amount);
    setData({ ...data, order_amount: total_amount });
    // eslint-disable-next-line
  }, [displayData, order_discount]);

  const handleSubmit = async () => {
    let newData = { ...data, recipient_phone: recipient_phone };
    cleanInput(newData?.order_items?.attributes);
    cleanInput(newData);
    setSubmitted(true);
    if (recipient_name && order_type) {
      const response = await dispatch(createAStoreOrder(newData));
      if (response) {
        navigate("/admin/store-orders/unshipped/1");
      }
    }
  };

  return (
    <div>
      <div className="border border-[#E4E4F3] border-none bg-white dark:bg-[#121212] sm:min-h-[786px] sm:w-[527px] rounded-md py-5 px-8">
        <div className="flex justify-between items-start">
          <img src={LogoBlack} alt="logo" className="mt-6 dark:hidden" />
          <img src={LogoWhite} alt="logo" className="mt-6 hidden dark:block" />
        </div>

        <>
          <div className="flex justify-between mt-5">
            {data.order_payment_method && (
              <div>
                <p className="text-sm text-[#4D4D4D] dark:text-[#BCBCBC]">
                  Payment Method
                </p>
                <p className="text-sm font-bold my-1">
                  {data.order_payment_method}
                </p>
              </div>
            )}
            {data?.order_paid && (
              <div className="text-right">
                <p className="text-sm text-[#4D4D4D] dark:text-[#BCBCBC]">
                  Payment Status
                </p>
                <p className="text-sm font-bold my-1">
                  {data?.order_paid === "1" && "Paid"}
                  {data?.order_paid === "0" && "Unpaid"}
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-between my-8 ">
            {data?.recipient_name && (
              <div>
                <p className="text-sm text-[#4D4D4D] dark:text-[#BCBCBC]">
                  Recipients Name{" "}
                </p>
                <p className="text-sm font-bold my-1">{data?.recipient_name}</p>
              </div>
            )}
           
            {(data?.recipient_phone || recipient_phone) && (
              <div className="text-right">
                <p className="text-sm text-[#4D4D4D] dark:text-[#BCBCBC]">
                  Recipients Phone{" "}
                </p>
                <p className="text-sm font-bold my-1">
                  {data?.recipient_phone !== ""
                    ? data?.recipient_phone
                    : recipient_phone}
                </p>
              </div>
            )}
          </div>
        </>

        {data.order_items && data.order_items?.length > 0 ? (
          data.order_items?.map((item, index) => (
            <div
              key={index}
              className="px-6 py-2 border-b border-[#565656] border-dashed last:border-0 last:pb-20"
            >
              <div className="flex justify-between my-3">
                <p className="text-sm font-bold">
                  {displayData[index].merchandise_name}
                </p>
                <p className="text-sm">
                  NGN {displayData[index].merchandise_amount}
                </p>
              </div>

              {displayData[index]?.attributes?.length > 0 &&
                displayData[index].attributes?.map(
                  (attribute) =>
                    typeof attribute !== "undefined" && (
                      <div
                        className="flex justify-between"
                        key={attribute?.attribute_id}
                      >
                        <p className="text-sm font-bold">
                          {attribute?.name} x {attribute?.quantity}
                        </p>
                        <p className="text-sm ">NGN {attribute?.amount} </p>
                      </div>
                    )
                )}

              {/* {displayData?.map((item, index) => (
              <div key={index} className="">
                <div className="flex justify-between my-3">
                  <p className="text-sm font-bold ">
                    {item.food_name} {index}
                  </p>
                  <p className="text-sm">NGN {item.food_amount}</p>
                </div>

                {item?.attributes?.length > 0 &&
                  item.attributes?.map(
                    (attribute) =>
                      typeof attribute !== "undefined" && (
                        <div
                          className="flex justify-between"
                          key={attribute?.attribute_id}
                        >
                          <p className="text-sm font-bold">
                            {attribute?.name} x {attribute?.quantity}
                          </p>
                          <p className="text-sm ">NGN {attribute?.amount} </p>
                        </div>
                      )
                  )}
              </div>
            ))} */}
              <div className="flex justify-between">
                <button
                  type="button"
                  className=" w-24 sm:w-[78px] inline-flex mt-2 items-center h-11 sm:h-5 text-base font-medium  sm:text-sm ml-2 sm:ml-0"
                >
                  <img
                    src={MINUS}
                    alt="decrement counter"
                    onClick={() => {
                      decrementCounter(index);
                    }}
                    className=" w-6 h-6 sm:w-5 sm:h-5"
                  />

                  <p className="mx-2 text-sm">{item?.quantity}</p>
                  <img
                    src={PLUS}
                    alt="decrement counter"
                    onClick={() => incrementCounter(index)}
                    className=" w-6 h-6 sm:w-5 sm:h-5"
                  />
                </button>
                <button
                  className="text-primary"
                  onClick={() => removeItem(index)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="pt-16 pb-4 text-center">
            <div className="flex justify-center">
              <img
                src={EmptyState}
                className="w-[100px] h-[100px]"
                alt="no data"
              />
            </div>
            <h3 className="mt-5 text-xl font-bold">Store Orders</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any orders yet.
            </p>
          </div>
        )}

        <AddStoreOrderModal
          handleChange={handleChange}
          submitted={submitted}
          data={data}
          setData={setData}
          displayData={displayData}
          setDisplayData={setDisplayData}
        />
        <div className="mt-10">
          <div className="flex justify-between">
            <p className="text-sm font-bold py-1">Subtotal</p>
            <p className="text-sm font-bold py-1">NGN {subtotalAmount} </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm py-1">Discount</p>
            <p className="text-sm py-1"> NGN {order_discount}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm font-bold py-1">Total</p>
            <p className="text-sm font-bold py-1">NGN {order_amount}</p>
          </div>
        </div>

        <div className="border mt-2" />
      </div>
      <div className="flex justify-center my-12 sm:w-[527px]">
        <Button.Primary
          disabled={creating}
          title={creating ? <Beat color={"#ffffff"} /> : "Create Order"}
          className="rounded-sm"
          type={"button"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
