import axios from "../plugins/axios.plugin";

class PublicRestaurantService {
  baseServicePath = "/api/public/restaurant";

  getAllPublicRestaurants(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getPublicRestaurant(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  filterRestaurants(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }

  searchPublicRestaurants(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
}

export const publicRestaurantService = new PublicRestaurantService();
