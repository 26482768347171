import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import { useDispatch, useSelector } from "react-redux";
import { RiCloseLine } from "react-icons/ri";
import {
  filterProductTag,
  storeATag,
} from "../../../redux/productTags/productTagActions";
import { Beat } from "../../../plugins/spinners.plugin";

export default function TagModal() {
  const dispatch = useDispatch();

  let [isOpen, setIsOpen] = useState(false);
  function handleClose() {
    setIsOpen(false);
  }
  const { loading } = useSelector((state) => state.productTag);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([{ name: "", type: "" }]);
  const { name, type } = data;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const [payLoad] = useState({
    type: "restaurant",
  });
  const page = 1;
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (name && type) {
      // dispatch action to create data
      const response = await dispatch(storeATag({ ...data }));
      if (response) {
        dispatch(filterProductTag(payLoad, page));
      }
      handleClose();
    }
  };
  return (
    <>
      <Button.Primary title={"+ New Tag"} onClick={() => setIsOpen(true)} />
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-white dark:bg-[#121212] w-[358px] sm:rounded-md rounded-sm sm:w-[450px] p-4 sm:p-6 ">
                  <form name="form" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4">
                        <button
                          type="button"
                          className=" "
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close</span>
                          <RiCloseLine />
                        </button>
                      </div>
                      <div className="">
                        <div className=" text-center sm:text-left ">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-medium text-black dark:text-white"
                          >
                            New Tag
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className=" border-b-2 mt-4"></div>
                      <div className="mt-5 sm:mt-7 text-left">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 dark:text-white"
                          >
                            Name
                          </label>
                          <div className="mt-2 border-gray-300 focus-within:border-primary">
                            <input
                              type="text"
                              name="name"
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF]  focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                              placeholder="Enter tag name"
                              onChange={handleChange}
                            />
                            {submitted && !name && (
                              <div className="text-primary text-xs ">
                                Name is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700 dark:text-white"
                          >
                            Type*
                          </label>
                          <div className="mt-2 ">
                            <select
                              name="type"
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[#121212] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                              onChange={handleChange}
                            >
                              <option>Select a type</option>
                              <option value="store">Store</option>
                              <option value="restaurant">Restaurant</option>
                            </select>
                            {submitted && !type && (
                              <div className="text-primary text-xs ">
                                Type is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between mt-10 sm:mt-24">
                        <Button.Secondary
                          title={"Back"}
                          className={"rounded-md px-4"}
                          onClick={handleClose}
                        />
                        <button
                          type="submit"
                          className="h-14 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                        >
                          {loading ? <Beat /> : "Add Tag"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
