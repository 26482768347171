// Application types

const FOOD = {
  LOADING_FOOD: "LOADING_FOOD",
  ALL_FOODS: "ALL_FOODS",
  SINGLE_FOOD: "SINGLE_FOOD",
  SEARCHING_FOODS: "SEARCHING_FOODS",
  SEARCH_PUBLIC_FOODS: "SEARCH_PUBLIC_FOODS",
};

export default FOOD;
