import React, { useMemo, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/buttons";
import Card from "../../../components/cards/admin/cards";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import { Beat } from "../../../plugins/spinners.plugin";
import { PaginationNoRoute } from "../../../components/pagination/paginationNoRoute";
import EmptyState from "../../../assets/images/emptyState/orders.svg";
import FilterRestaurantOrdersDropDown from "../../../components/dropDown/filter/filterRestaurantOrders";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import {
  filterFoodAction,
  searchFoodAction,
} from "../../../redux/food/foodActions";
import searchFoodType from "../../../redux/food/foodTypes";
import { getAllRestaurantOrdersNoPagination } from "../../../redux/restaurantOrder/restaurantOrderActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function AllFoods() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFilterOpen, setFilterState] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  const { allFood, downloading } = useSelector((state) => state.food);
  const { filtering, searching } = useSelector((state) => state.food);
  const { restaurant } = useSelector((state) => state.restaurant);

  const [payLoad] = useState({
    end_date: new Date(),
    restaurant_id: restaurant?.id,
  });

  useEffect(() => {
    dispatch(
      filterFoodAction({ ...payLoad, restaurant_id: restaurant?.id }, page)
    );
    // eslint-disable-next-line
  }, [page]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(
        searchFoodAction({ ...input, restaurant_id: restaurant?.id })
      );
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDownload = async () => {
    const response = await dispatch(
      getAllRestaurantOrdersNoPagination({
        ...payLoad,
        restaurant_id: restaurant?.id,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Restaurant_Orders.xlsx",
        download: true,
      });
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allFood?.data);
      setData(dataSet);
      dispatch({
        type: searchFoodType["SEARCH_FOOD"],
        payLoad: { data: [] },
      });
    }
  };

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          index: index + 1,
          food_name: order.food_name,
          amount: <span>NGN {numberWithCommas(order?.food_amount)}</span>,
          availability: order?.food_operating_status,
          approved: (
            <span>
              {order?.management_approved ? (
                <span className="text-[#00D220] font-bold">Approved</span>
              ) : (
                <span className="text-primary font-bold">Unapproved</span>
              )}
            </span>
          ),
          orderData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order) => {
        let show = {
          id: order?.id,
          food_name: order.food_name,
          amount: order?.food_amount,
          availability: order?.food_operating_status,
          approved: order?.management_approved ? "Approved" : "Unapproved",
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allFood?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFood]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.index",
          },
          {
            Header: "Food Name",
            accessor: "show.food_name",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
          {
            Header: "Availability Status",
            accessor: "show.availability",
          },
          {
            Header: "Approval Status",
            accessor: "show.approved",
          },
        ],
      },
    ],
    []
  );

  function goToSinglePage(params) {
    let food_id = params?.show?.orderData?.id;
    navigate(`/admin/food/${food_id}`, {
      state: { food: params?.show?.orderData },
    });
  }

  return (
    <div>
      <Card.TableFilter
        title={
          allFood?.total > 0 &&
          `Showing ${allFood?.from ?? "0"} - ${allFood?.to ?? "0"} of ${
            allFood?.total ?? "0"
          } Results`
        }
        searchPlaceholder={"Search Orders"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterRestaurantOrdersDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#ffffff"} /> : "Download CSV"}
          disabled={!data}
          onClick={handleDownload}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3] dark:border-[#505050]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-8">
        <h4>Restaurant Foods</h4>
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            className="w-full"
            rowClass="cursor-pointer"
            onClick={goToSinglePage}
          />
        ) : (
          <div className="py-16 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Orders</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any orders yet.
            </p>
          </div>
        )}
      </div>
      <PaginationNoRoute data={allFood} page={page} setPage={setPage} />
    </div>
  );
}
