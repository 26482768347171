import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Input = ({ children }) => {
  return (
    <div className="Input" data-test="inputComponent">
      {children}
    </div>
  );
};

Input.Label = ({
  title,
  className,
  placeholder,
  type,
  name,
  readOnly,
  id,
  value,
  defaultValue,
  htmlFor,
  onKeyDown,
  onChange,
  disabled,
  autoComplete,
  subText,
}) => {
  return (
    <div className="">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-2 mb-4">
        <input
          type={type}
          name={name}
          readOnly={readOnly}
          defaultValue={defaultValue}
          id={id}
          value={value}
          onKeyDown={onKeyDown}
          className={
            "mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[transparent] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 border border-gray-300 " +
            className
          }
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          autoComplete={autoComplete}
        />
        {subText && (
          <p className="text-[10px] text-[#344B67] dark:text-white mt-1">{subText}</p>
        )}
      </div>
    </div>
  );
};

Input.Password = ({
  title,
  className,
  placeholder,
  name,
  id,
  onClick,
  value,
  htmlFor,
  onChange,
  disabled,
}) => {
  function toggleFunction(e) {
    var parent = e.target.parentNode;
    // var x = document.getElementById("password");
    var x = parent.childNodes[0];
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    e.target.classList.toggle("fa-eye-slash");
  }

  return (
    <div className="relative">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-2">
        <input
          type={"password"}
          name={name}
          // id={"password"}
          value={value}
          autoComplete="off"
          className={
            "mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[transparent] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 " +
            className
          }
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
        <i
          className="absolute top-[43px] right-4 fa-solid fa-eye"
          onClick={(e) => toggleFunction(e)}
          // id="eye"
        />
      </div>
    </div>
  );
};

Input.Number = ({
  title,
  className,
  placeholder,
  name,
  min,
  id,
  onClick,
  value,
  htmlFor,
  onChange,
  icon,
  defaultValue,
  disabled, subText
}) => {
  return (
    <div className="relative">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-2 mb-4">
        <div className='relative'>
          <input
          type={"number"}
          name={name}
          id={id}
          value={value}
          defaultValue={defaultValue}
          autoComplete="off"
          min={min}
          className={
            "mt-2 block pl-3 pr-10 w-full text-base dark:bg-[transparent] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 border border-gray-300 " +
            className
          }
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => {
            let new_e = e;
            new_e.target.value = Math.abs(e.target.value);
            onChange(new_e);
          }}
          onWheel={(e) => e.target.blur()}
        />
        <i className="absolute top-4 right-4" onClick={onClick}>
          {icon}
        </i>
        </div>
        
        {subText && (
          <p className="text-[10px] text-[#344B67] dark:text-white mt-1">
            {subText}
          </p>
        )}
      </div>
    </div>
  );
};

Input.Phone = ({
  title,
  className,
  name,
  id,
  defaultCountry,
  value,
  htmlFor,
  onChange,
}) => {
  return (
    <div className="">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-2">
        <PhoneInput
          type="tel"
          defaultCountry="NG"
          value={value}
          name={name}
          id={id}
          className={
            "mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[transparent] sm:text-sm rounded-sm h-[42px] px-4 mb-4 border border-gray-300" +
            className
          }
          onChange={onChange}
        />
      </div>
    </div>
  );
};

Input.Select = ({
  title,
  className,
  defaultValue,
  name,
  value,
  id,
  onChange,
  htmlFor,
  disabled,
  children,
  subText
}) => {
  return (
    <div className="mb-4">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <select
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        className={
          "mt-2 block w-full pl-3 pr-10 text-base dark:bg-[transparent] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 border border-gray-300 " +
          className
        }
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {children}
      </select>
      {subText && (
        <p className="text-[10px] text-[#344B67] dark:text-white mt-1">
          {subText}
        </p>
      )}
    </div>
  );
};

Input.TextArea = ({
  title,
  className,
  name,
  id,
  onChange,
  placeholder,
  htmlFor,
  value,
}) => {
  return (
    <div className="pb-4">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-2">
        <textarea
          rows={5}
          name={name}
          value={value}
          id={id}
          placeholder={placeholder}
          className={
            "shadow-sm bg-[#FFFFFF] dark:bg-[transparent] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none  block w-full sm:text-sm border border-[#E4E4F3] rounded-sm px-4 py-2 mb-4" +
            className
          }
          onChange={onChange}
        />
      </div>
    </div>
  );
};

Input.DatePicker = ({
  title,
  className,
  name,
  id,
  defaultValue,
  onChange,
  selected,
  placeholder,
  htmlFor,
  min,
  max,
  subText
}) => {
  return (
    <div className="pb-4">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-1">
        <input
          type="date"
          name={name}
          id={id}
          min={min}
          max={max}
          selected={selected}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={
            "shadow-sm w-full h-[40px] bg-[#FFFFFF] dark:bg-[transparent] focus:ring-4 focus:ring-red-500 focus:border-red-500 focus:outline-none  focus:border-transparent  block  sm:text-sm border border-[#E4E4F3] rounded-sm px-4 py-2 " +
            " " +
            className
          }
          onChange={onChange}
        />
        {subText && (
          <p className="text-[10px] text-[#344B67] dark:text-white mt-1">
            {subText}
          </p>
        )}
      </div>
    </div>
  );
};
Input.TimePicker = ({
  title,
  defaultValue,
  className,
  name,
  id,
  onChange,
  htmlFor,
}) => {
  return (
    <div className="pb-4">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-1">
        <input
          type="time"
          id={id}
          name={name}
          defaultValue={defaultValue}
          className={
            "shadow-sm w-full h-[40px] bg-[#FFFFFF] dark:bg-[transparent] dark:bg-[transparent] focus:ring-4 focus:ring-red-500 focus:border-red-500 focus:outline-none  focus:border-transparent  block  sm:text-sm border border-[#E4E4F3] rounded-sm px-4 py-2 " +
            " " +
            className
          }
          onChange={onChange}
          step="60" // set step to 60 seconds (1 minute)
          pattern="[0-9]{2}:[0-9]{2}" // set pattern to hh:mm
        />
      </div>
    </div>
  );
};

Input.File = ({
  title,
  className,
  placeholder,
  type,
  name,
  id,
  htmlFor,
  onChange,
}) => {
  return (
    <div className="">
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-2">
        <input
          type="file"
          name={name}
          id={id}
          className={
            "mt-2 block pl-3 pr-10 w-full text-base focus:outline-2 bg-[#FFFFFF] dark:bg-[transparent] focus:ring-4 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-dashed border-gray-300" +
            className
          }
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
Input.Item = ({ title, className, onClick = () => {} }) => {
  return (
    <div className="pb-4">
      <label
        htmlFor="item"
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-1">
        <input
          type="item"
          name="item"
          id="item"
          placeholder="placeholder"
          className={
            "shadow-sm h-[40px] w-[153px] bg-[#FFFFFF] dark:bg-[transparent] focus:ring-4 focus:ring-red-500 focus:border-red-500 focus:outline-none  focus:border-transparent  block  sm:text-sm border-[#E4E4F3] rounded-sm px-4 py-2 mb-4" +
            className
          }
        />
      </div>
    </div>
  );
};
Input.Quantity = ({ title, className, onClick = () => {} }) => {
  return (
    <div>
      <label
        htmlFor="quantity"
        className="block text-sm font-medium text-[#151515] dark:text-white"
      >
        {title ?? "Title"}
      </label>
      <div className="mt-1">
        <input
          type="quantity"
          name="quantity"
          id="quantity"
          placeholder="1"
          className={
            "shadow-sm h-[40px] w-[47px] bg-[#FFFFFF] dark:bg-[transparent] focus:ring-4 focus:ring-red-500 focus:border-red-500 focus:outline-none  focus:border-transparent  block  sm:text-sm border-[#E4E4F3] rounded-sm px-4 py-2 mb-4" +
            className
          }
        />
      </div>
    </div>
  );
};

export default Input;
