// Application types

const PROFILE = {
  APPROVING: "APPROVING",
  MARKING: "MARKING",
  SHIPPING: "SHIPPING",
  DOWNLOADING: "DOWNLOADING",
  LOADING_PACKAGE: "LOADING_PACKAGE",
  ALL_PACKAGES: "ALL_PACKAGES",
  MY_PACKAGES: "MY_PACKAGES",
  SINGLE_PACKAGE_ORDER: "SINGLE_PACKAGE_ORDER",
  STORE_PACKAGE: "STORE_PACKAGE",
  SEARCHING: "SEARCHING",
  SEARCH_PACKAGE: "SEARCH_PACKAGE",
  FILTERING: "FILTERING",
  FILTER_PACKAGE: "FILTER_PACKAGE",
};

export default PROFILE;
