import authType from "./restaurantTypes";

const initialState = {
  isLoading: false,
  updating: false,
  approving: false,
  searching: false,
  filtering: false,
  downloading: false,
  allRestaurants: {},
  allRestaurantsNoPagination: {},
  myRestaurants: [],
  restaurant: {},
  search: [],
};

export default function restaurantReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_RESTAURANT:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.APPROVING:
      return {
        ...state,
        approving: action.payLoad,
      };

    case authType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.ALL_RESTAURANTS:
      return {
        ...state,
        allRestaurants: action.payLoad?.data,
      };

    case authType.ALL_RESTAURANTS_NO_PAGINATION:
      return {
        ...state,
        allRestaurantsNoPagination: action.payLoad?.data,
      };

    case authType.SINGLE_RESTAURANT:
      return {
        ...state,
        restaurant: action.payLoad?.data,
      };

    case authType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_RESTAURANT:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case authType.MY_RESTAURANTS:
      return {
        ...state,
        myRestaurants: action.payLoad?.data?.data,
      };

    case authType.APPROVE_RESTAURANT_MANAGEMENT:
      let approved = {
        ...state.restaurant,
        management_approved: true,
      };
      return {
        ...state,
        restaurant: approved,
      };

    case authType.UNAPPROVE_RESTAURANT_MANAGEMENT:
      let unapproved = {
        ...state.restaurant,
        management_approved: false,
      };
      return {
        ...state,
        restaurant: unapproved,
      };

    case authType.APPROVE_RESTAURANT_BUSINESS:
      let approved_business = {
        ...state.restaurant,
        business_approved: true,
      };
      return {
        ...state,
        restaurant: approved_business,
      };

    case authType.UNAPPROVE_RESTAURANT_BUSINESS:
      let unapproved_business = {
        ...state.restaurant,
        business_approved: false,
      };
      return {
        ...state,
        restaurant: unapproved_business,
      };
    case authType.FILTER_RESTAURANTS:
      return {
        ...state,
        allRestaurants: action.payLoad?.data,
      };
    default:
      return state;
  }
}
