import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../../components/tables/tableCols2";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/cards/admin/cards";
import CategoryModal from "../../../../components/modals/admin/categoryModal";
import { Pagination } from "../../../../components/pagination/pagination";
import TableSkeleton from "../../../../components/skeleton/table";
import { getUserFromCookies } from "../../../../utils/Auth";
import {
  filterProductCategory,
  searchProductCategory,
} from "../../../../redux/productCatergory/productCategoryAction";
import EditCategory from "../../../../components/modals/admin/editCategoryModal";
import RemoveCategory from "../../../../components/modals/admin/removeCategory";
import Settings from "../../../../assets/images/icons/settingsEmpty.svg";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterCategoryDropDown from "./../../../../components/dropDown/filter/categoryFilter";

export default function CategoryRestaurant() {
  const dispatch = useDispatch();
  let { page } = useParams();
  const { allCategories, loading, search, searching } = useSelector(
    (state) => state.productCatergory
  );
  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  let user = getUserFromCookies();
  let business_id = Object.keys(user.groups);

  const restaurantCategories = allCategories?.data;
  // eslint-disable-next-line no-unused-vars
  const [payLoad, setPayLoad] = useState({
    type: "restaurant",
  });
  useEffect(() => {
    dispatch(filterProductCategory(payLoad, page));
  }, [dispatch, payLoad, page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((category, index) => {
        let singleRowArray = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          name: category.name,
          type: category.type,
          actions: (
            <div className="flex justify-around max-w-[92px]">
              <EditCategory restaurantCategories={category} />
              <p className="text-[#717485] text-xl">|</p>
              <RemoveCategory restaurantCategories={category} />
            </div>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(restaurantCategories);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(restaurantCategories);
      setData(dataSet);
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchProductCategory(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "num",
          },
          {
            Header: "Category",
            accessor: "name",
          },
          {
            Header: "Type",
            accessor: "type",
          },
          {
            Header: "Actions",
            accessor: "actions",
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <div>
        <Card.TableFilter
          results={restaurantCategories?.length}
          totalResults={restaurantCategories?.length}
          onChange={handleChange}
          title={"Categories"}
          payLoad={payLoad}
          page={page}
          filter={
            <AllFiltersModal
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            >
              <FilterCategoryDropDown
                isFilterOpen={isFilterOpen}
                setFilterState={setFilterState}
                payLoad={payLoad}
              />
            </AllFiltersModal>
          }
          searchPlaceholder={"Category Name"}
        >
          <CategoryModal teamId={business_id} payLoad={payLoad} page={page} />
        </Card.TableFilter>

        <div className="border-b border-[#E4E4F3] dark:border-[#505050]" />
        <div className="bg-white dark:bg-[#121212] rounded-md py-4 ">
          {loading || searching ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={() => {}}
            />
          ) : (
            <div className="text-center">
              {" "}
              <div className="flex justify-center my-12 ">
                <img src={Settings} alt="settinga" />
              </div>
              <p className="font-bold text-xl my-3">Settings</p>
              <p className="text-sm text-gray-500">
                You do not have any Categories yet
              </p>
            </div>
          )}
        </div>

        <Pagination
          data={allCategories}
          route={"/admin/settings/categories/restaurant"}
        />
      </div>
    </>
  );
}
