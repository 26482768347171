import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAUserAction } from "../../../../redux/users/userActions";
import EmptyState from "../../../../assets/images/emptyState/category-list.jpg";
import { ManageUserRolesModal } from "../../../../components/modals/roles/manageUserRolesModal";
import Button from "../../../../components/buttons/buttons";

export default function SingleUserRole() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { singleUser } = useSelector((state) => state.user);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const onImageError = (e) => {
    e.target.src = EmptyState;
  };

  useEffect(() => {
    if (id) {
      dispatch(getAUserAction(id));
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div data-test="inviteUser-page">
      <ManageUserRolesModal
        onClose={closeModal}
        show={isOpen}
        user={singleUser}
      />
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">User Details</h3>
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <figure className="min-h-[150px] xl:max-h-[164px] w-auto xl:max-w-[200px] border border-[#E4E4F3] dark:border-none rounded-sm">
            <img
              className="object-cover h-full w-full rounded"
              src={singleUser?.picture ?? EmptyState}
              alt="User"
              onError={onImageError}
            />
          </figure>

          <div className="col-span-4 grid grid-cols-5 gap-8">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
              <p className="text-[#151515] dark:text-white text-sm">{singleUser?.name}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
              <p className="text-[#151515] dark:text-white text-sm">{singleUser?.email}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Phone number
              </label>
              <p className="text-[#151515] dark:text-white text-sm">{singleUser?.phone}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Location </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleUser?.profile?.city}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Birthday</label>
              <p className="text-[#151515] dark:text-white text-sm">{singleUser?.birth_date}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Orders</label>
              <p className="text-[#151515] dark:text-white text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Coupons</label>
              <p className="text-[#151515] dark:text-white text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Referrals</label>
              <p className="text-[#151515] dark:text-white text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
              <p className="text-[#151515] dark:text-white text-sm">N/A</p>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Roles</h3>
          <Button.Primary
            type={"button"}
            title={"Manage Roles"}
            onClick={openModal}
          />
        </div>
        {singleUser?.roles?.length > 0 ? (
          singleUser?.roles?.map((role, index) => (
            <div key={index} className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Role</label>
                <p className="text-[#151515] dark:text-white text-sm">{role?.display_name}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Description
                </label>
                <p className="text-[#151515] dark:text-white text-sm">{role?.description}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="font-medium py-4 text-center">No roles available</p>
        )}
      </div>
    </div>
  );
}
