import authType from "./publicPackagesTypes";

const initialState = {
  isLoading: false,
  loadingMore: false,
  allPackages: {},
  filter: {},
  singlePackage: {},
};

export default function publicPackagesReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_PACKAGE:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.LOADING_MORE:
      return {
        ...state,
        loadingMore: action.payLoad,
      };

    case authType.ALL_PACKAGES:
      return {
        ...state,
        allPackages: action.payLoad?.data,
      };

    case authType.MORE_PACKAGES:
      return {
        ...state,
        allPackages: {
          ...state.allPackages,
          data: [...state.allPackages.data, ...action.payLoad?.data?.data],
        },
      };

    case authType.SINGLE_PACKAGE:
      return {
        ...state,
        singlePackage: action.payLoad?.data,
      };

    case authType.FILTER_PACKAGE:
      return {
        ...state,
        filter: action.payLoad?.data,
      };

    default:
      return state;
  }
}
