// Application types

const PACKAGES = {
  LOADING_PACKAGE: "LOADING_PACKAGE",
  LOADING_MORE: "LOADING_MORE",
  ALL_PACKAGES: "ALL_PACKAGES",
  MORE_PACKAGES: "MORE_PACKAGES",
  SINGLE_PACKAGE: "SINGLE_PACKAGE",
  FILTER_PACKAGE: "FILTER_PACKAGE",
};

export default PACKAGES;
