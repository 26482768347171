import React, { useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { useDispatch, useSelector } from "react-redux";
import CLOSE from "../../../assets/images/icons/close.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/icons/upload.svg";
import {
  createAdvertisment,
  getAllAdvertisments,
} from "../../../redux/advertisment/advertismentAction";
import { cleanInput } from "../../../helpers/cleanInput";
import { Beat } from "../../../plugins/spinners.plugin";

export default function AddNewSlider(props) {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);

  const { isLoading } = useSelector((state) => state.advertisment);
  const [submitted, setSubmitted] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState({});
  const [data, setData] = useState({
    title: "",
    url: "",
    description: "",
    base64_photos: "",
  });
  const title = data;

  function onImageChange(e) {
    setImages([...e.target.files]);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setImage64(reader.result);
    };
  }
  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setImageUrls(newImageUrls);
  }, [images]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    const newData = { ...data, base64_photos: [image64] };
    cleanInput(newData);

    if (title) {
      // dispatch action to create data
      const response = await dispatch(createAdvertisment(newData));
      if (response.status === "success") {
        props.onClose();
        dispatch(getAllAdvertisments());
      }
    }
  };
  return (
    <div>
      <Transition appear show={props.show}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-white dark:bg-[#121212] w-[380px] text-left sm:w-[630px] p-4 sm:p-6">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-sm p-1 sm:p-3 "
                        onClick={props.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    <div className="pb-5 text-center sm:mt-2 sm:text-center border-b border-[#E4E4F3] dark:border-[#505050]">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold text-black dark:text-white"
                      >
                        Add New Slider
                      </Dialog.Title>
                    </div>
                  </div>
                  <form
                    id="slider_form"
                    className="py-5 mt-4 px-5"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <Input.Label
                      title={"Title"}
                      name="title"
                      id="title"
                      placeholder="Enter title"
                      onChange={handleChange}
                    />
                    {submitted && !title && (
                      <div className="text-primary text-xs ">
                        Title is required
                      </div>
                    )}
                    <Input.Label
                      title={"Description"}
                      name="description"
                      id="description"
                      placeholder="Enter description"
                      onChange={handleChange}
                    />
                    <Input.Label
                      title={"Link/URL"}
                      name="url"
                      id="url"
                      placeholder="Enter URL"
                      onChange={handleChange}
                    />

                    <div className="mt-8 mx-auto text-center w-full bg-[#FFFBFA] dark:bg-[#121212] p-5 border border-dashed border-[#E4E4F3] dark:border-[#505050] rounded-md cursor-pointer">
                      {imageUrls?.length > 0 ? (
                        imageUrls?.map((image, index) => (
                          <div key={index}>
                            <img
                              src={image}
                              className="object-cover w-full h-full"
                              alt="food"
                            />
                          </div>
                        ))
                      ) : (
                        <div>
                          <UploadIcon className="w-8 h-8 mx-auto mb-3" />
                          <p
                            className="text-[#676565] dark:text-white"
                            onClick={handleFileClick}
                          >
                            Click here to select a photo (1440px by 576px)
                          </p>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={onImageChange}
                            ref={hiddenFileInput}
                          />
                        </div>
                      )}
                    </div>
                  </form>

                  <div className="py-8 max-w-3xl mx-auto text-center">
                    <Button.Primary
                      title={isLoading ? <Beat /> : "Activate New Slider"}
                      className="px-4"
                      form="slider_form"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
