import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Switch } from "@headlessui/react";
import DotMenu from "../../../assets/images/icons/dot-menu.svg";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import {
  getARewardAction,
  approveRewardByMgtAction,
  unapproveRewardByMgtAction,
} from "../../../redux/reward/rewardActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RewardDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { singleReward, isLoading } = useSelector((state) => state.reward);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [enabledApprove, setEnabledApprove] = useState();
  const [data, setData] = useState([]);

  console.log("singleReward", singleReward);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getARewardAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setEnabledApprove(singleReward?.management_approved);
    // eslint-disable-next-line
  }, [singleReward?.id]);

  const toggleBtn = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleApprove = () => {
    dispatch(approveRewardByMgtAction({ id: singleReward?.id }));
  };

  const handleUnapprove = () => {
    dispatch(unapproveRewardByMgtAction({ id: singleReward?.id }));
  };

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((referral, index) => {
        let show = {
          id: index + 1,
          user_name: referral?.name,
          address: referral?.address,
          email: referral?.email,
          phone: referral?.phone ?? "N/A",
          created: (
            <span>{moment(referral?.created_at).format("MMM DD, YYYY")}</span>
          ),
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(singleReward?.users);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleReward]);

  const referralColumns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Name",
            accessor: "show.user_name",
          },
          {
            Header: "Address",
            accessor: "show.address",
          },
          {
            Header: "Email",
            accessor: "show.email",
          },
          {
            Header: "Created On",
            accessor: "show.created",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      {/* <AddFoodVariationModal show={isOpen} onClose={closeModal} /> */}

      <div data-test="adminFooddetail-page">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Reward Details</h3>

            <div className="relative block sm:hidden">
              <button
                className="flex text-[#717485] gap-1 items-center"
                onClick={toggleBtn}
                type="button"
              >
                <img src={DotMenu} alt="menu" />
              </button>
              {openDropdown && (
                <div className="absolute right-0 z-10 w-[280px] border-[#E4E4F3] bg-white dark:bg-[#121212] px-0 overflow-y-auto shadow-xl transform transition-all">
                  <div className="grid grid-cols-1 gap-4 p-4">
                    <Button.Primary
                      title={"Edit Reward"}
                      onClick={() =>
                        navigate(`/admin/food/edit/${singleReward?.id}`, {
                          state: { food: singleReward },
                        })
                      }
                      className="px-5"
                      type={"button"}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="hidden sm:flex gap-4 items-center">
              <Button.Primary
                title={"Edit Reward"}
                onClick={() =>
                  navigate(`/admin/rewards/edit/${singleReward?.id}`, {
                    state: { reward: singleReward },
                  })
                }
                className="px-5"
                type={"button"}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Reward ID
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleReward?.id}
              </p>
            </div>

            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Title
              </label>
              <p className="text-[#151515] dark:text-white text-sm ">
                {singleReward?.title}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Point
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleReward?.points}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Count
              </label>
              <p className="text-[#151515] dark:text-white text-sm capitalize">
                {singleReward?.configuration?.count}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Validity
              </label>
              <p className="text-[#151515] dark:text-white text-sm">
                {singleReward?.configuration?.validity}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                {singleReward?.management_approved
                  ? "Unapprove Reward"
                  : "Approve Reward"}
              </label>
              <Switch.Group as="div" className="flex">
                <Switch
                  checked={enabledApprove}
                  onChange={(e) => {
                    setEnabledApprove(e);
                    singleReward?.management_approved
                      ? handleUnapprove()
                      : handleApprove();
                  }}
                  className={classNames(
                    enabledApprove ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabledApprove ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8 mt-5 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Reward Images (200px by 200px)
              </h3>
              <p className="text-sm">
                Accepts .jpg, .png and .jpeg image formats
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-5 sm:mt-8">
            <figure className="w-full h-[160px] relative">
              <img
                src={singleReward?.picture}
                className="object-cover w-full h-full"
                alt="food"
              />
            </figure>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Users</h3>
          </div>
          <div className="bg-white dark:bg-[#121212] rounded-md py-4">
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                columns={referralColumns}
                data={data?.length > 0 ? data : []}
                onClick={() => {}}
                className="w-full"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
