import errorHandler from "../../handlers/errorHandler";
import { publicRestaurantService } from "../../services/publicRestaurant.service";
import restaurantType from "./publicRestaurantTypes";

/**
 * Get all restaurants
 **/

export const getAllPublicRestaurantsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: true });
    const response = await publicRestaurantService.getAllPublicRestaurants(
      page
    );
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    dispatch({ type: restaurantType["ALL_RESTAURANTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get more restaurants
 **/

export const getMorePublicRestaurantsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_MORE"], payLoad: true });
    const response = await publicRestaurantService.getAllPublicRestaurants(
      page
    );
    dispatch({ type: restaurantType["LOADING_MORE"], payLoad: false });
    dispatch({ type: restaurantType["MORE_RESTAURANTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_MORE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a restaurants
 **/

export const getPublicRestaurantAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: true });
    const response = await publicRestaurantService.getPublicRestaurant(id);
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    dispatch({ type: restaurantType["SINGLE_RESTAURANT"], payLoad: response });
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter restaurants
 **/

export const filterPublicRestaurantsAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: true });
      const response = await publicRestaurantService.filterRestaurants(
        payload,
        page
      );
      dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
      dispatch({ type: restaurantType["FILTER_RESTAURANTS"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Filter more restaurants
 **/

export const filterMorePublicRestaurantsAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: restaurantType["LOADING_MORE"], payLoad: true });
      const response = await publicRestaurantService.filterRestaurants(
        payload,
        page
      );
      dispatch({ type: restaurantType["LOADING_MORE"], payLoad: false });
      dispatch({ type: restaurantType["MORE_RESTAURANTS"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: restaurantType["LOADING_MORE"], payLoad: false });
      errorHandler(error, true);
    }
  };

  /**
 * Search restaurants
 **/

export const searchPublicRestaurantsAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: true });
    const response = await publicRestaurantService.searchPublicRestaurants(
      payload
    );
    dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: false });
    errorHandler(error, true);
  }
};
