import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../../../components/inputs/inputs";
import { Beat } from "../../../../plugins/spinners.plugin";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import Background from "../../../../assets/images/pictures/create-restaurant.png";
import {
  // getAllTags,
  filterProductTag,
} from "../../../../redux/productTags/productTagActions";
import {
  // getAllCategories,
  filterProductCategory,
} from "../../../../redux/productCatergory/productCategoryAction";

export default function OnboardRestaurant({
  handleChange,
  data,
  setData,
  handleSubmit,
  handleBack,
  submitted,
  Location,
  locationId,
  locationList,
  setLocationList,
  setLocationId,
  restaurant_phone,
  setRestaurantPhone,
  handleRegion,
}) {
  const dispatch = useDispatch();
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const {
    restaurant_name,
    restaurant_email,
    restaurant_address,
    restaurant_city,
    restaurant_state,
    restaurant_country,
    restaurant_description,
    restaurant_categories,
    restaurant_tags,
  } = data;

  useEffect(() => {
    // dispatch(getAllTags());
    dispatch(filterProductTag({ type: "restaurant" }, 1));
    // dispatch(getAllCategories());
    dispatch(filterProductCategory({ type: "restaurant" }, 1));
    // eslint-disable-next-line
  }, []);

  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [restaurantTags, setRestaurantTags] = useState([]);
  const [locationLoader, setLocationLoader] = useState(false);

  const { allTags } = useSelector((state) => state.productTag);
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { isLoading } = useSelector((state) => state.onboard);

  useEffect(() => {
    const restaurantCategories = allCategories?.data?.filter((item) => {
      return item?.type === "restaurant";
    });
    setRestaurantCategories(restaurantCategories);
    // eslint-disable-next-line
  }, [allCategories]);

  useEffect(() => {
    const restaurantTags = allTags?.data?.filter((item) => {
      return item?.type === "restaurant";
    });
    setRestaurantTags(restaurantTags);
    // eslint-disable-next-line
  }, [allTags]);

  const handleAddTags = (value) => {
    const former_tags = [...data?.restaurant_tags];
    let new_tags = [...former_tags, value.name];
    setData({ ...data, restaurant_tags: new_tags });
  };

  const handleAddCategories = (value) => {
    const former_categories = [...data?.restaurant_categories];
    let new_categories = [...former_categories, value.name];
    setData({ ...data, restaurant_categories: new_categories });
  };

  return (
    <div>
      <div
        className="pt-4 sm:pt-2 sm:flex items-stretch items-center py-4 max-w-7xl w-full mx-auto sm:px-6 lg:px-6"
        data-test="businessComponent"
      >
        <div className="dark:bg-[#121212] sm:max-w-[550px] flex-1 px-4 sm:px-10 py-10 sm:rounded-tl-[25px] sm:rounded-bl-[25px] sm:border dark:border-none">
          <div className="mb-4">
            <h3 className="text-3xl mb-3 font-semibold text-black dark:text-white">
              Let's work together!
            </h3>
            <p>
              Ogwugo is your dedicated business service agency, helping you
              automate daily operations and grow your customer base. Let’s bring
              your vision to life! 🚀
            </p>
          </div>

          <div className="w-full py-4">
            <form
              name="form "
              id="business-form"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <div
                  className={
                    "mt-4" +
                    (submitted && restaurant_name ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Restaurant Name "}
                    htmlFor={"restaurant_name"}
                    type={"text"}
                    value={restaurant_name}
                    name={"restaurant_name"}
                    onChange={handleChange}
                    placeholder={"Enter restaurant name"}
                  />
                </div>

                <div
                  className={
                    "mt-4" +
                    (submitted && restaurant_address ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Restaurant Address *"}
                    htmlFor={"restaurant_address"}
                    value={restaurant_address}
                    type={"text"}
                    name={"restaurant_address"}
                    onChange={handleChange}
                    placeholder={"Enter Restaurant Address"}
                  />
                </div>

                <div className="grid sm:grid-cols-2 gap-4">
                  <div
                    className={
                      submitted && restaurant_phone ? " border-red-500" : ""
                    }
                  >
                    <Input.Phone
                      defaultCountry="NG"
                      title={"Restaurant Phone Number"}
                      htmlFor={"restaurant_phone"}
                      value={restaurant_phone}
                      onChange={setRestaurantPhone}
                      placeholder={"Enter phone number"}
                    />
                  </div>

                  <div
                    className={
                      submitted && restaurant_email ? " border-red-500" : ""
                    }
                  >
                    <Input.Label
                      title={"Restaurant Email *"}
                      htmlFor={"restaurant_email"}
                      type={"email"}
                      name={"restaurant_email"}
                      onChange={handleChange}
                      placeholder={"Enter restaurant email address"}
                    />
                  </div>
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && restaurant_description
                      ? " border-red-500"
                      : "")
                  }
                >
                  <Input.TextArea
                    title={"Restaurant Description "}
                    htmlFor={"restaurant_description"}
                    value={restaurant_description}
                    type={"text"}
                    name={"restaurant_description"}
                    onChange={handleChange}
                    placeholder={"Describe your restaurant to us"}
                  />
                </div>

                <div className="grid sm:grid-cols-2 gap-4">
                  <div
                    className={
                      "mt-4" +
                      (submitted && restaurant_categories
                        ? " border-red-500"
                        : "")
                    }
                  >
                    <label
                      htmlFor="restaurant_categories"
                      className="text-sm font-medium text-[#151515] dark:text-white"
                    >
                      Restaurant Categories*
                    </label>
                    <SelectableInput
                      options={restaurantCategories}
                      label="restaurant_categories"
                      name={"+ Add Category"}
                      type="category"
                      selectOption={handleAddCategories}
                    />
                  </div>

                  <div
                    className={
                      "mt-4" +
                      (submitted && restaurant_tags ? " border-red-500" : "")
                    }
                  >
                    <label
                      htmlFor="restaurant_tags"
                      className="text-sm font-medium text-[#151515] dark:text-white"
                    >
                      Restaurant Tags*
                    </label>
                    <SelectableInput
                      options={restaurantTags}
                      label="restaurant_tags"
                      name={"+ Add Tag"}
                      type="tag"
                      selectOption={handleAddTags}
                    />
                  </div>
                </div>

                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  loadingLocation={(value) => {
                    setLocationLoader(value);
                  }}
                  countryList={(value) => {
                    setLocationList({
                      ...locationList,
                      countryList: value,
                    });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                />
                <div
                  className={
                    "mt-4" +
                    (submitted && restaurant_country ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="restaurant_country"
                    className="text-sm font-medium text-[#151515] dark:text-white"
                  >
                    Country*
                  </label>
                  <select
                    name="restaurant_country"
                    className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFFFFF] dark:bg-[#121212] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>
                      {restaurant_country
                        ? restaurant_country
                        : "Select country"}
                    </option>

                    {locationLoader ? (
                      <option>Loading...</option>
                    ) : (
                      locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })
                    )}
                  </select>

                  {/* {submitted && !restaurant_country && (
                    <div className=" text-xs text-red-500">
                      Restaurant country is required
                    </div>
                  )} */}
                </div>

                <div className="grid sm:grid-cols-2 gap-4">
                  <div
                    className={
                      submitted && restaurant_state ? " border-red-500" : ""
                    }
                  >
                    <label
                      htmlFor="restaurant_state"
                      className="text-sm font-medium text-[#151515] dark:text-white"
                    >
                      {" "}
                      State{" "}
                    </label>
                    <select
                      name="restaurant_state"
                      className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFFFFF] dark:bg-[#121212] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          state_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                    >
                      <option>
                        {restaurant_state ? restaurant_state : "Select state"}
                      </option>

                      {locationLoader ? (
                        <option>Loading...</option>
                      ) : (
                        locationList.stateList &&
                        locationList.stateList.length > 0 &&
                        locationList.stateList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({ name: item.name, id: item.id })}
                            >
                              {item.name}
                            </option>
                          );
                        })
                      )}
                    </select>
                    {/* {submitted && !restaurant_state && (
                    <div className="text-xs text-red-500">
                      Restaurant state is required.
                    </div>
                  )} */}
                  </div>
                  <div
                    className={
                      submitted && restaurant_city ? " border-red-500" : ""
                    }
                  >
                    <label
                      htmlFor="restaurant_city"
                      className="text-sm font-medium text-[#151515] dark:text-white"
                    >
                      {" "}
                      City{" "}
                    </label>
                    <select
                      name="restaurant_city"
                      className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFFFFF] dark:bg-[#121212] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                      onChange={(e) => {
                        setLocationId({
                          ...locationId,
                          city_id: fromJson(e.target.value).id,
                        });
                        handleRegion(e);
                      }}
                    >
                      <option>
                        {restaurant_city ? restaurant_city : "Select a city"}
                      </option>
                      {locationLoader ? (
                        <option>Loading...</option>
                      ) : (
                        locationList.cityList &&
                        locationList.cityList.length > 0 &&
                        locationList.cityList.map((item, key) => {
                          return (
                            <option
                              key={key}
                              value={toJson({ name: item.name, id: item.id })}
                            >
                              {item.name}
                            </option>
                          );
                        })
                      )}
                    </select>
                    {/* {submitted && !restaurant_city && (
                    <div className="text-xs text-red-500">
                      Restaurant city is required.
                    </div>
                  )} */}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="items-center flex justify-between gap-4 sm:mt-10">
            <div className="items-center flex gap-2">
              <span className="w-[10px] h-[10px] rounded-full inline-block bg-[#A39D9D] dark:bg-white"></span>
              <span className="w-[10px] h-[10px] rounded-full inline-block bg-primary"></span>
              <span className="text-sm ml-4">Phase 2 of 2</span>
            </div>

            <div className="flex gap-4">
              <button
                onClick={handleBack}
                className="rounded-md px-4 sm:mb-0 shadow-sm px-3 py-3 border border-primary text-primary text-base font-medium bg-white text-sm"
              >
                Back
              </button>

              <button
                form="business-form"
                type="submit"
                className=" rounded-md min-w-[100px] shadow-sm px-4 py-3 bg-primary text-base font-medium text-white hover:bg-red-700 text-sm"
              >
                {isLoading ? <Beat color={"#ffffff"} /> : "Submit"}
              </button>
            </div>
          </div>
        </div>

        <div
          className="hidden sm:block w-full flex-1 rounded-tr-[25px] rounded-br-[25px] bg-cover bg-center"
          style={{ backgroundImage: `url(${Background})` }}
        />
      </div>
    </div>
  );
}
