import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FeatureRates from './FeatureRates';
import PlatformRatesSettings from './PlatformRates';

const tabs = [
  { name: "Feature Rates", href: "feature-rates" },
  { name: "Platform Rates", href: "platform-rates" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RateSettings() {
  const navigate = useNavigate();
  const [active_tab, setActiveTab] = useState("feature-rates");

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/settings/application`);
    }
  });

  const toggle = (tab) => {
    if (active_tab !== tab) {
      setActiveTab(tab.href);
    }
  };

  return (
    <div data-test="rate-page">
      <div className="mb-5 sm:max-w-[630px] w-full mx-auto">
        <div className="border-b border-gray-200 dark:border-none">
          <nav
            className="-mb-px flex space-x-8 overflow-x-scroll sm:overflow-hidden"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {active_tab === "feature-rates" && <FeatureRates />}
      {active_tab === "platform-rates" && <PlatformRatesSettings />}
    </div>
  );
}
