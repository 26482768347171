import React, { useMemo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from '../../../../components/buttons/buttons';
import Table from "../../../../components/tables/tableCols2";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import Settings from "../../../../assets/images/icons/settingsEmpty.svg";
import { Pagination } from "../../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import KYCTiersFilterDropDown from '../../../../components/dropDown/filter/kycTiersFilter';
import { filterHumanVerificationLevel,searchHumanVerificationLevel } from '../../../../redux/humanVerificationLevel/humanVerificationLevelActions';

export default function Tiers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();

  const { filtering, allVerificationLevel, searching } = useSelector(
    (state) => state.humanVerificationLevel
  );

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  const [payLoad] = useState({
    end_date: new Date(),
  });

  useEffect(() => {
    dispatch(filterHumanVerificationLevel(payLoad, page));
  }, [dispatch, page, payLoad]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((tier, index) => {
        let singleRowArray = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          title: tier.title,
          daily: tier.daily_limit,
          monthly: tier.monthly_limit,
          date: moment(tier.created_at).format("MMM DD, YYYY"),
          tierData: tier,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allVerificationLevel?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVerificationLevel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };
  const showSearch = (search) => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allVerificationLevel?.data);
      setData(dataSet);
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchHumanVerificationLevel(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);


  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "num",
          },
          {
            Header: "Tiers",
            accessor: "title",
          },
          {
            Header: "Daily Limit",
            accessor: "daily",
          },
          {
            Header: "Monthly Limit",
            accessor: "monthly",
          },
          {
            Header: "Created On",
            accessor: "date",
          },
          
        ],
      },
    ],
    []
  );

  return (
    <>
      <div>
        <TableFilter
          results={allVerificationLevel?.per_page}
          totalResults={allVerificationLevel?.total}
          onChange={handleChange}
          title={"KYC Tiers"}
          payLoad={payLoad}
          page={page}
          filter={
            <AllFiltersModal
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            >
              <KYCTiersFilterDropDown
                isFilterOpen={isFilterOpen}
                setFilterState={setFilterState}
                payLoad={payLoad}
                page={page}
              />
            </AllFiltersModal>
          }
          handleKeyDown={handleKeyDown}
          searchPlaceholder={"Search kyc tiers"}
        >
          <Button.Primary
            title={"+ Create KYC Tier"}
            onClick={() =>
              navigate("/admin/settings/kyc-verification/create-tier")
            }
          />
        </TableFilter>

        <div className="border-b border-[#E4E4F3]" />
        <div className="bg-white dark:bg-[#121212] rounded-md py-4 ">
          {filtering || searching ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={() => {}}
              // rowClass="hover:shadow-md cursor-pointer"
            />
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                <img src={Settings} alt="settings" />
              </div>
              <p className="font-bold text-xl my-3">KYC Tiers</p>
              <p className="text-sm text-gray-500">
                You do not have any KYC tiers yet
              </p>
            </div>
          )}
        </div>

        <Pagination
          data={allVerificationLevel}
          route={"/admin/settings/kyc-verification/tiers"}
        />
      </div>
    </>
  );
}
