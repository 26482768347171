import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import authReducer from "./auth/authReducer";
import profileReducer from "./profile/profileReducer";
import userReducer from "./users/userReducer";
import productCategoriesReducer from "./productCatergory/productCategoryReducer";
import inviteReducer from "./invite/inviteReducer";
import employeesReducer from "./employee/employeeReducer";
import onboardReducer from "./onBoarding/onBoardingReducer";
import addressReducer from "./address/addressReducer";
import referralReducer from "./referral/referralReducer";
import roleReducer from "./roles/rolesReducer";
import productTagReducer from "./productTags/productTagReducer";
import businessReducer from "./business/businessReducer";
import storeReducer from "./stores/storeReducer";
import attributeReducer from "./attribute/attributeReducer";
import merchandiseReducer from "./merchandise/merchandiseReducer";
import foodReducer from "./food/foodReducer";
import restaurantReducer from "./restaurant/restaurantReducer";
import publicRestaurantReducer from "./publicRestaurant/publicRestaurantReducer";
import publicStoreReducer from "./publicStore/publicStoreReducers";
import publicMerchandiseReducer from "./publicMerchandise/publicMerchandiseReducer";
import CartReducer from "./cart/cartReducer";
import packagesReducer from "./packages/packagesReducer";
import subscriptionFoodReducer from "./subscriptionFood/subscriptionFoodReducer";
import publicPackagesReducer from "./publicPackages/publicPackagesReducer";
import CouponReducer from "./coupons/couponReducers";
import currencyReducer from "./currency/currencyReducer";
import publicFoodReducer from "./publicFood/publicFoodReducer";
import advertismentReducer from "./advertisment/advertismentReducer";
import arrangementReducer from "./arrangements/arrangmentReducer";
import StatisticsReducer from "./statistics/statisticsReducer";
import featureReducer from "./feature/featureReducer";
import ShippersReducer from "./shippers/shippersReducer";
import publicAdvertismentReducer from "./publicAdvertisment/publicAdvertismentReducer";
import restaurantOrderReducer from "./restaurantOrder/restaurantOrderReducer";
import packageSubscriptionFoodOrderReducer from "./packageSubscriptionFoodOrder/packageSubscriptionFoodOrderReducer";
import storeOrderReducer from "./storeOrders/storeOrderReducer";
import publicCartReducer from "./publicCart/publicCartReducer";
import applicationReducer from "./applicationSettings/applicationReducer";
import subscriptionMerchReducer from "./subscriptionMerchandise/subscriptionMerchReducer";
import catalogueReducer from "./catalogue/catalogueReducer";
import paymentReducer from "./payment/paymentReducer";
import publicCatalogueReducer from "./publicCatalogues/publicCatalogueReducer";
import packageSubscriptionMerchandiseOrderReducer from "./packageSubscriptionMerchandiseOrder/packageSubscriptionMerchandiseOrderReducer";
import thirdPartyReducer from "./thirdParty/thirdPartyReducer";
import serviceAreaReducer from "./serviceAreas/serviceAreasReducer";
import publicServiceAreaReducer from "./publicServiceAreas/publicServiceAreasReducer";
import scoutReducer from "./scout/scoutReducer";
import promoReducer from "./promo/promoReducers";
import feedbackReducer from "./feedback/feedbackReducer";
import businessSettingsReducer from "./businessSettings/businessSettingsReducer";
import notifierReducer from "./notifier/notifierReducer";
import notifierGroupReducer from "./notifierGroup/notifierGroupReducer";
import humanVerificationReducer from "./humanVerification/humanVerificationReducer";
import humanVerificationLevelReducer from "./humanVerificationLevel/humanVerificationLevelReducer";
import contactReducer from "./contacts/contactReducer";
import timezoneReducer from "./timezone/timezoneReducer";
import rewardReducer from "./reward/rewardReducer";

// Combine all reducers
const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  user: userReducer,
  productCatergory: productCategoriesReducer,
  invite: inviteReducer,
  employee: employeesReducer,
  onboard: onboardReducer,
  address: addressReducer,
  referral: referralReducer,
  roles: roleReducer,
  productTag: productTagReducer,
  business: businessReducer,
  store: storeReducer,
  attribute: attributeReducer,
  merchandise: merchandiseReducer,
  food: foodReducer,
  cart: CartReducer,
  restaurant: restaurantReducer,
  publicRestaurant: publicRestaurantReducer,
  publicStore: publicStoreReducer,
  publicMerchandise: publicMerchandiseReducer,
  packages: packagesReducer,
  subscriptionFood: subscriptionFoodReducer,
  subscriptionMerch: subscriptionMerchReducer,
  publicPackages: publicPackagesReducer,
  publicFood: publicFoodReducer,
  publicCatalogue: publicCatalogueReducer,
  coupon: CouponReducer,
  currency: currencyReducer,
  advertisment: advertismentReducer,
  arrangement: arrangementReducer,
  statistics: StatisticsReducer,
  feature: featureReducer,
  shipper: ShippersReducer,
  publicAdvertisment: publicAdvertismentReducer,
  restaurantOrder: restaurantOrderReducer,
  packageSubscriptionFoodOrder: packageSubscriptionFoodOrderReducer,
  storeOrder: storeOrderReducer,
  publicCart: publicCartReducer,
  application: applicationReducer,
  catalogue: catalogueReducer,
  payment: paymentReducer,
  packageSubscriptionMerchandiseOrder:
    packageSubscriptionMerchandiseOrderReducer,
  thirdParty: thirdPartyReducer,
  serviceArea: serviceAreaReducer,
  publicServiceArea: publicServiceAreaReducer,
  scout: scoutReducer,
  promo: promoReducer,
  feedback: feedbackReducer,
  businessSettings: businessSettingsReducer,
  notifier: notifierReducer,
  notifierGroup: notifierGroupReducer,
  humanVerification: humanVerificationReducer,
  humanVerificationLevel: humanVerificationLevelReducer,
  contact: contactReducer,
  timezone: timezoneReducer,
  reward: rewardReducer,
});

// Create a name for the reducer in local storage
const reducerName = process.env.REACT_APP_SLUG + "_reducer";

// Load the reducer state from local storage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem(reducerName);
    return serializedState === null ? {} : JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

// Save the reducer state to local storage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    return localStorage.setItem(reducerName, serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

const initialState = loadState();
const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
);
store.subscribe(() => saveState(store.getState()));

export default store;
