const FirebaseMessage = ({ notification }) => {
  console.log("notification", notification);
  return (
    <>
      <div id="notificationHeader">
        {/* image is optional */}
        {notification.image && (
          <div id="imageContainer">
            <img src={notification.image} width={100} alt='notify' />
          </div>
        )}
        <span>{notification.title}ggg</span>
      </div>
      <div id="notificationBody">{notification.body}fff</div>
    </>
  );
};

export default FirebaseMessage;
