import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { cleanInput } from "../../../../helpers/cleanInput";
import { Beat } from "../../../../plugins/spinners.plugin";
import {
  showApplication,
  updateApplication,
} from "../../../../redux/applicationSettings/applicationAction";
import DeliveryRange from "../DeliveryRange";

export default function ApplicationSettings() {
  const dispatch = useDispatch();
  const { updating, settings } = useSelector((state) => state.application);

  const [data, setData] = useState({
    restaurant_operating_status: settings?.restaurant_operating_status,
    store_operating_status: settings?.store_operating_status,
    multiple_outlet_checkout_status: settings?.multiple_outlet_checkout_status,
    pay_stack_payment_status: settings?.pay_stack_payment_status,
    flutter_wave_payment_status: settings?.flutter_wave_payment_status,
    ogwugo_payment_status: settings?.ogwugo_payment_status,
    automatic_shipping_status: settings?.automatic_shipping_status,
    package_delivery_periods: settings?.package_delivery_periods,
  });

  useEffect(() => {
    dispatch(showApplication());
  }, [dispatch]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleUpdate = async () => {
    cleanInput(data);
    const response = dispatch(updateApplication({ ...data }));
    if (response.status === "success") {
      dispatch(showApplication());
    }
  };

  return (
    <>
      <div className="w-100 mx-auto">
        <div className="mx-auto bg-white dark:bg-[#121212] sm:w-[630px] w-full mt-10 sm:mt-0 sm:border p-6 sm:border-gray-200 dark:border-none">
          <div className="border-b-2 dark:border-[#505050] py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 dark:text-white flex justify-center ">
              Application Settings
            </p>
          </div>

          <form>
            <div className=" sm:px-10 mb-10">
              <Input.Select
                title={" All Restaurant Operating Status"}
                name="restaurant_operating_status"
                htmlFor={"restaurant_operating_status"}
                defaultValue={settings?.restaurant_operating_status}
                onChange={handleChange}
              >
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>
              <Input.Select
                title={" All Store Operating Status"}
                name="store_operating_status"
                htmlFor={"store_operating_status"}
                defaultValue={settings?.store_operating_status || ""}
                onChange={handleChange}
              >
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>
              <Input.Select
                title={" Multiple Store Checkout"}
                name="multiple_outlet_checkout_status"
                htmlFor={"multiple_outlet_checkout_status"}
                defaultValue={settings?.multiple_outlet_checkout_status}
                onChange={handleChange}
              >
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>
              <Input.Select
                title={"Automatic Shipping Status"}
                name="automatic_shipping_status"
                htmlFor={"automatic_shipping_status"}
                defaultValue={settings?.automatic_shipping_status}
                onChange={handleChange}
              >
                <option value={"unavailable"}>Unavailable</option>
                <option value={"available"}>Available</option>
              </Input.Select>
              <Input.Select
                title={"Paystack Payment Method"}
                name="pay_stack_payment_status"
                htmlFor={"pay_stack_payment_status"}
                defaultValue={settings?.pay_stack_payment_status}
                onChange={handleChange}
              >
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>
              <Input.Select
                title={"Ogwugo Payment Method"}
                name="ogwugo_payment_status"
                htmlFor={"ogwugo_payment_status"}
                defaultValue={settings?.ogwugo_payment_status}
                onChange={handleChange}
              >
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>
              <Input.Select
                title={"Flutterwave Payment Method"}
                name="flutter_wave_payment_status"
                htmlFor={"flutter_wave_payment_status"}
                defaultValue={settings?.flutter_wave_payment_status}
                onChange={handleChange}
              >
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>

              {/* Delivery time ranges */}

              {settings?.package_delivery_periods &&
                Object.keys(settings?.package_delivery_periods)?.map(
                  (period, index) => (
                    <DeliveryRange
                      key={index}
                      period={period}
                      settings={settings}
                      data={data}
                      setData={setData}
                    />
                  )
                )}
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center mx-auto">
        <Button.Primary
          disabled={updating}
          title={updating ? <Beat color={"#ffffff"} /> : "Update Settings"}
          className={"px-4 rounded-sm sm:mt-10"}
          onClick={handleUpdate}
        />
      </div>
    </>
  );
}
