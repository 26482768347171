import errorHandler from "../../handlers/errorHandler";
import publicMerchandiseType from "./publicMerchandiseTypes";
import { publicMerchandiseService } from "./../../services/publicMerchandise";

/**
 * Get all PublicMerchandise
 **/

export const getAllPublicMerchandise = (page) => async (dispatch) => {
  try {
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: true,
    });
    const response = await publicMerchandiseService.getAllPublicMerchandises(
      page
    );
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: false,
    });
    dispatch({
      type: publicMerchandiseType["ALL_PUBLIC_MERCHANDISE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Get a PublicMerchandise
 **/

export const getPublicMerchandise = (id) => async (dispatch) => {
  try {
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: true,
    });
    const response = await publicMerchandiseService.getPublicMerchandise(id);
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: false,
    });
    dispatch({
      type: publicMerchandiseType["SINGLE_PUBLIC_MERCHANDISE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Filter PublicMerchandise
 **/

export const filterPublicMerchandise = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: true,
    });
    const response = await publicMerchandiseService.filterPublicMerchandise(
      payLoad
    );
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: false,
    });
    dispatch({
      type: publicMerchandiseType["FILTER_PUBLIC_MERCHANDISE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const searchPublicMerchandise = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: true,
    });
    const response = await publicMerchandiseService.searchPublicMerchandise(
      payLoad
    );

    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: false,
    });
    dispatch({
      type: publicMerchandiseType["SEARCH_PUBLIC_MERCHANDISE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: publicMerchandiseType["LOADING_PUBLIC_MERCHANDISE"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
