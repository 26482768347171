import errorHandler from "../../handlers/errorHandler";
import { publicFoodService } from "../../services/publicFood.service";
import foodType from "./publicFoodTypes";

/**
 * Get all Foods
 **/

export const getAllPublicFoodsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: true });
    const response = await publicFoodService.getAllPublicFoods();
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    dispatch({ type: foodType["ALL_FOODS"], payLoad: response });
  } catch (error) {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a Food
 **/

export const getAPublicFoodAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: true });
    const response = await publicFoodService.getAPublicFood(id);
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    dispatch({ type: foodType["SINGLE_FOOD"], payLoad: response });
  } catch (error) {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

  /**
 * Search foods
 **/

export const searchPublicFoodsAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: foodType["SEARCHING_FOODS"], payLoad: true });
    const response = await publicFoodService.searchPublicFoods(
      payload
    );
    dispatch({ type: foodType["SEARCHING_FOODS"], payLoad: false });
    dispatch({ type: foodType["SEARCH_PUBLIC_FOODS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: foodType["SEARCHING_FOODS"], payLoad: false });
    errorHandler(error, true);
  }
};