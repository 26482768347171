import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Table from "../../../../components/tables/tableCols2";
import { Pagination } from "../../../../components/pagination/pagination";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import {
  filterNotifier,
  searchNotifier,
} from "../../../../redux/notifier/notifierAction";
import Settings from "../../../../assets/images/icons/settingsEmpty.svg";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterCategoryDropDown from "../../../../components/dropDown/filter/categoryFilter";

export default function Email() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { page } = useParams();

  const { filtering, search, searching, allNotifier } = useSelector(
    (state) => state.notifier
  );

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  const [payLoad] = useState({
    type: "mail",
    end_date: new Date(),
  });

  useEffect(() => {
    dispatch(filterNotifier(payLoad, page));
  }, [dispatch, payLoad, page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((notify, index) => {
        let singleRowArray = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          title: notify.title,
          status: notify.status,
          date: moment(notify.created_at).format("MMM. DD, YYYY"),
          notify: notify,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allNotifier?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifier]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allNotifier?.data);
      setData(dataSet);
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchNotifier(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "num",
          },
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Date Sent",
            accessor: "date",
          },
        ],
      },
    ],
    []
  );

  const goToSinglePage = (params) => {
    navigate(`/admin/settings/broadcast-notification/notification/${params?.notify?.id}`);
  };

  return (
    <>
      <div>
        <TableFilter
          results={allNotifier?.data?.length}
          totalResults={allNotifier?.data?.length}
          onChange={handleChange}
          title={"Email"}
          payLoad={payLoad}
          page={page}
          filter={
            <AllFiltersModal
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            >
              <FilterCategoryDropDown
                isFilterOpen={isFilterOpen}
                setFilterState={setFilterState}
                payLoad={payLoad}
              />
            </AllFiltersModal>
          }
          searchPlaceholder={"Name"}
        >
          <Button.Primary
            title={"+ Create Mail"}
            onClick={() =>
              navigate("/admin/settings/broadcast-notification/create-email")
            }
          />
          <Button.Secondary
            title={"Recipients"}
            onClick={() =>
              navigate(
                "/admin/settings/broadcast-notification/recipient-groups"
              )
            }
          />
        </TableFilter>

        <div className="border-b border-[#E4E4F3]" />
        <div className="bg-white dark:bg-[#121212] rounded-md py-4 ">
          {filtering || searching ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full cursor-pointer"
              onClick={goToSinglePage}
            />
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                <img src={Settings} alt="settinga" />
              </div>
              <p className="font-bold text-xl my-3">Notifications</p>
              <p className="text-sm text-gray-500">
                You do not have any notifications yet
              </p>
            </div>
          )}
        </div>

        <Pagination
          data={allNotifier}
          route={"/admin/settings/broadcast-notification/email"}
        />
      </div>
    </>
  );
}
