import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { getTeamFromCookies } from "../../../utils/Auth";
import MobileRoutes from "./MobileRoutes";
import Logo from "../../../assets/images/icons/ogwugo-logo.svg";
import { ReactComponent as FOOD } from "../../../assets/images/icons/food.svg";
import { ReactComponent as HOME } from "../../../assets/images/icons/home.svg";
import { ReactComponent as ORDERS } from "../../../assets/images/icons/orders.svg";
import { ReactComponent as OUTLET } from "../../../assets/images/icons/outlet.svg";
import { ReactComponent as PROFILE } from "../../../assets/images/icons/profile.svg";
import { ReactComponent as RESTAURANT } from "../../../assets/images/icons/restaurant.svg";
import { ReactComponent as CART } from "../../../assets/images/icons/cart.svg";
import { ReactComponent as USERS } from "../../../assets/images/icons/users.svg";
import { ReactComponent as MERCHANDISE } from "../../../assets/images/icons/merchandise.svg";
import { ReactComponent as PACKAGE } from "../../../assets/images/icons/Food-packages.svg";
import { ReactComponent as COUPON } from "../../../assets/images/icons/coupon.svg";
import { ReactComponent as EMPLOYEES } from "../../../assets/images/icons/employees.svg";
import { ReactComponent as BUSINESS } from "../../../assets/images/icons/customer.svg";
import { ReactComponent as SHIPPERS } from "../../../assets/images/icons/Shippers.svg";
import { ReactComponent as FEATURES } from "../../../assets/images/icons/feature.svg";
import { ReactComponent as MERCHPACKAGE } from "../../../assets/images/icons/Product.svg";
import { ReactComponent as CATALOGUE } from "../../../assets/images/icons/catalogu.svg";
import { ReactComponent as PAYMENT } from "../../../assets/images/icons/payment.svg";
import { ReactComponent as NOTIFICATION } from "../../../assets/images/icons/notification.svg";
import { ReactComponent as KYCICON } from "../../../assets/images/icons/kyc-icon.svg";
import { ReactComponent as FEEDBACK } from "../../../assets/images/icons/feedback.svg";
import CLOSE from "../../../assets/images/icons/close.svg";
import {
  getUserFromCookies,
  getOutletFromCookies,
  getOutletTypeFromCookies,
} from "./../../../utils/Auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideNav({ sidebarOpen, setSidebarOpen }) {
  const params = useParams();
  const user = getUserFromCookies();
  const team = getTeamFromCookies();
  const outlet = getOutletFromCookies();
  const outlet_type = getOutletTypeFromCookies();
  // eslint-disable-next-line no-unused-vars
  const userRole = user?.team_roles[team]?.roles;
  const outlet_name = outlet?.restaurant_name ?? outlet?.store_name;
  const restaurant = outlet_type === "restaurant";
  const store = outlet_type === "store";

  const navigation = [
    {
      name: "Home",
      href: "/admin/dashboard",
      icon: HOME,
      current: params["*"].includes("dashboard"),
      display: team === "administrator" || team.includes("management"),
    },
    {
      name: "Home",
      href: `/admin/${outlet_name}/dashboard`,
      icon: HOME,
      current: params["*"].includes("dashboard"),
      display: team !== "administrator" && !team.includes("management"),
    },
    {
      name: "Profile",
      href: "/profile/info",
      icon: PROFILE,
      current: params["*"].includes("profile"),
      display: true,
    },
    {
      name: "Orders",
      href: `/admin/${outlet_name}/orders/shipped/1`,
      icon: ORDERS,
      current: params["*"].includes("order"),
      display: team !== "administrator" && !team.includes("management"),
    },

    {
      name: "Merchandise",
      href: `/admin/${outlet_name}/merchandise`,
      icon: MERCHANDISE,
      current: params["*"].includes("merchandise"),
      display:
        team !== "administrator" && !team.includes("management") && store,
    },
  ];

  const operations = [
    {
      name: "Restaurant",
      href: "/admin/restaurants",
      icon: RESTAURANT,
      current:
        params["*"].includes("restaurant") &&
        !params["*"].includes("report") &&
        !params["*"].includes("order") &&
        !params["*"].includes("categories") &&
        !params["*"].includes("tags"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },

    {
      name: "Food",
      href: "/admin/food",
      icon: FOOD,
      current: params["*"].includes("food") && !params["*"].includes("package"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Food",
      href: `/admin/${outlet_name}/food`,
      icon: FOOD,
      current:
        params["*"].includes("food") &&
        !params["*"].includes("package") &&
        !params["*"].includes("dashboard"),
      display:
        team !== "administrator" && !team.includes("management") && restaurant,
    },

    {
      name: "Food Packages",
      href: "/admin/food-packages/packages/1",
      icon: PACKAGE,
      current:
        params["*"].includes("food-package") &&
        !params["*"].includes("report") &&
        !params["*"].includes("order"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },

    {
      name: "Stores",
      href: "/admin/stores",
      icon: OUTLET,
      current:
        params["*"].includes("store") &&
        !params["*"].includes("report") &&
        !params["*"].includes("order") &&
        !params["*"].includes("categories") &&
        !params["*"].includes("tags"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },

    {
      name: "Merchandise",
      href: "/admin/merchandise",
      icon: MERCHANDISE,
      current:
        params["*"].includes("merchandise") && !params["*"].includes("package"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Merchandise Packages",
      href: "/admin/merchandise-packages/packages",
      icon: MERCHPACKAGE,
      current:
        params["*"].includes("merchandise-packages") &&
        !params["*"].includes("report") &&
        !params["*"].includes("order"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },

    {
      name: "Businesses",
      href: "/admin/business",
      icon: BUSINESS,
      current:
        params["*"].includes("business") && !params["*"].includes("report"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Users",
      href: "/admin/users/all/1",
      icon: USERS,
      current: params["*"].includes("user") && !params["*"].includes("report"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Employees",
      href: `/admin/${outlet_name}/employees`,
      icon: EMPLOYEES,
      current: params["*"].includes("employee"),
      display: team !== "administrator " && userRole?.includes("business"),
    },
    {
      name: "Features",
      href: `/admin/${outlet_name}/features`,
      icon: FEATURES,
      current:
        params["*"].includes("feature") && !params["*"].includes("report"),
      display: team !== "administrator " && userRole?.includes("business"),
    },
    {
      name: "Coupon",
      href: "/admin/coupons",
      icon: COUPON,
      current: params["*"].includes("coupon"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Promo Code",
      href: "/admin/promo",
      icon: COUPON,
      current: params["*"].includes("promo"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Cart",
      href: `/admin/carts/1`,
      icon: CART,
      current: params["*"].includes("cart"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Feature",
      href: "/admin/feature",
      icon: FEATURES,
      current: params["*"].includes("feature"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Shippers",
      href: "/admin/shippers",
      icon: SHIPPERS,
      current: params["*"].includes("shippers"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Catalogues",
      href: "/admin/catalogue",
      icon: CATALOGUE,
      current: params["*"].includes("catalogues"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Payments",
      href: "/admin/payments",
      icon: PAYMENT,
      current: params["*"].includes("payments"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Notify",
      href: "/admin/settings/broadcast-notification/email/1",
      icon: NOTIFICATION,
      current: params["*"].includes("broadcast"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "KYC",
      href: "/admin/settings/kyc-verification/all/1",
      icon: KYCICON,
      current: params["*"].includes("kyc"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Feedback",
      href: "/admin/settings/feedback/1",
      icon: FEEDBACK,
      current: params["*"].includes("feedback"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
    {
      name: "Rewards",
      href: "/admin/rewards",
      icon: FEEDBACK,
      current: params["*"].includes("reward"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor",
    },
  ];

  const orders = [
    {
      name: "Restaurant Orders",
      href: "/admin/restaurant-orders",
      icon: ORDERS,
      current:
        params["*"].includes("restaurant-orders") &&
        !params["*"].includes("report"),
      display: team === "administrator" || team.includes("management"),
    },
    {
      name: "Store Orders",
      href: "/admin/store-orders",
      icon: ORDERS,
      current:
        params["*"].includes("store-orders") && !params["*"].includes("report"),
      display: team === "administrator" || team.includes("management"),
    },
    {
      name: "Food Packages Orders",
      href: "/admin/orders/package-food",
      icon: ORDERS,
      current:
        params["*"].includes("orders/package") &&
        !params["*"].includes("report") &&
        !params["*"].includes("merchandise"),

      display: team === "administrator" || team.includes("management"),
    },
    {
      name: "Merchandise Packages Orders",
      href: "/admin/orders/package-merchandise",
      icon: ORDERS,
      current:
        params["*"].includes("orders/package") &&
        !params["*"].includes("report") &&
        !params["*"].includes("food"),
      display: team === "administrator" || team.includes("management"),
    },
  ];

  const reports = [
    {
      name: "Orders Report",
      href: "/admin/orders-report",
      icon: ORDERS,
      current:
        params["*"].includes("report") &&
        params["*"].includes("order") &&
        !params["*"].includes("user"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant",
    },
    {
      name: "Users Report",
      href: "/admin/users-report",
      icon: USERS,
      current: params["*"].includes("users-report"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant",
    },
    {
      name: "Feature Report",
      href: "/admin/feature-report",
      icon: FEATURES,
      current:
        params["*"].includes("feature-report"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant",
    },
    {
      name: "Business Report",
      href: "/admin/business-report",
      icon: BUSINESS,
      current: params["*"].includes("business-report"),
      display:
        team === "administrator" ||
        team === "management" ||
        team === "management_accountant",
    },

    {
      name: "Reports",
      href: `/admin/${outlet_name}/report`,
      icon: ORDERS,
      current: params["*"].includes("report"),
      display: team !== "administrator" && !team.includes("management"),
    },
  ];

  return (
    <>
      {/* Static sidebar for mobile */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white dark:bg-[#121212] pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      // className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      className="-mx-2 rounded-md p-2 bg-[#FAE9E5] inline-flex items-center justify-center focus:outline-none"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <img
                        src={CLOSE}
                        className="block "
                        alt="ICON"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img className="h-8 w-auto" src={Logo} alt="Ogwugo" />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <MobileRoutes
                    navigation={navigation}
                    orders={orders}
                    operations={operations}
                    reports={reports}
                    params={params}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 dark:border-none dark:bg-[#121212] pt-5">
          <div className="flex flex-shrink-0 items-center px-4">
            <img className="h-8 w-auto" src={Logo} alt="Ogwugo" />
          </div>
          <div className="mt-5 flex flex-grow flex-col">
            <div>
              <nav className="flex-1 space-y-1 px-2">
                {navigation.map((item) => (
                  <a
                    key={item.href}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 dark:bg-transparent text-primary"
                        : "text-gray-600 dark:text-[#BCBCBC] hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-transparent",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                      `${item.display ? "block" : "hidden"}`
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-7 w-7"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>

              {(team === "administrator" || team.includes("management")) && (
                <h3 className="capitalize px-4 mt-4 mb-3 font-bold">ORDERS</h3>
              )}
              <nav className="flex-1 space-y-1 px-2">
                {orders.map((item) => (
                  <a
                    key={item.href}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 dark:bg-transparent text-primary"
                        : "text-gray-600 dark:text-[#BCBCBC] hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-transparent",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                      `${item.display ? "block" : "hidden"}`
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-7 w-7"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>

              {(team === "administrator" ||
                team === "management" ||
                team === "management_accountant" ||
                team === "management_supervisor") && (
                <h3 className="capitalize px-4 mt-4 mb-3 font-bold">
                  OPERATIONS
                </h3>
              )}
              <nav className="flex-1 space-y-1 px-2">
                {operations.map((item) => (
                  <a
                    key={item.href}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 dark:bg-transparent text-primary"
                        : "text-gray-600 dark:text-[#BCBCBC] hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-transparent",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                      `${item.display ? "block" : "hidden"}`
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-7 w-7"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>

              {(team === "administrator" ||
                team === "management" ||
                team === "management_accountant") && (
                <h3 className="capitalize px-4 mt-4 mb-3 font-bold">REPORTS</h3>
              )}
              <nav className="flex-1 space-y-1 px-2 pb-4">
                {reports.map((item) => (
                  <a
                    key={item.href}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 dark:bg-transparent text-primary"
                        : "text-gray-600 dark:text-[#BCBCBC] hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-transparent",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                      `${item.display ? "block" : "hidden"}`
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-7 w-7"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
