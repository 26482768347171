import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import CLOSE from "../../../assets/images/icons/close.svg";
import Location from "../../../components/includes/location";
import { filterPublicServiceAreas } from "../../../redux/publicServiceAreas/publicServiceAreasActions";

export default function CreateRecipientModal({
  data,
  handleChange,
  setData,
  recipient_phone,
  setRecipientPhone,
}) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [payLoad] = useState({ end_date: new Date(), pagination: 0 });
  const { filter, filtering } = useSelector((state) => state.publicServiceArea);

  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const {
    recipient_name,
    recipient_address,
    recipient_city,
    recipient_state,
    recipient_country,
    recipient_service_area_id,
  } = data;
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  useEffect(() => {
    dispatch(filterPublicServiceAreas(payLoad));
    // eslint-disable-next-line
  }, []);

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const handleSave = () => {
    setCompleted(true);
    if (
      recipient_name &&
      recipient_address &&
      recipient_city &&
      recipient_state &&
      recipient_country &&
      recipient_service_area_id
    ) {
      setData((data) => ({
        ...data,
        recipient_name,
        recipient_address,
        recipient_city,
        recipient_state,
        recipient_country,
        recipient_service_area_id,
      }));
      setRecipientPhone(recipient_phone);
      closeModal();
    }
  };
  return (
    <div className="Coupon" data-test="couponModal">
      <button className="text-primary font-meduim " onClick={openModal}>
        New Recipient +
      </button>
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[380px] text-left sm:w-[630px] p-4 sm:p-6">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-sm p-1 sm:p-3 "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    <div className="pb-5 text-left sm:mt-2 sm:text-left border-b border-[#E4E4F3]">
                      <Dialog.Title
                        as="p"
                        className=" font-meduim text-gray-500"
                      >
                        Create New Recipient
                      </Dialog.Title>
                    </div>
                  </div>
                  <form className="py-5 mt-4 px-5">
                    <div>
                      <Input.Label
                        title={"Recipient name*"}
                        htmlFor={"recipient_name"}
                        name="recipient_name"
                        autoComplete="off"
                        placeholder="Enter recipient name"
                        onChange={handleChange}
                      />
                      {completed && !recipient_name && (
                        <div className="text-xs text-red-500">
                          Recipient name is required
                        </div>
                      )}
                    </div>

                    <div className="grid sm:grid-cols-2 gap-4">
                      <div>
                        <Input.Phone
                          defaultCountry="NG"
                          title={"Recipient Phone Number*"}
                          htmlFor={"recipient_phone"}
                          name="reciepient_phone"
                          onChange={setRecipientPhone}
                        />
                        {completed && !recipient_phone && (
                          <div className="text-xs text-red-500">
                            Recipient phone is required
                          </div>
                        )}
                      </div>

                      <Input.Label
                        title={"Recipient Email*"}
                        type="email"
                        htmlFor={"recipient_email"}
                        name="recipient_email"
                        placeholder="Enter recipient email address"
                        autoComplete="off"
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <Input.Label
                        title={"Recipient Address*"}
                        type="text"
                        htmlFor={"recipient_address"}
                        name="recipient_address"
                        placeholder="What address should we deliver the item to?"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {completed && !recipient_address && (
                        <div className="text-xs text-red-500">
                          Recipient address is required
                        </div>
                      )}
                    </div>

                    {/* Here, we utilize the location component to get the location data */}
                    <Location
                      country_id={locationId.country_id}
                      state_id={locationId.state_id}
                      city_id={locationId.city_id}
                      loadingLocation={(value) => {
                        setLocationLoader(value);
                      }}
                      countryList={(value) => {
                        setLocationList({
                          ...locationList,
                          countryList: value,
                        });
                      }}
                      stateList={(value) => {
                        setLocationList({
                          ...locationList,
                          stateList: value,
                        });
                      }}
                      cityList={(value) => {
                        setLocationList({ ...locationList, cityList: value });
                      }}
                    />
                    <div className="grid sm:grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="recipient_country"
                          className="block text-sm font-medium text-[#151515]"
                        >
                          Country*
                        </label>
                        <select
                          name="recipient_country"
                          className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFF] focus:outline-none focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                          onChange={(e) => {
                            setLocationId({
                              ...locationId,
                              country_id: fromJson(e.target.value).id,
                            });
                            handleRegion(e);
                          }}
                        >
                          <option>Select Country</option>
                          {locationLoader ? (
                            <option>Loading...</option>
                          ) : (
                            locationList.countryList &&
                            locationList.countryList.length > 0 &&
                            locationList.countryList.map((item, key) => {
                              return (
                                <option
                                  key={key}
                                  value={toJson({
                                    name: item.name,
                                    id: item.id,
                                  })}
                                >
                                  {item.name}
                                </option>
                              );
                            })
                          )}
                        </select>

                        {completed && !recipient_country && (
                          <div className="text-xs text-red-500">
                            Recipient country is required
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          completed && recipient_state ? " border-red-500" : ""
                        }
                      >
                        <label
                          htmlFor="recipient_state"
                          className="block text-sm font-medium text-[#151515]"
                        >
                          State*{" "}
                        </label>
                        <select
                          name="recipient_state"
                          className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFF]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                          onChange={(e) => {
                            setLocationId({
                              ...locationId,
                              state_id: fromJson(e.target.value).id,
                            });
                            handleRegion(e);
                          }}
                        >
                          <option>Select state</option>

                          {locationList.stateList &&
                            locationList.stateList.length > 0 &&
                            locationList.stateList.map((item, key) => {
                              return (
                                <option
                                  key={key}
                                  value={toJson({
                                    name: item.name,
                                    id: item.id,
                                  })}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                        {completed && !recipient_state && (
                          <div className="text-xs text-red-500">
                            Recipient state is required.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="grid sm:grid-cols-2 gap-4">
                      <div
                        className={
                          completed && recipient_city ? " border-red-500" : ""
                        }
                      >
                        <label
                          htmlFor="recipient_city"
                          className="block text-sm font-medium text-[#151515]"
                        >
                          Recipient City*{" "}
                        </label>
                        <select
                          name="recipient_city"
                          className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFF] focus:outline-none focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                          onChange={(e) => {
                            setLocationId({
                              ...locationId,
                              city_id: fromJson(e.target.value).id,
                            });
                            handleRegion(e);
                          }}
                        >
                          <option>Select city</option>
                          {locationList.cityList &&
                            locationList.cityList.length > 0 &&
                            locationList.cityList.map((item, key) => {
                              return (
                                <option
                                  key={key}
                                  value={toJson({
                                    name: item.name,
                                    id: item.id,
                                  })}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                        {completed && !recipient_city && (
                          <div className="text-xs text-red-500">
                            Recipient city is required
                          </div>
                        )}
                      </div>
                      <div>
                        <Input.Select
                          title={"Service Area*"}
                          name="recipient_service_area_id"
                          id="recipient_service_area_id"
                          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                          htmlFor={"recipient_service_area_id"}
                          onChange={handleChange}
                        >
                          <option>Select service area</option>
                          {filtering
                            ? "Loading..."
                            : filter?.length > 0 &&
                              filter?.map((item, i) => {
                                return (
                                  <option key={i} value={item?.id}>
                                    {item.area}
                                  </option>
                                );
                              })}
                        </Input.Select>
                        {completed && !recipient_service_area_id && (
                          <div className="text-xs text-red-500">
                            Recipient service area is required
                          </div>
                        )}
                      </div>
                    </div>
                  </form>

                  <div className="py-8 max-w-xs flex justify-center mx-auto text-center">
                    {/* <Button.Secondary
                      title={"Back"}
                      className="px-4"
                      onClick={closeModal}
                    /> */}
                    <Button.Primary
                      onClick={handleSave}
                      title={"Continue"}
                      className="px-4"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
