import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../../assets/images/icons/close.svg";
import Input from "../../../inputs/inputs";
import Button from "../../../buttons/buttons";
import AddPackageSkeleton from "../../../skeleton/addPackage";
import SearchableDropdown from "../../../../helpers/searchableDropDown";
import ErrorImg from "../../../../assets/images/emptyState/restaurant-list.jpg";
import { searchAllMerchandises } from "./../../../../redux/merchandise/merchandiseActions";

export function AttachMerch(props) {
  const {
    selectedMerch,
    setSelectedMerch,
    selectedMerchArr,
    setSelectedMerchArr,
    showMerch,
    setShowMerch,
  } = props;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [show_search, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [outlet, setOutlet] = useState("");
  const { allStores } = useSelector((state) => state.store);
  const { searchMerch, searching } = useSelector((state) => state.merchandise);
  const storeData = allStores?.data;

  function closeModal() {
    setIsOpen(false);
  }
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    e.preventDefault();
    if (value.length > 2) {
      setShowSearch(true);
      dispatch(
        searchAllMerchandises({
          search: value,
          store_id: outlet,
        })
      );
    }
  };

  function handleSelected(item) {
    setShowSearch(false);
    setSelectedMerch([...selectedMerch, item]);
    setSelectedMerchArr([...selectedMerchArr, item.id]);
  }
  const removeItem = (i) => {
    const filtered = selectedMerch.filter((item, index) => index !== i);
    setSelectedMerch(filtered);
  };
  function handleContinue() {
    setShowMerch([...showMerch, selectedMerch]);
    closeModal();
  }

  const onImageError = (e) => {
    e.target.src = ErrorImg;
  };

  const selectStoreOption = (option) => {
    const value = option?.id;
    setOutlet(value);
  };

  return (
    <>
      {showMerch?.length === 0 && (
        <button
          type="button"
          className="border border-dashed border-[#C2C9D1] text-center p-3 w-full"
          onClick={() => setIsOpen(true)}
        >
          --Attach--
        </button>
      )}
      {showMerch?.length > 0 && (
        <p
          className="text-sm text-primary mb-2 cursor-pointer "
          onClick={() => setIsOpen(true)}
        >
          Add more+
        </p>
      )}
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full min-h-[502px] sm:w-[650px] sm:max-w-2xl bg-white dark:bg-[#121212] px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-8">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-sm p-1 sm:p-3  "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="pb-3 text-center sm:text-center border-b border-[#E4E4F3]">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold text-black dark:text-white"
                      >
                        Attach Store
                      </Dialog.Title>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="mt-4">
                      <label
                        htmlFor="store_id"
                        className="text-sm font-medium text-[#151515] dark:text-white"
                      >
                        Store
                      </label>
                      <SearchableDropdown
                        options={storeData}
                        label="store_name"
                        name={"store_id"}
                        placeholder={"Select a store"}
                        selectOption={selectStoreOption}
                      />
                    </div>

                    <div className="relative">
                      <Input.Label
                        title={"Merchandise"}
                        type="search"
                        id="search"
                        value={searchValue}
                        className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="Search merchandises."
                        onChange={handleSearch}
                      />
                      {searching ? (
                        <div className="absolute w-full bg-white dark:bg-[#121212] shadow top-[68px]">
                          <div className="p-3">
                            <AddPackageSkeleton />
                          </div>
                        </div>
                      ) : (
                        show_search &&
                        searchMerch?.length > 0 && (
                          <div className="absolute w-full bg-white dark:bg-[#121212] shadow top-[68px]">
                            <div className="p-3">
                              {searchMerch?.length > 0 &&
                                searchMerch?.map((item, i) => (
                                  <div
                                    key={i}
                                    className="flex justify-between cursor-pointer mb-2 hover:border-b"
                                    onClick={() => handleSelected(item)}
                                  >
                                    <div>
                                      {" "}
                                      <p className="text-sm">
                                        {item?.merchandise_name}
                                      </p>{" "}
                                    </div>
                                    <p className="text-primary text-sm">
                                      + Add
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    {selectedMerch?.length > 0 && (
                      <div>
                        {selectedMerch?.map((merchandise, i) => (
                          <div
                            key={i}
                            className="flex items-center gap-6  mb-4"
                          >
                            <div>
                              <img
                                src={
                                  merchandise?.merchandise_pictures ?? ErrorImg
                                }
                                className="h-[52px] w-[130px] object-cover rounded-md"
                                alt="food"
                                onError={onImageError}
                              />
                            </div>
                            <div className="w-full">
                              <h4 className="text-md mb-1">
                                {merchandise?.merchandise_name}
                              </h4>
                              <p className="text-xs">{merchandise?.id}</p>
                            </div>
                            <div className="w-full text-right">
                              <button
                                className="text-primary text-md"
                                onClick={() => removeItem(i)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div>
                      <Button.Primary
                        className="rounded-sm flex justify-center mx-auto mt-5"
                        title={"Continue"}
                        onClick={handleContinue}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
