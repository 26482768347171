import axios from "../plugins/axios.plugin";

class RewardService {
  baseServicePath = "/api/reward";

  getAllRewards() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAllRewardsNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getAReward(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  getMyReward() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }

  storeAReward(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  updateAReward(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  claimAReward(payload) {
    return axios.post(`${this.baseServicePath}/claim`, payload);
  }

  deleteAFood(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }

  approveFoodByMgt(payload) {
    return axios.post(`${this.baseServicePath}/approve/management`, payload);
  }

  unapproveFoodByMgt(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payload);
  }

  approveFoodBySelf(payload) {
    return axios.post(`${this.baseServicePath}/approve/business`, payload);
  }

  unapproveFoodBySelf(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/business`, payload);
  }

  searchRewards(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  filterRewards(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}&properties=1`,
      payload
    );
  }
}

export const rewardService = new RewardService();
