import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { addressService } from "../../services/address.service";
import authType from "./addressTypes";

/**
 * Get all my addresses
 **/

export const getMyAddressesAction = () => async (dispatch) => {
  try {
    const response = await addressService.getMyAddresses();
    dispatch({ type: authType["LOADING_ADDRESS"], payLoad: false });
    dispatch({ type: authType["GET_MY_ADDRESSES"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_ADDRESS"], payLoad: false });
  }
};

/**
 * Set new address
 **/

export const setNewAddressAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["CREATING"], payLoad: true });
    const response = await addressService.setNewAddress(payLoad);
    dispatch({ type: authType["CREATING"], payLoad: false });
    dispatch({ type: authType["ADD_ADDRESS"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: authType["CREATING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Delete new address
 **/

export const deleteAnAddressAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["DELETING"], payLoad: false });
    const response = await addressService.deleteAnAddress(payLoad);
    dispatch({ type: authType["DELETING"], payLoad: false });
    dispatch({
      type: authType["DELETE_ADDRESS"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: authType["DELETING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Update an address
 **/

export const updateAnAddressAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["UPDATING"], payLoad: true });
    const response = await addressService.updateAnAddress(payLoad);
    dispatch({ type: authType["UPDATING"], payLoad: false });
    dispatch({
      type: authType["UPDATE_ADDRESS"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: authType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Set default address
 **/

export const setDefaultAddress = (payLoad) => async (dispatch) => {
  dispatch({ type: authType["DEFAULT_ADDRESS"], payLoad: payLoad });
};

/**
 * Get an addresses
 **/

export const filterAddressesAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_ADDRESS"], payLoad: true });
    const response = await addressService.filterAddresses(payLoad);
    dispatch({ type: authType["LOADING_ADDRESS"], payLoad: false });
    dispatch({ type: authType["FILTER_ADDRESSES"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: authType["LOADING_ADDRESS"], payLoad: false });
  }
};
