import axios from "../plugins/axios.plugin";

class PublicMerchandiseService {
  baseServicePath = "/api/public/merchandise";

  getAllPublicMerchandises() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }
  searchPublicMerchandise(payLoad) {
    return axios.post(`${this.baseServicePath}/search/index`, payLoad);
  }
  getPublicMerchandise(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }
  filterPublicMerchandise() {
    return axios.post(`${this.baseServicePath}/filter/index`);
  }
}

export const publicMerchandiseService = new PublicMerchandiseService();
