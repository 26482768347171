import React, { useState, useEffect, useRef } from "react";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//import { history } from "../../../../helpers/history";
import { Beat } from "../../../../plugins/spinners.plugin";
import ADVERTIMG from "../../../../assets/images/pictures/advertImage.jpg";
import EditStoreAttribute from "../../../../components/modals/admin/editStoreAttribute";
import RemoveMerchAttributeModal from "../../../../components/modals/admin/attributes/removeMerchAttributeModal";
import AddMerchVariationModal from "../../../../components/modals/admin/addMerchVariationModal";
import { groupBy } from "../../../../helpers/groupBy";
import { cleanInput } from "../../../../helpers/cleanInput";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import {
  getOutletFromCookies,
  getTeamFromCookies,
} from "../../../../utils/Auth";
import {
  getSingleMerchandise,
  editmerchandise,
} from "../../../../redux/merchandise/merchandiseActions";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";
import { filterAttributeAction } from "../../../../redux/attribute/attributeActions";
import { filterProductTag } from "../../../../redux/productTags/productTagActions";

export default function EditMerchandise() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const team = getTeamFromCookies();
  const outlet = getOutletFromCookies();
  const hiddenFileInput = useRef(null);
  const merchandise = location.state.merchandise;

  const { singleMerchandise, updating } = useSelector(
    (state) => state.merchandise
  );
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);

  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [displayArr, setDisplayArr] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [allAttributeArr, setAllAttributeArr] = useState([]);
  const [images, setImages] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const [data, setData] = useState({
    id: singleMerchandise?.id,
    merchandise_name: merchandise?.merchandise_name,
    merchandise_description: merchandise?.merchandise_description,
    merchandise_categories: merchandise?.merchandise_categories,
    merchandise_tags: merchandise?.merchandise_tags,
    merchandise_amount: merchandise?.merchandise_amount,
    merchandise_discount: merchandise?.merchandise_discount,
    stock: merchandise?.stock,
    url_photos: [],
    format: merchandise?.format,
  });
  const { merchandise_categories, merchandise_tags, format } = data;

  useEffect(() => {
    if (singleMerchandise) {
      setData({
        id: singleMerchandise?.id,
        merchandise_name: singleMerchandise?.merchandise_name,
        merchandise_description: singleMerchandise?.merchandise_description,
        merchandise_categories: singleMerchandise?.merchandise_categories,
        merchandise_tags: singleMerchandise?.merchandise_tags,
        merchandise_amount: singleMerchandise?.merchandise_amount,
        merchandise_discount: singleMerchandise?.merchandise_discount,
        stock: singleMerchandise?.stock,
        format: singleMerchandise?.format,
      });
    }
    // eslint-disable-next-line
  }, [singleMerchandise]);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const addImageFallback = (event) => {
    event.currentTarget.src = ADVERTIMG;
  };

  useEffect(() => {
    if (merchandise?.id) {
      dispatch(getSingleMerchandise(merchandise?.id));
    }
    // eslint-disable-next-line
  }, [merchandise]);

  useEffect(() => {
    dispatch(filterAttributeAction({ business_id: team }));
    dispatch(filterProductCategory({ type: "store" }, 1));
    dispatch(filterProductTag({ type: "store" }, 1));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allCategories) {
      let items = allCategories?.data?.filter((item) => item.type === "store");
      setCategoryList(items);
    }
  }, [allCategories]);

  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "store");
    setTagList(items);
  }, [allTags]);

  useEffect(() => {
    setAllAttributeArr(singleMerchandise?.attributes);
    singleMerchandise?.attributes.forEach((element) => {
      if (!selectedArr.includes(element.id)) {
        setSelectedArr([...selectedArr, element.id]);
      }
      const in_selected = selected.some((obj) => obj.id === element.id);
      if (!in_selected) {
        setSelected([...selected, element]);
      }
    });
    // eslint-disable-next-line
  }, [singleMerchandise]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  const handleToggleTags = (value) => {
    let former_tags = [];
    if (data?.merchandise_tags) {
      former_tags = [...data?.merchandise_tags];
      if (former_tags.includes(value)) {
        let arr = [...former_tags];
        const filtered = arr.filter((item) => item !== value);
        setData({ ...data, merchandise_tags: filtered });
      } else {
        let new_tags = [...former_tags, value.name];
        setData({ ...data, merchandise_tags: new_tags });
      }
    } else {
      let new_tags = [...former_tags, value.name];
      setData({ ...data, merchandise_tags: new_tags });
    }
  };

  const handleToggleCategories = (value) => {
    let former_categories = [];
    if (data?.merchandise_categories) {
      former_categories = [...data?.merchandise_categories];
      if (former_categories.includes(value)) {
        let arr = [...former_categories];
        const filtered = arr.filter((item) => item !== value);
        setData({ ...data, merchandise_categories: filtered });
      } else {
        let new_categories = [...former_categories, value.name];
        setData({ ...data, merchandise_categories: new_categories });
      }
    } else {
      let new_categories = [...former_categories, value.name];
      setData({ ...data, merchandise_categories: new_categories });
    }
  };

  function getMerchWithCategories() {
    let titleArr = [];
    let newArray = [];
    for (let index = 0; index < selected?.length; index++) {
      if (!titleArr.includes(selected[index].title)) {
        titleArr.push(selected[index].title);
      }
    }
    for (let index = 0; index < titleArr?.length; index++) {
      let temp = {
        title: titleArr[index],
        attributes: selected.filter(
          (merchandise) => titleArr[index] === merchandise.title
        ),
      };
      newArray.push(temp);
    }
    return newArray;
  }

  useEffect(() => {
    const merchandiseAttr = getMerchWithCategories();
    setAllAttributeArr([...allAttributeArr, ...merchandiseAttr]);
    let group = groupBy(merchandiseAttr, "title");
    setDisplayArr({ ...group });
    // eslint-disable-next-line
  }, [selected]);

  //handling removing and adding images to existing images
  useEffect(() => {
    if (singleMerchandise?.merchandise_pictures?.length > 0) {
      setData({ ...data, url_photos: singleMerchandise?.merchandise_pictures });
    }
    // eslint-disable-next-line
  }, [singleMerchandise?.merchandise_pictures]);

  function onImageChange(e) {
    setImages([...e.target.files]);
  }
  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  const removeUrlPhoto = (index) => {
    let newArr = [...data?.url_photos];
    newArr.splice(index, 1);
    setData({ ...data, url_photos: newArr });
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const handleSubmit = async () => {
    setSubmitted(true);
    let newInput = {
      ...data,
      base64_photos: image64 ? image64 : [],
      merchandise_attributes: selectedArr?.length > 0 ? selectedArr : [null],
    };
    cleanInput(newInput);
    try {
      const response = await dispatch(
        editmerchandise({ ...newInput, id: singleMerchandise.id })
      );
      if (response.status === "success") {
        navigate(
          `/admin/${outlet?.store_name}/merchandise/${merchandise?.id}`,
          {
            state: { merchandise: merchandise },
          }
        );
      }
    } catch (error) {}
  };
  return (
    <>
      <form>
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Merchandise Details</h3>
          </div>
          <div className="mt-6">
            <div>
              <Input.Label
                title={"Merchandise Name*"}
                htmlFor={"merchandise_name"}
                defaultValue={merchandise?.merchandise_name}
                name="merchandise_name"
                onChange={handleChange}
                placeholder="Enter merchandise name"
              />
            </div>
            <div>
              <Input.Label
                title={
                  "Short Description (Hint: Input product Highlights/Features in bullets. Not more than 200 Characters)"
                }
                htmlFor={"merchandise_description"}
                name="merchandise_description"
                defaultValue={merchandise?.merchandise_description}
                onChange={handleChange}
                placeholder="Entera short merchandise description"
              />
            </div>

            <div className="grid grid-cols-1">
              <Input.Label
                title={"Stock"}
                htmlFor={"stock"}
                name="stock"
                defaultValue={merchandise?.stock}
                placeholder="Enter Quantity"
                onChange={handleChange}
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div>
                <Input.Number
                  title={"Price"}
                  htmlFor={"merchandise_amount"}
                  name="merchandise_amount"
                  placeholder="Enter merchandise price"
                  defaultValue={merchandise?.merchandise_amount}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Input.Number
                  title={"Discounted Price"}
                  name="merchandise_discount"
                  htmlFor={"merchandise_discount"}
                  onChange={handleChange}
                  defaultValue={merchandise?.merchandise_discount}
                  placeholder="0"
                />
              </div>
            </div>

            <Input.Select
              title={"Product Type"}
              htmlFor={"format"}
              name={"format"}
              value={format}
              onChange={handleChange}
            >
              <option>Select Product Type</option>
              <option value={"physical"}>Physical Product</option>
              <option value={"digital"}>Digital Product</option>
            </Input.Select>
          </div>
        </div>
      </form>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mt-8">
          <div className="mt-6">
            <label
              htmlFor="merchandise_categories"
              className="text-sm font-medium text-[#151515] mb-2 block"
            >
              Merchandise Categories
            </label>
            <SelectableInput
              options={categoryList}
              label="merchandise_categories"
              name={"+ Add Category"}
              type="category"
              previousSelected={merchandise_categories}
              selectOption={handleToggleCategories}
            />
            {submitted && merchandise_categories?.length < 1 && (
              <div className="text-xs text-red-500">
                Food category is required
              </div>
            )}
          </div>
        </div>
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="mt-6">
            <label
              htmlFor="merchandise_tags"
              className="text-sm font-medium text-[#151515] mb-2 block"
            >
              Merchandise Tags
            </label>
            <SelectableInput
              options={tagList}
              label="merchandise_tags"
              name={"+ Add Tag"}
              type="tag"
              previousSelected={merchandise_tags}
              selectOption={handleToggleTags}
            />
            {submitted && merchandise_tags?.length < 1 && (
              <div className="text-xs text-red-500">Food tags is required</div>
            )}
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium">
              Merchandise Images (200px by 200px)
            </h3>
            <p className="text-sm">
              Accepts .jpg, .png and .jpeg image formats
            </p>
          </div>
          <div>
            <button className="text-primary" onClick={handleFileClick}>
              + Upload Image
            </button>
            <input
              type="file"
              className="hidden "
              accept="image/*"
              onChange={onImageChange}
              ref={hiddenFileInput}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
          {data?.url_photos?.length > 0 &&
            data?.url_photos?.map((image, index) => (
              <figure className="w-full h-[160px] relative" key={index}>
                <img
                  src={image}
                  className="object-cover w-full h-full"
                  alt="food"
                />
                <button
                  className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                  onClick={() => removeUrlPhoto(index)}
                >
                  <XIcon className="h-5 w-5 text-primary mx-auto" />
                </button>
              </figure>
            ))}

          {imageUrls?.length > 0 &&
            imageUrls.map((image, index) => (
              <figure className="w-full h-[160px] relative" key={index}>
                <img
                  src={image}
                  onError={addImageFallback}
                  className="object-cover w-full h-full"
                  alt="merchandise"
                />

                <button
                  className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                  onClick={() => removeFile(index)}
                >
                  <XIcon
                    className="h-5 w-5 text-primary mx-auto"
                    // onClick={handleDelete}
                  />
                </button>
              </figure>
            ))}
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8 mb-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Merchandise Attributes</h3>
          <button className="text-primary" onClick={openModal}>
            + Add Attributes
          </button>
        </div>
        <AddMerchVariationModal
          show={isOpen}
          onClose={closeModal}
          selected={selected}
          setSelected={setSelected}
          selectedArr={selectedArr}
          setSelectedArr={setSelectedArr}
          merch_business_id={singleMerchandise?.business_id}
        />
        {selected?.length > 0 ? (
          Object.keys(displayArr).map((single, i) => (
            <div key={i} className="border-b border-[#E4E4F3] mt-8">
              <p className="text-md my-6">
                Title : <span className="font-medium">{single}</span>
              </p>
              <div className="grid grid-cols-3 gap-24 px-4">
                <p className="py-2 text-md">Name</p>
                <p className="py-2 text-md">Value</p>
                <p className="py-2 text-md">Action</p>
              </div>
              {displayArr[single].map((attribute, i) => (
                <div key={i}>
                  {attribute?.attributes?.map((attribute, i) => (
                    <div key={i} className="grid grid-cols-3 gap-24 px-4">
                      <p className="py-2 font-medium">{attribute.name}</p>
                      <p className="py-2 font-medium">NGN {attribute.amount}</p>
                      <div>
                        <div className="flex justify-between max-w-[26px] py-2">
                          <EditStoreAttribute myAttributes={attribute} />
                          <p className="mx-4">|</p>
                          <RemoveMerchAttributeModal
                            id={attribute?.id}
                            myAttributes={attribute}
                            selected={selected}
                            setSelected={setSelected}
                            selectedArr={selectedArr}
                            setSelectedArr={setSelectedArr}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="text-center mx-auto max-w-2xl py-8">
            <h4 className="text-2xl font-medium mb-1">
              You have no merchandise Attributes yet
            </h4>
            <p className="text-lg">
              When you create new merchandise Attributes, it will be dispalyed
              here
            </p>
            <p
              className="text-primary text-lg cursor-pointer"
              onClick={openModal}
            >
              + Add Attributes
            </p>
          </div>
        )}
      </div>
      <div className="mx-auto flex justify-center py-5">
        <Button.Primary
          title={updating ? <Beat color={"#ffffff"} /> : "Update Merchandise"}
          form="merch_form"
          onClick={handleSubmit}
          type={"button"}
          disabled={updating}
          className="rounded-sm"
        />
      </div>
    </>
  );
}
