import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
// import Button from "../../../../components/buttons/buttons";
// import { copyTextToClipboard } from "../../../../components/helpers";
// import { ReactComponent as CopyIcon } from "../../../../assets/images/icons/copy-outline.svg";
import { getSingleFeedback } from "../../../../redux/feedback/feedbackActions";

// function numberWithCommas(x) {
//   return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

export default function KYCTierDetails() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const params = useParams();

  const { singleFeedback } = useSelector(
    (state) => state.feedback
  );

  useEffect(() => {
    if (params?.id) {
      dispatch(getSingleFeedback(params?.id));
    }
    // eslint-disable-next-line
  }, [params?.id]);

  return (
    <div data-test="singleOrder-page">
      <div className="border border-[#E4E4F3] bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">User Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 sm:gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Gender</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone number</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Birthday: </label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Date Created</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">KYC Document</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Licence No:</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">BVN No:</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
        </div>

        <div className="mt-6">
          <label className="text-[#9CA3AF] text-sm mb-2">Home Address</label>
          <p className="text-[#151515] text-sm font-bold">
            {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
}
