import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { storeHumanVerificationLevel } from "../../../../redux/humanVerificationLevel/humanVerificationLevelActions";

export default function CreateKYCTier() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

 const { isLoading } = useSelector((state) => state.humanVerificationLevel);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    title: "",
    daily_limit: "",
    monthly_limit: "",
    limit_currency: "NGN",
  });

  const { title, daily_limit, monthly_limit } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (title && daily_limit && monthly_limit) {
      try {
        const response = await dispatch(storeHumanVerificationLevel(data));
        if (response?.status === "success") {
          navigate("/admin/settings/kyc-verification/tiers/1");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <>
      <div className=" ml-52">
        <div className=" mx-auto bg-white dark:bg-[#121212] sm:w-[630px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 pb-8 sm:border-gray-200 dark:border-none">
          <div className="border-b-2 py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 dark:text-white flex justify-center ">
              Create New Tier
            </p>
          </div>
          <form className='pb-8'>
            <div className=" sm:px-10">
              <div>
                <Input.Label
                  title={"Enter Tier Title"}
                  htmlFor={"title"}
                  name={"title"}
                  placeholder={"Enter tier title"}
                  onChange={handleChange}
                />
                {submitted && !title && (
                  <div className="text-primary text-xs ">Title is required</div>
                )}
              </div>

              <div>
                <Input.Label
                  type={"number"}
                  title={"Tier Daily Limit"}
                  htmlFor={"daily_limit"}
                  name={"daily_limit"}
                  placeholder={"Enter daily limit"}
                  onChange={handleChange}
                />
                {submitted && !daily_limit && (
                  <div className="text-primary text-xs ">
                    Daily Limit is required
                  </div>
                )}
              </div>

              <div>
                <Input.Label
                  type={"number"}
                  title={"Tier Monthly Limit"}
                  htmlFor={"monthly_limit"}
                  name={"monthly_limit"}
                  placeholder={"Enter monthly limit"}
                  onChange={handleChange}
                />
                {submitted && !monthly_limit && (
                  <div className="text-primary text-xs ">
                    Monthly Limit is required
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div>
          <Button.Primary
            title={isLoading ? <Beat color={"#ffffff"} /> : "Create Tier"}
            className={"w-[151px] sm:mt-10 sm:ml-72 ml-36 rounded-sm "}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}
