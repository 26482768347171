import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// components
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
// requests
import {
  showANotifier,
  closeANotifier,
} from "../../../../redux/notifier/notifierAction";

export default function SingleBroadcast() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const { singleNotifier, deleting } = useSelector((state) => state.notifier);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(showANotifier(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const handleCloseMessage = async () => {
    const response = await dispatch(closeANotifier({ id: id }));
    if (response?.status === "success") {
      navigate(-1);
    }
  };


  return (
    <div data-test="singleRestaurant-page">
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mb-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">{singleNotifier?.title}</h3>
          <Button.Primary
            type={"button"}
            title={deleting ? <Beat /> : "Close Message"}
            onClick={handleCloseMessage}
          />
        </div>

        <div className="mt-6">
          <p className="text-lg">{singleNotifier?.message}</p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Date:</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {moment(singleNotifier?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Time:</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {moment(singleNotifier?.created_at).format("hh:mm a")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status:</label>
            <p className="text-[#151515] dark:text-white text-sm break-words">
              {singleNotifier?.status}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
