import React, { useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../../components/tables/tableCols2";
import Copy from "../../../../../assets/images/icons/copy-outline.svg";
import TableSkeleton from "../../../../../components/skeleton/table";
import { copyTextFromTable } from "../../../../../components/helpers";
import Orders from "../../../../../assets/images/icons/ordersEmpty.svg";
import {
  filterStoreOrders,
  getAllStoreOrdersNoPage,
} from "../../../../../redux/storeOrders/storeOrderAction";
import moment from "moment";

export default function ShippedStoreOrders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);
  const { allStoreOrders, filtering } = useSelector(
    (state) => state.storeOrder
  );

  const [payLoad] = useState({
    start_date: lastMonth,
    end_date: new Date(),
  });
  // eslint-disable-next-line no-unused-vars
  const [noPageData, setNoPageData] = useState([]);
  const [data, setData] = useState([]);

  let { page } = useParams();

  useEffect(() => {
    dispatch(filterStoreOrders(payLoad, page));
    dispatch(getAllStoreOrdersNoPage(payLoad));
  }, [dispatch, payLoad, page]);
  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 25, separator = "...") => {
    if (string?.length <= lengthToShow) return string;

    const sepLen = separator?.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string?.substr(0, frontChars) +
      separator +
      string?.substr(string.length - backChars)
    );
  };
  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.splice(0, 4).map((order, index) => {
        let single = {
          created: (
            <span>{moment(order?.created_at).format("MMM DD, YYYY")}</span>
          ),
          id: (
            <div className="flex ">
              {truncateText(order.id)}
              <img
                className="ml-2"
                src={Copy}
                alt="copy-icon"
                onClick={(e) => copyTextFromTable(e, order.id)}
              />
            </div>
          ),
          name: order?.recipient_name,
          phone: <div>{order?.recipient_phone}</div>,
          payment:
            order?.order_paid === true ? (
              <p className="font-bold text-sm text-green-500">Paid</p>
            ) : (
              <p className="font-bold text-sm text-primary">Not Paid</p>
            ),
          method: order?.order_payment_method ?? "Not available",
          amount: order?.order_amount,
          order: order,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allStoreOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStoreOrders]);

  const goToSinglePage = (params) => {
    let id = params?.single?.order?.id;
    navigate(`/admin/store-orders/details/${id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order ID",
            accessor: "single.id",
          },
          {
            Header: "Date",
            accessor: "single.created",
          },
          {
            Header: "Amount",
            accessor: "single.amount",
          },
          {
            Header: "Payment Status",
            accessor: "single.payment",
          },
          {
            Header: "Payment Method",
            accessor: "single.method",
          },
          {
            Header: "Customer Name",
            accessor: "single.name",
          },
          {
            Header: "Customer Phone",
            accessor: "single.phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="stores-page">
      <div className="border-b border-[#E4E4F3] dark:border-[#505050] sm:px-4 flex justify-between items-center">
        <h3 className="text-md py-2 font-medium">Recent Orders</h3>
        {data?.length > 0 && (
          <a
            href="/admin/orders/store/shipped/1"
            className="text-primary font-medium"
          >
            See all
          </a>
        )}
      </div>
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 ">
        {filtering ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            <div className="flex justify-center my-12 ">
              <img src={Orders} alt="orders" />
            </div>
            <p className="font-bold text-xl my-3">Orders</p>
            <p className="text-sm text-gray-500">
              You do not have any orders yet
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
