import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../../../components/buttons/buttons";
//tabs
import Restaurant from "./tabs/restaurant";
import Store from "./tabs/store";
import { getABusiness } from "../../../../redux/business/businessActions";
import { getAUserAction } from "../../../../redux/users/userActions";
import { ReactComponent as Chart } from "../../../../assets/images/icons/chart.svg";

const tabs = [
  { name: "Restaurant", href: "restaurant" },
  { name: "Stores", href: "store" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BusinessReportDetail() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const [active_tab, setActiveTab] = useState("restaurant");
  const { business } = useSelector((state) => state.business);
  const { singleUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (id) {
      dispatch(getABusiness(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (business?.user_id) {
      dispatch(getAUserAction(business?.user_id));
    }
    // eslint-disable-next-line
  }, [business?.user_id]);

  const toggle = (tab) => {
    if (active_tab !== tab.href) {
      setActiveTab(tab.href);
    }
  };

  return (
    <div data-test="userProfile-page">
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">Business Details</h3>
          <Button.Primary
            type="button"
            title={
              <span className="flex gap-1 items-center">
                <Chart />
                Check Stats
              </span>
            }
            onClick={() => navigate(`/admin/business-report/${id}/statistics`)}
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
            <p className="text-[#151515] dark:text-white text-sm">{business?.business_name}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm">{business?.business_email}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">{business?.business_phone}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {business?.business_country}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Business Owner Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
            <p className="text-[#151515] dark:text-white text-sm">{singleUser?.name}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm">{singleUser?.email}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">{singleUser?.phone}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.address?.[0]?.city}
            </p>
          </div>
        </div>
      </div>

      <div className="border-b border-gray-200 dark:border-none mt-16">
        <nav
          className="-mb-px flex space-x-8 overflow-x-auto"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => {
                toggle(tab);
              }}
              className={classNames(
                tab.href === active_tab
                  ? "border-primary text-primary font-bold"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={tab.href === active_tab ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="p-3 bg-white dark:bg-[#121212] rounded-md">
        {active_tab === "restaurant" && <Restaurant business_id={id} />}
        {active_tab === "store" && <Store business_id={id} />}
      </div>
    </div>
  );
}
