import axios from "../plugins/axios.plugin";

class PublicFoodService {
  baseServicePath = "/api/public/food";

  getAllPublicFoods() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAPublicFood(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  searchPublicFoods(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
}

export const publicFoodService = new PublicFoodService();
