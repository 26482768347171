// Application types
const REWARDS = {
  LOADING_REWARDS: "LOADING_REWARDS",
  UPDATING: "UPDATING",
  SEARCHING: "SEARCHING",
  APPROVING_REWARD: "APPROVING_REWARD",
  FILTERING_REWARDS: "FILTERING_REWARDS",
  DELETING_FOOD: "DELETING_FOOD",
  DOWNLOADING: "DOWNLOADING",
  STORE_REWARD: "STORE_REWARD",
  ALL_REWARDS: "ALL_REWARDS",
  SINGLE_REWARD: "SINGLE_REWARD",
  GET_MY_REWARD: "GET_MY_REWARD",
  SEARCH_REWARDS: "SEARCH_REWARDS",
  FILTER_REWARDS: "FILTER_REWARDS",
  UPDATE_REWARD: "UPDATE_REWARD",
  CLAIM_REWARD: "CLAIM_REWARD",
  DELETE_FOOD: "DELETE_FOOD",
  APPROVE_REWARD_MANAGEMENT: "APPROVE_REWARD_MANAGEMENT",
  UNAPPROVE_REWARD_MANAGEMENT: "UNAPPROVE_REWARD_MANAGEMENT",
  ALL_REWARDS_NO_PAGINATION: "ALL_REWARDS_NO_PAGINATION",
};

export default REWARDS;
