import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { rewardService } from "../../services/rewards.service";
import rewardType from "./rewardTypes";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

/**
 * Get all rewards
 **/

export const getAllRewardsAction = () => async (dispatch) => {
  try {
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: true });
    const response = await rewardService.getAllRewards();
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: false });
    dispatch({ type: rewardType["ALL_REWARDS"], payLoad: response });
  } catch (error) {
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all rewards
 **/

export const getAllRewardsActionNoPagination =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: rewardType["DOWNLOADING"], payLoad: true });
      const response = await rewardService.getAllRewardsNoPagination(payload);
      dispatch({ type: rewardType["DOWNLOADING"], payLoad: false });
      dispatch({
        type: rewardType["ALL_REWARDS_NO_PAGINATION"],
        payLoad: response,
      });
      return response;
    } catch (error) {
      dispatch({ type: rewardType["DOWNLOADING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Get my reward
 **/

export const getMyRewardsAction = () => async (dispatch) => {
  try {
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: true });
    const response = await rewardService.getMyReward();
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: false });
    dispatch({ type: rewardType["GET_MY_REWARD"], payLoad: response });
  } catch (error) {
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a reward
 **/

export const getARewardAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: true });
    const response = await rewardService.getAReward(id);
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: false });
    dispatch({ type: rewardType["SINGLE_REWARD"], payLoad: response });
  } catch (error) {
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a food
 **/

export const deleteAFoodAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["DELETING_FOOD"], payLoad: true });
    const response = await rewardService.deleteAFood(id);
    dispatch({ type: rewardType["DELETING_FOOD"], payLoad: false });
    dispatch({ type: rewardType["DELETE_FOOD"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: rewardType["DELETING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

export const searchRewardsAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["SEARCHING"], payLoad: true });
    const response = await rewardService.searchRewards(payLoad);
    dispatch({ type: rewardType["SEARCHING"], payLoad: false });
    dispatch({ type: rewardType["SEARCH_REWARDS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: rewardType["SEARCHING"], payLoad: false });
  }
};

export const filterRewardsAction = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["FILTERING_REWARDS"], payLoad: true });
    const response = await rewardService.filterRewards(payLoad, page);
    dispatch({ type: rewardType["FILTERING_REWARDS"], payLoad: false });
    dispatch({ type: rewardType["FILTER_REWARDS"], payLoad: response });
    // successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: rewardType["FILTERING_REWARDS"], payLoad: false });
  }
};

export const storeARewardAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: true });
    const response = await rewardService.storeAReward(payLoad);
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: false });
    dispatch({ type: rewardType["STORE_REWARD"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: rewardType["LOADING_REWARDS"], payLoad: false });
    errorHandler(error, true);
  }
};

export const updateARewardAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["UPDATING"], payLoad: true });
    const response = await rewardService.updateAReward(payLoad);
    dispatch({ type: rewardType["UPDATING"], payLoad: false });
    dispatch({ type: rewardType["UPDATE_REWARD"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: rewardType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const claimARewardAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["UPDATING"], payLoad: true });
    const response = await rewardService.claimAReward(payLoad);
    console.log("response", response);
    dispatch({ type: rewardType["UPDATING"], payLoad: false });
    dispatch({ type: rewardType["CLAIM_REWARD"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: rewardType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve Reward by mgt
 **/

export const approveRewardByMgtAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["APPROVING_REWARD"], payLoad: true });
    const response = await rewardService.approveFoodByMgt(payload);
    dispatch({ type: rewardType["APPROVING_REWARD"], payLoad: false });
    dispatch({ type: rewardType["APPROVE_REWARD_MANAGEMENT"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: rewardType["APPROVING_REWARD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Unapprove Rewards by mgt
 **/

export const unapproveRewardByMgtAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: rewardType["APPROVING_REWARD"], payLoad: true });
    const response = await rewardService.unapproveFoodByMgt(payload);
    dispatch({ type: rewardType["APPROVING_REWARD"], payLoad: false });
    dispatch({
      type: rewardType["UNAPPROVE_REWARD_MANAGEMENT"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: rewardType["APPROVING_REWARD"], payLoad: false });
    errorHandler(error, true);
  }
};
