import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { copyTextToClipboard, concatId } from "../../../../components/helpers";
import { ReactComponent as CopyIcon } from "../../../../assets/images/icons/copy-outline.svg";
import { Assignshipping } from "../../../../components/modals/assignShipper";
import { getAUserAction } from "../../../../redux/users/userActions";
import { CloseFoodPackageOrder } from "../../../../components/modals/admin/orders/closeFoodPackageOrder";
import { getASubscriptionFoodOrder } from "../../../../redux/packageSubscriptionFoodOrder/packageSubscriptionFoodOrderAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SingleFoodPackageOrder() {
  const dispatch = useDispatch();
  const params = useParams();

  const { singlePackageOrder } = useSelector(
    (state) => state.packageSubscriptionFoodOrder
  );

  useEffect(() => {
    dispatch(getASubscriptionFoodOrder(params?.id));
    // eslint-disable-next-line
  }, [params?.id]);

  useEffect(() => {
    if (singlePackageOrder?.created_by) {
      dispatch(getAUserAction(singlePackageOrder?.created_by));
    }

    // eslint-disable-next-line
  }, [singlePackageOrder?.created_by]);

  return (
    <div data-test="singleOrder-page">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 pt-6">
        {/* Grid 1 */}
        <div>
          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Order Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order date
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {moment(singlePackageOrder?.created_at).format(
                    "MMM DD, YYYY"
                  )}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] dark:text-white text-sm font-bold flex">
                  <span className="flex items-center">
                    #{concatId(singlePackageOrder?.id)}
                    <button
                      type="button"
                      onClick={() => {
                        copyTextToClipboard(singlePackageOrder?.id);
                      }}
                    >
                      <CopyIcon className="h-4 w-4 ml-1" />
                    </button>
                  </span>
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singlePackageOrder?.order_sub_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singlePackageOrder?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singlePackageOrder?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Insurance Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN{" "}
                  {numberWithCommas(singlePackageOrder?.order_insurance_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singlePackageOrder?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Total Amount
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  NGN {numberWithCommas(singlePackageOrder?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Order Status
                </label>
                <p className="text-primary text-sm font-bold">
                  {singlePackageOrder?.status === "unshipped" ? (
                    <p className="text-primary text-sm font-bold">Unshipped</p>
                  ) : singlePackageOrder?.status === "shipped" ? (
                    <p className="text-[#046ECF] text-sm font-bold">Shipped</p>
                  ) : singlePackageOrder?.status === "closed" ? (
                    <p className="text-primary text-sm font-bold">Closed</p>
                  ) : singlePackageOrder?.status === "delivered" ? (
                    <p className="text-[#00D220] text-sm font-bold">
                      Delivered
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>

              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Payment Status
                </label>
                {singlePackageOrder?.order_paid ? (
                  <p className="text-[#00D220] text-sm font-bold">Paid</p>
                ) : (
                  <p className="text-primary text-sm font-bold">Unpaid</p>
                )}
              </div>

              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Shipping Date
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {moment(singlePackageOrder?.expected_shipping_date).format(
                    "MMM DD, YYYY"
                  )}
                </p>
              </div>

              {singlePackageOrder?.order_payment_method !== null && (
                <div>
                  <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                    Payment Method
                  </label>
                  <p className="text-[#1634A1] text-sm font-bold">
                    {singlePackageOrder?.order_payment_method ??
                      "Not available"}
                  </p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-8">
              {singlePackageOrder?.status === "unshipped" && (
                <CloseFoodPackageOrder
                  recipient={singlePackageOrder?.recipient_name}
                  id={singlePackageOrder?.id}
                />
              )}
              {singlePackageOrder?.status === "unshipped" && (
                <Assignshipping
                  order_id={params?.id}
                  order_type={"package-food"}
                />
              )}
            </div>
          </div>

          {singlePackageOrder?.order_coupons?.length > 0 && (
            <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Coupon Details</h3>
              </div>

              {singlePackageOrder?.order_coupons?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Title</label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] dark:text-white text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {singlePackageOrder?.order_promotion_codes?.length > 0 && (
            <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Promo Code Details</h3>
              </div>

              {singlePackageOrder?.order_promotion_codes?.map(
                (coupon, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                  >
                    <div>
                      <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                        Slug Name
                      </label>
                      <p className="text-[#151515] dark:text-white text-sm font-bold">
                        {coupon?.slug}
                      </p>
                    </div>
                    <div>
                      <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                        Title
                      </label>
                      <p className="text-[#151515] dark:text-white text-sm font-bold flex">
                        {coupon?.title}
                      </p>
                    </div>
                    <div>
                      <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                        Amount
                      </label>
                      <p className="text-[#151515] dark:text-white text-sm font-bold">
                        NGN {numberWithCommas(coupon?.amount)}
                      </p>
                    </div>
                    <div>
                      <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                        Expires
                      </label>
                      <p className="text-[#151515] dark:text-white text-sm font-bold">
                        {moment(coupon?.expiry).format("MMM DD, YYYY")}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>

        {/* Grid 2 */}
        <div>
          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Store Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Store Name
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singlePackageOrder?.restaurant?.restaurant_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Store Email
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold break-words">
                  {singlePackageOrder?.restaurant?.restaurant_email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Store Phone Number
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singlePackageOrder?.restaurant?.restaurant_phone}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Completed Orders
                </label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">{0}</p>
              </div>
            </div>
          </div>

          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">
                Product Details (Optional)
              </h3>
            </div>

            <div className="flex justify-between items-start mb-5 mt-3">
              <div className="flex">
                <span className="w-6 mr-3 sm:mr-10">1x</span>

                <p className="mb-1">
                  {singlePackageOrder?.package?.package_name}
                </p>
              </div>
              <p>NGN {numberWithCommas(singlePackageOrder?.order_amount)}</p>
            </div>
          </div>

          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Customer Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singlePackageOrder?.recipient_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
                <p className="text-[#151515] dark:text-white text-sm  font-bold">
                  {singlePackageOrder?.recipient_email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Phone Number
                </label>
                <p className="text-[#151515] dark:text-white text-sm  font-bold">
                  {singlePackageOrder?.recipient_phone}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
                <p className="text-[#151515] dark:text-white text-sm font-bold">
                  {singlePackageOrder?.recipient_address}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
