import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../../plugins/spinners.plugin";
import Button from "../../../../../components/buttons/buttons";
import Card from "../../../../../components/cards/admin/cards";
import Table from "../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../components/skeleton/table";
import { Pagination } from "../../../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../../../components/modals/allFiltersModal";
import FilterBusinessDropDown from "../../../../../components/dropDown/filter/filterBusiness";
import {
  filterBusinessAction,
  searchBusinessAction,
  filterBusinessActionNoPage,
} from "../../../../../redux/business/businessActions";
import businessType from "../../../../../redux/business/businessTypes";
import { writeFileWithXLSX } from "../../../../../components/exports/xlsx";

export default function Business() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  let fourMonths = new Date();
  fourMonths.setDate(fourMonths.getDate() - 120);

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    // start_date: fourMonths,
    end_date: new Date(),
  });

  const { allBusinesses, downloading, filtering, searching } = useSelector(
    (state) => state.business
  );
  useEffect(() => {
    dispatch(filterBusinessAction(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((business, index) => {
        let show = {
          id: index + 1,
          name: business?.business_name,
          email: business?.business_email,
          location: business?.business_city,
          status: (
            <span>
              {business?.management_approved ? (
                <span className="text-[#00D220] font-bold">Approved</span>
              ) : (
                <span className="text-primary font-bold">Unapproved</span>
              )}
            </span>
          ),
          phone: business?.business_phone,
          business: business,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((business, index) => {
        let show = {
          id: index + 1,
          name: business?.business_name,
          email: business?.business_email,
          location: business?.business_city,
          status: business?.management_approved ? "Approved" : "Unapproved",
          phone: business?.business_phone,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allBusinesses?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBusinesses]);

  function goToSinglePage(params) {
    let id = params?.show?.business?.id;
    navigate(`/admin/business-report/${id}`, {
      state: { restaurant: params?.show?.business },
    });
  }

  const handleDownload = async () => {
    const response = await dispatch(
      filterBusinessActionNoPage({ ...payLoad, pagination: 0 })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "BusinessReport_Businesses.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allBusinesses?.data);
      setData(dataSet);
      dispatch({
        type: businessType["SEARCH_BUSINESS"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchBusinessAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Business Name",
            accessor: "show.name",
          },
          {
            Header: "Email",
            accessor: "show.email",
          },
          {
            Header: "Location",
            accessor: "show.location",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <Card.TableFilter
        title={"Business"}
        results={allBusinesses?.to}
        totalResults={allBusinesses?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterBusinessDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder="Business name, email, phone number"
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          type="button"
          onClick={handleDownload}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3] dark:border-[#505050]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4">
        {filtering || searching ? (
          <TableSkeleton />
        ) : (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        )}

        <Pagination
          data={allBusinesses}
          route={"admin/business-report/restaurant"}
        />
      </div>
    </div>
  );
}
