import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../inputs/inputs";
import CLOSE from "../../assets/images/icons/close.svg";
import Heart from "../../assets/images/icons/heart-circle.svg";
import {
  FACEBOOK,
  WHATSAPP,
  // INSTAGRAM,
  TELEGRAM,
  TWITTER,
} from "../../assets/images/icons/icon";

export default React.forwardRef(function ShareProductModal(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    getOutletId: (data) => getOutletId(data),
    getOutletType: (data) => getOutletType(data),
    getProductId: (data) => getProductId(data),
    getProductImages: (data) => getProductImages(data),
  }));

  const base_url = window.location.origin;

  let [isOpen, setIsOpen] = React.useState(visibility);
  let [product_images, setProductImages] = React.useState();
  let [product_id, setProductId] = React.useState("");
  let [outlet_id, setOutletId] = React.useState("");
  let [outlet_type, setOutletType] = React.useState("");
  let [caption, setCaption] = React.useState("");
  const [selectedImage, setSelectedImage] = React.useState(product_images?.[0]);

  // const text = `${base_url}/pay-for-me/${pfm}`;
  const store_url = `${base_url}/${outlet_type}/${outlet_id}?item=${product_id}`;
  const defaultMessage = `Hey! checkout my product at ${store_url}`;
  console.log("store_url", store_url);

  useEffect(() => {
    if (store_url && defaultMessage) {
      setCaption(defaultMessage);
    }
    // eslint-disable-next-line
  }, [store_url, defaultMessage]);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function getProductImages(data) {
    setProductImages(data);
  }

  function getProductId(data) {
    setProductId(data);
  }


  function getOutletId(data) {
    setOutletId(data);
  }

  function getOutletType(data) {
    setOutletType(data);
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setCaption(value);
  };

  const text = encodeURIComponent(
    `Buy my product\n\nCheck this image: ${selectedImage}\n\n${caption}`
  );

  function shareOnWhatsApp() {
    const message = encodeURIComponent(
      `Buy my product\n\nCheck this image: ${selectedImage}\n\n${caption}`
    );
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  }

  function shareOnFacebook() {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${text}`;
    window.open(facebookUrl, "_blank");
  }

  // function shareOnInstagram() {
  //   const instagramUrl = `https://www.instagram.com/?url=${store_url}`;
  //   window.open(instagramUrl, "_blank");
  // }

  function shareOnTwitter() {
    const message = encodeURIComponent(
      `Buy my product\n\nCheck this image: ${selectedImage}\n\n${caption}`
    );
    const twitterUrl = `https://twitter.com/intent/tweet?text=${message}&url=${store_url}`;
    window.open(twitterUrl, "_blank");
  }

  function shareOnTelegram() {
    const message = encodeURIComponent(
      `Buy my product\n\nCheck this image: ${selectedImage}\n\n${caption}`
    );
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
      store_url
    )}&text=${message}`;
    window.open(telegramUrl, "_blank");
  }

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[600px] bg-white dark:bg-[#121212] rounded-3xl  pb-4  overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-[600px] sm:w-full  ">
                  <div className=" sm:block absolute top-3 right-5 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-[#ffeae5] rounded-md p-1 sm:p-3 "
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>
                  <div className="px-4 mt-7 sm:mt-3 sm:p-8">
                    <h3 className="text-center mb-5 sm:mb-8 text-base sm:text-lg">
                      Share product with customers
                    </h3>
                    <h3 className="text-sm sm:text-base font-medium text-left mb-4 mt-4 sm:mb-6">
                      "Hey! Share products with your customers so they can
                      explore and purchase your product. You can also use the
                      link to showcase your product on your social media or
                      directly to interested buyers!"
                    </h3>

                    <div className="mb-6">
                      <div className="grid grid-cols-2 gap-4">
                        {product_images?.length > 0 &&
                          product_images?.map((image, index) => (
                            <figure
                              key={index}
                              className="relative cursor-pointer"
                            >
                              <img
                                src={image}
                                alt="product_image"
                                onClick={() => setSelectedImage(image)}
                                className="h-[120px] sm:h-[164px] rounded-[14px] w-full object-cover"
                              />
                              {selectedImage && selectedImage === image && (
                                <div className="absolute top-2 right-2 flex flex-col">
                                  <img
                                    src={Heart}
                                    alt="heart"
                                    className="max-h-[34px]"
                                  />
                                  <p className="text-sm mt-0 text-white">
                                    Selected
                                  </p>
                                </div>
                              )}
                            </figure>
                          ))}
                      </div>

                      <div className="mt-8 text-left">
                        <Input.TextArea
                          htmlFor={"caption"}
                          title={"Caption"}
                          placeholder={"Add caption."}
                          name="caption"
                          value={caption}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="text-left">
                      <p className="mb-2">Share product via:</p>
                      <div className="grid grid-cols-4 sm:grid-cols-5 gap-2">
                        <button
                          className="flex flex-col items-center"
                          onClick={() => shareOnWhatsApp()}
                        >
                          <img src={WHATSAPP} alt="icon" />
                          <p className=" text-base text-center">Whatsapp</p>
                        </button>
                        <button
                          className="flex flex-col items-center"
                          onClick={() => shareOnFacebook()}
                        >
                          <img src={FACEBOOK} alt="icon" />
                          <p className=" text-base text-center">Facebook</p>
                        </button>
                        {/* <button className='flex flex-col items-center' onClick={() => shareOnInstagram()}>
                          <img src={INSTAGRAM} alt="icon" />
                          <p className=" text-base text-center">
                            Instagram
                          </p>
                        </button> */}
                        <button
                          className="flex flex-col items-center"
                          onClick={() => shareOnTwitter()}
                        >
                          <img src={TWITTER} alt="icon" />
                          <p className=" text-base text-center">Twitter</p>
                        </button>
                        <button
                          className="flex flex-col items-center"
                          onClick={() => shareOnTelegram()}
                        >
                          <img
                            src={TELEGRAM}
                            alt="icon"
                            className="mt-2 ml-2"
                          />
                          <p className=" text-base text-center mt-4">
                            Telegram
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
