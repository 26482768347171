import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { packageService } from "../../services/package.service";
import packageType from "./packagesTypes";

/**
 * Get all packages
 **/

export const getAllPackagesAction = () => async (dispatch) => {
  try {
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: true });
    const response = await packageService.getAllPackages();
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: false });
    dispatch({ type: packageType["ALL_PACKAGES"], payLoad: response });
  } catch (error) {
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all packages no pagination
 **/

export const getAllPackagesNoPagination = (payload) => async (dispatch) => {
  try {
    dispatch({ type: packageType["DOWNLOADING"], payLoad: true });
    const response = await packageService.getAllPackagesNoPagination(payload);
    dispatch({ type: packageType["DOWNLOADING"], payLoad: false });
    return response;
  } catch (error) {
    dispatch({ type: packageType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a package
 **/

export const getAPackageAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: true });
    const response = await packageService.getAPackage(id);
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: false });
    dispatch({ type: packageType["SINGLE_PACKAGE"], payLoad: response });
  } catch (error) {
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search packages
 **/

export const searchPackageAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: packageType["SEARCHING"], payLoad: true });
    const response = await packageService.searchPackage(id);
    dispatch({ type: packageType["SEARCHING"], payLoad: false });
    dispatch({ type: packageType["SEARCH_PACKAGE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: packageType["SEARCHING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter packages
 **/

export const filterPackageAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: packageType["FILTERING"], payLoad: true });
    const response = await packageService.filterPackage(payload, page);
    dispatch({ type: packageType["FILTERING"], payLoad: false });
    dispatch({ type: packageType["FILTER_PACKAGE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: packageType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Store packages
 **/

export const storePackageAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: true });
    const response = await packageService.storePackage(payLoad);
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: false });
    dispatch({ type: packageType["STORE_PACKAGE"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: packageType["LOADING_PACKAGE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve package by mgt
 **/

export const approvePackageByMgtAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: packageType["APPROVING"], payLoad: true });
    const response = await packageService.approvePackageByMgt(id);
    dispatch({ type: packageType["APPROVING"], payLoad: false });
    successHandler(response, true);
    window.location.reload(false);
    return response;
  } catch (error) {
    dispatch({ type: packageType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Unapprove Package by mgt
 **/

export const unapprovePackageByMgtAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: packageType["APPROVING"], payLoad: true });
    const response = await packageService.unapprovePackageByMgt(id);
    dispatch({ type: packageType["APPROVING"], payLoad: false });
    successHandler(response, true);
    window.location.reload(false);
    return response;
  } catch (error) {
    dispatch({ type: packageType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Update packages
 **/

export const updatePackageAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: packageType["UPDATING"], payLoad: true });
    const response = await packageService.updatePackage(payLoad);
    dispatch({ type: packageType["UPDATING"], payLoad: false });
    dispatch({ type: packageType["UPDATE_PACKAGE"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: packageType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};
