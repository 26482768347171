import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import { copyTextFromTable } from "../../../../components/helpers";
import EmptyState from "../../../../assets/images/emptyState/orders.svg";
import { PaginationNoRoute } from "../../../../components/pagination/paginationNoRoute";
import { ReactComponent as COPY } from "../../../../assets/images/icons/copy1.svg";
import { filterRestaurantOrderAction } from "../../../../redux/restaurantOrder/restaurantOrderActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Restaurant({ shipper }) {
  const dispatch = useDispatch();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const { allOrders, filtering, searching } = useSelector(
    (state) => state.restaurantOrder
  );

  useEffect(() => {
    if (shipper?.id) {
      dispatch(filterRestaurantOrderAction({ shipper_id: shipper?.id }, page));
    }
    // eslint-disable-next-line
  }, [shipper?.id, page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order) => {
        let show = {
          id: (
            <span className="flex items-center">
              #
              {order?.id?.substr(0, 8) +
                "...." +
                order?.id?.substr(order?.id?.length - 8)}
              <button
                type="button"
                onClick={(e) => {
                  copyTextFromTable(e, order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          status: (
            <span>
              {order?.order_paid ? (
                <span className="text-[#00D220] font-bold">Paid</span>
              ) : (
                <span className="text-primary font-bold">Unpaid</span>
              )}
            </span>
          ),
          payment_method: order?.order_payment_method ?? "Not available",
          orderData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrders]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order ID",
            accessor: "show.id",
          },
          {
            Header: "Date",
            accessor: "show.date",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
          {
            Header: "Payment Status",
            accessor: "show.status",
          },
          {
            Header: "Payment Method",
            accessor: "show.payment_method",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="orders-page">
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-8">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <>
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              rowClass="hover:shadow-md cursor-pointer"
            />
            <PaginationNoRoute data={allOrders} page={page} setPage={setPage} />
          </>
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Orders</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any orders yet.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
