import foodType from "./publicFoodTypes";

const initialState = {
  isLoading: false,
  allFoods: {},
  singleFood: {},
  searching: false,
  search: [],
};

export default function publicFoodReducer(state = initialState, action) {
  switch (action.type) {
    case foodType.LOADING_FOOD:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case foodType.ALL_FOODS:
      return {
        ...state,
        allFoods: action.payLoad?.data,
      };

    case foodType.SINGLE_FOOD:
      return {
        ...state,
        singleFood: action.payLoad?.data,
      };

    case foodType.SEARCHING_FOODS:
      console.log("searching", action.payLoad);
      return {
        ...state,
        searching: action.payLoad,
      };

    case foodType.SEARCH_PUBLIC_FOODS:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    default:
      return state;
  }
}
