import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/buttons";
import { ReactComponent as Medal } from "../../../assets/svgIcons/medal.svg";
// import Cancel from "../../../assets/images/icons/cancel.svg";
import Success from "../../../assets/images/icons/green-tick.svg";
import { ClaimRewardModal } from "../../../components/modals/claimRewardModal";
import { getMyRewardsAction } from "../../../redux/reward/rewardActions";

export default function MyRewards() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState({ status: false, modalObj: {} });

  function closeModal() {
    setIsOpen({ status: false, modalObj: {} });
  }
  function openModal(obj) {
    if (obj) {
      setIsOpen({ status: true, modalObj: obj });
    } else {
      setIsOpen({ status: true, modalObj: {} });
    }
  }

  const { myReward } = useSelector((state) => state.reward);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getMyRewardsAction());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0 px-6 bg-white dark:bg-[#121212] sm:min-h-[1000px] rounded-sm shadow-lg">
        <div className="border-b-2 py-4 sm:py-6 mb-6 block">
          <p className="text-base font-medium text-gray-800 dark:text-white text-center">
            Rewards
          </p>
        </div>
        <div className="flex gap-8 justify-center items-center mx-auto">
          <div className="relative w-full">
            <div className="mb-16">
              <p className="text-sm">
                By using Ogwugo, you’re not just making purchases; you’re
                building value. Each interaction contributes to a growing pool
                of benefits that make every experience with Ogwugo more
                rewarding. Start earning today and watch your Reward Points grow
                with Ogwugo!
              </p>
            </div>

            <h3 className="mb-4 text-[#151515] dark:text-white">
              Reward Points
            </h3>
            <div className="flex items-center gap-6 rounded-lg dark:bg-black px-3 sm:px-5 py-4 mb-8 shadow-lg">
              <div className="bg-[#FFEAE5] h-[40px] w-[40px] sm:h-[60px] sm:w-[60px] rounded-md flex items-center justify-center">
                <Medal className="h-[24px] w-[24px] sm:h-[40px] sm:w-[40px]" />
              </div>
              <div>
                <p className="text-[#344B67B2] dark:text-[#BCBCBC] text-[14px] sm:text-[17px] font-bold mb-1">
                  Total Points
                </p>
                <p className="text-[#00CC1F] dark:text-white text-[8px] sm:text-[15px]">
                  10,000{" "}
                </p>
              </div>
            </div>

            <h3 className="mb-4 text-[#151515] dark:text-white">
              Order & Earn Points
            </h3>

            <div className="flex flex-col gap-4 mb-8">
              {myReward?.length > 0 &&
                myReward?.map((reward, index) => (
                  <div
                    key={index}
                    onClick={() => openModal(reward)}
                    className={`${
                      reward?.visibility === "private" ? "" : "block"
                    } flex items-center justify-between gap-6 rounded-lg dark:bg-black px-3 sm:px-5 py-4 shadow-lg cursor-pointer`}
                  >
                    <div className="flex items-center gap-6">
                      <img
                        className="h-[40px] w-[40px] sm:h-[60px] sm:w-[60px]"
                        alt="reward-icon"
                        src={reward?.picture}
                      />
                      <div>
                        <p className="text-[#344B67B2] dark:text-[#BCBCBC] text-[14px] sm:text-[17px] font-bold mb-1">
                          {reward?.title}
                        </p>
                        <p className="text-[#00CC1F] dark:text-white text-[8px] sm:text-[15px]">
                          {reward?.description}
                        </p>
                      </div>
                    </div>
                    <img
                      className="h-[16px] w-[16px] sm:h-[32px] sm:w-[32px]"
                      alt="status"
                      src={Success}
                    />
                  </div>
                ))}
            </div>

            <Button.Secondary
              type="button"
              title={"Back"}
              className={"sm:hidden rounded-md mb-8 sm:mb-0 w-full"}
              onClick={() => navigate("/profile")}
            />
          </div>
        </div>
      </div>
      {isOpen.status && (
        <ClaimRewardModal
          show={isOpen.status}
          onClose={closeModal}
          modalObj={isOpen.modalObj}
        />
      )}
    </>
  );
}
