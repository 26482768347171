import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "./../../../components/skeleton/table";
import TableFilter from "../../../components/tables/tableFilter";
import { Pagination } from "../../../components/pagination/pagination";
import Catalogue from "../../../assets/images/icons/catalogueEmpty.svg";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";
import FilterCatalogueDropDown from "../../../components/dropDown/filter/catalogueFilter";
import {
  filterCatalogueActionNoPage,
  filterCatalogueAction,
  searchCatalogueAction,
} from "./../../../redux/catalogue/catalogueAction";
import catalogueType from "./../../../redux/catalogue/catalogueTypes";

export default function UnapprovedCatalogues() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const { allCatalogues, filtering, searching, filterNoPage } = useSelector(
    (state) => state.catalogue
  );

  const [isFilterOpen, setFilterState] = useState(false);
  const [payLoad] = useState({
    management_approved: 0,
    // start_date: lastMonth,
    // end_date: new Date(),
  });

  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  useEffect(() => {
    dispatch(filterCatalogueAction(payLoad, params.page));
    dispatch(filterCatalogueActionNoPage(payLoad));
  }, [dispatch, payLoad, params]);

  useEffect(() => {
    if (!params) {
      navigate(`/admin/catalogues/unapproved/1`);
    }
  }, [params, navigate]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((catalogue, index) => {
        let single = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          title: catalogue?.catalogue_title,
          restaurant:
            catalogue?.catalogue_restaurants?.length > 0
              ? catalogue?.catalogue_restaurants?.length
              : 0,
          store:
            catalogue?.catalogue_stores?.length > 0
              ? catalogue?.catalogue_stores?.length
              : 0,
          food:
            catalogue?.catalogue_foods?.length > 0
              ? catalogue?.catalogue_foods?.length
              : 0,
          merchandise:
            catalogue?.catalogue_merchandise?.length > 0
              ? catalogue?.catalogue_merchandise?.length
              : 0,
          packages:
            catalogue?.catalogue_packages?.length > 0
              ? catalogue?.catalogue_packages?.length
              : 0,
          created: (
            <span>{moment(catalogue?.created_at).format("MMM DD, YYYY")}</span>
          ),
          catalogue: catalogue,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allCatalogues?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCatalogues]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((catalogue, index) => {
        let single = {
          title: catalogue?.catalogue_title,
          restaurant:
            catalogue?.catalogue_restaurants?.length > 0
              ? catalogue?.catalogue_restaurants?.length
              : 0,
          store:
            catalogue?.catalogue_stores?.length > 0
              ? catalogue?.catalogue_stores?.length
              : 0,
          food:
            catalogue?.catalogue_foods?.length > 0
              ? catalogue?.catalogue_foods?.length
              : 0,
          merchandise:
            catalogue?.catalogue_merchandise?.length > 0
              ? catalogue?.catalogue_merchandise?.length
              : 0,
          packages:
            catalogue?.catalogue_packages?.length > 0
              ? catalogue?.catalogue_packages?.length
              : 0,
          created: catalogue?.created_at,
        };
        outputArray.push(single);
        return true;
      });
      return outputArray;
    }
  }

  const downloadCSV = () => {
    if (filterNoPage) {
      const newData = createTableNoPage(filterNoPage);
      writeFileWithXLSX(newData, {
        filename: "unapproved catalogue.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allCatalogues?.data);
      setData(dataSet);
      dispatch({
        type: catalogueType["SEARCH_CATALOGUE"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchCatalogueAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const goToSinglePage = (params) => {
    let id = params?.single?.catalogue?.id;
    navigate(`/admin/catalogue/${id}`);
  };
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "single.num",
          },
          {
            Header: "Title",
            accessor: "single.title",
          },

          {
            Header: "Created ",
            accessor: "single.created",
          },
          {
            Header: "Restaurant",
            accessor: "single.restaurant",
          },
          {
            Header: "Food",
            accessor: "single.food",
          },
          {
            Header: "Store",
            accessor: "single.store",
          },
          {
            Header: "Merchandise",
            accessor: "single.merchandise",
          },
          {
            Header: "Packages",
            accessor: "single.packages",
          },
        ],
      },
    ],
    []
  );
  const goToCreate = () => {
    navigate(`/admin/catalogue/create`);
  };
  return (
    <div data-test="">
      <TableFilter
        results={allCatalogues?.data?.length}
        totalResults={allCatalogues?.data?.length}
        title={"Catalogues"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchPlaceholder={"Catalogue title"}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterCatalogueDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Primary title={"Create Catalogue"} onClick={goToCreate} />
        <Button.Secondary
          title={"Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-3">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            <div className="flex justify-center my-12 ">
              <img src={Catalogue} alt="orders" />
            </div>
            <p className="font-bold text-xl my-3">Catalogues</p>
            <p className="text-sm text-gray-500">
              You do not have any approved catalogues yet
            </p>
          </div>
        )}

        <Pagination
          data={allCatalogues}
          route={"admin/catalogues/unapproved"}
        />
      </div>
    </div>
  );
}
