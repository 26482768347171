import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Location from "../../../components/includes/location";
import { onboardBusiness } from "../../../redux/onBoarding/onBoardingAction";
import CreateBusinessDetails from "./components/CreateBusinessDetails";
import OnboardRestaurant from "./components/OnboardRestaurant";
import OnboardStore from "./components/OnboardStore";
import { cleanInput } from "../../../helpers/cleanInput";
import { logoutUserLocallyAction } from "../../../plugins/axios.plugin";
import { history } from "../../../helpers/history";

const tabs = [
  { name: "Create Business", href: "create business" },
  { name: "Outlet Details", href: "restaurant details" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OnboardBusiness() {
  const [active_tab, setActive_tab] = useState("create business");
  const { type } = useParams();
  const dispatch = useDispatch();

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });

  //const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const [submitted, setSubmitted] = useState(false);
  let [restaurant_phone, setRestaurantPhone] = useState("");
  let [store_phone, setStorePhone] = useState("");
  let [business_phone, setBusinessPhone] = useState("");
  const [data, setData] = useState({
    business_email: "",
    business_name: "",
    business_city: "",
    business_address: "",
    business_state: "",
    business_country: "",
    business_description: "",
    business_type: type,
    restaurant_email: "",
    restaurant_name: "",
    restaurant_address: "",
    restaurant_city: "",
    restaurant_state: "",
    restaurant_country: "",
    restaurant_description: "",
    restaurant_categories: [],
    restaurant_tags: [],
    store_email: "",
    store_name: "",
    store_address: "",
    store_city: "",
    store_state: "",
    store_country: "",
    store_description: "",
    store_categories: [],
    store_tags: [],
  });
  const {
    business_email,
    business_name,
    business_type,
    business_city,
    business_address,
    business_state,
    business_country,
  } = data;

  business_phone = business_phone?.slice(1);
  restaurant_phone = restaurant_phone?.slice(1);
  store_phone = store_phone?.slice(1);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "restaurant_categories") {
      setData({ ...data, restaurant_categories: [value] });
    } else if (name === "restaurant_tags") {
      setData({ ...data, restaurant_tags: [value] });
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const toggle = (tab) => {
    setActive_tab(tab.href);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      business_name &&
      business_phone &&
      business_email &&
      business_type &&
      business_city &&
      business_state &&
      business_address &&
      business_country
    ) {
      // dispatch action to create data
      cleanInput(data);
      const response = await dispatch(
        onboardBusiness({
          ...data,
          business_phone,
          restaurant_phone,
        })
      );
      if (response === true) {
        logoutUserLocallyAction();
        history("/success");
      }
    }
  };
  const handleBack = () => {
    if (type === "restaurant") {
      setActive_tab("create business");
    } else if (type === "store") {
      setActive_tab("create business");
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const handleNext = () => {
    setSubmitted(true);
    if (
      business_name &&
      business_phone &&
      business_email &&
      business_type &&
      business_city &&
      business_state &&
      business_address &&
      business_country
    ) {
      if (type === "restaurant") {
        setActive_tab("restaurant details");
      }
    } else if (type === "store") {
      setActive_tab("store details");
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className="px-4 sm:mt-10">
      <nav
        className="-mb-px flex sm:hidden space-x-8 overflow-x-auto sm:max-w-[629px] mx-auto mt-10"
        aria-label="Tabs"
      >
        {tabs.map((tab) => (
          <button
            key={tab.name}
            onClick={() => {
              toggle(tab);
            }}
            className={classNames(
              tab.href === active_tab
                ? "border-primary text-primary font-bold"
                : "border-transparent text-[#BCBCBC] hover:text-gray-700 hover:border-gray-300 dark:hover:text-primary",
              "whitespace-nowrap py-2 px-2 border-b-2 dark:border-b-2 font-medium text-sm"
            )}
            aria-current={tab.href === active_tab ? "page" : undefined}
          >
            {tab.name}
          </button>
        ))}
      </nav>
      {active_tab === "create business" && (
        <CreateBusinessDetails
          handleChange={handleChange}
          data={data}
          business_phone={business_phone}
          setBusinessPhone={setBusinessPhone}
          handleSubmit={handleSubmit}
          submitted={submitted}
          Location={Location}
          locationId={locationId}
          locationList={locationList}
          setLocationList={setLocationList}
          setLocationId={setLocationId}
          handleRegion={handleRegion}
          handleNext={handleNext}
        />
      )}
      {active_tab === "restaurant details" && (
        <OnboardRestaurant
          handleChange={handleChange}
          data={data}
          setData={setData}
          restaurant_phone={restaurant_phone}
          setRestaurantPhone={setRestaurantPhone}
          handleSubmit={handleSubmit}
          submitted={submitted}
          Location={Location}
          locationId={locationId}
          locationList={locationList}
          setLocationList={setLocationList}
          setLocationId={setLocationId}
          handleRegion={handleRegion}
          handleBack={handleBack}
        />
      )}
      {active_tab === "store details" && (
        <OnboardStore
          store_phone={store_phone}
          setStorePhone={setStorePhone}
          handleChange={handleChange}
          data={data}
          setData={setData}
          handleSubmit={handleSubmit}
          submitted={submitted}
          Location={Location}
          locationId={locationId}
          locationList={locationList}
          setLocationList={setLocationList}
          setLocationId={setLocationId}
          handleRegion={handleRegion}
          handleBack={handleBack}
        />
      )}
    </div>
  );
}
