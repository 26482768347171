import React, { useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// components
import Button from "../../../../components/buttons/buttons";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import { Pagination } from "../../../../components/pagination/pagination";
// requests
import { getARestaurantAction } from "../../../../redux/restaurant/restaurantActions";

// sample data array looks like this
const data = [
  {
    show: {
      name: "001",
      type: "Citi Chef",
      phone: "090989796948",
      approval: "XXAABZCD002",
      status: "Pending",
      location: "Enugu",
    },
  },
  {
    show: {
      name: "002",
      type: "Impala Plaza",
      phone: "090989796948",
      approval: "XXAABZCD002",
      status: "Pending",
      location: "Enugu",
    },
  },
  {
    show: {
      name: "003",
      type: "Citi Chef",
      phone: "090989796948",
      approval: "XXAABZCD002",
      status: "Pending",
      location: "Enugu",
    },
  },
];

export default function RestaurantReportDetail() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { restaurant } = useSelector((state) => state.restaurant);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getARestaurantAction(id));
    // eslint-disable-next-line
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.name",
          },
          {
            Header: "Status",
            accessor: "show.type",
          },
          {
            Header: "Item Name",
            accessor: "show.approval",
          },
          {
            Header: "Item Quantity",
            accessor: "show.phone",
          },
          {
            Header: "Unit Price",
            accessor: "show.location",
          },
          {
            Header: "Item Amount",
            accessor: "show.status",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="singleRestaurant-page">
      <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">
          Restaurant: {restaurant?.restaurant_name}
        </h1>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">{restaurant?.restaurant_name}</h3>
          <Button.Primary
            title={"Check Stats"}
            onClick={() => navigate("/admin/business-report/statistics")}
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Restaurant ID</label>
            <p className="text-[#151515] text-sm">{restaurant?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm">
              {restaurant?.restaurant_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">
              {restaurant?.restaurant_phone}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Approve/Unapprove
            </label>
            <p className="text-[#151515] text-sm">
              {restaurant?.management_approved ? "Approved" : "Unapproved"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(restaurant?.created_at).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] text-sm">
              {restaurant?.management_approved ? "Approved" : "Unapproved"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm">
              {restaurant?.restaurant_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm">
              {restaurant?.restaurant_state}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm">
              {restaurant?.restaurant_city}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Restaurant Owner Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Approve/Unapprove
            </label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Orders History</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Total Orders </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Accepted Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Completed Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Pending Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Cancelled Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Rejected Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Card.TableFilter results={80}>
          <Button.Secondary title={"Download CSV"} />
        </Card.TableFilter>

        <div className="border-b border-[#E4E4F3]"></div>

        <div className="bg-white rounded-md py-4 px-8">
          <h4>Orders Summary</h4>
          <Table columns={columns} data={data} className="w-full" />
        </div>
        <Pagination />
      </div>
    </div>
  );
}
