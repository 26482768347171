import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { authService } from "../../services/auth.service";
import Cookies from "js-cookie";
import authType from "./authTypes";
import axios from "../../plugins/axios.plugin";
// import {
//   persistLastLogin,
//   updatePersistedLastLogin,
//   retrievePersistedLastLogin,
//   destroyLastLogin,
// } from "../../helpers/session";

// const storeLoginDetails = (payload) => {
//   return {
//     type: authType["STORE_LOGIN_DETAILS"],
//     payload,
//   };
// };
// const storeUserDetails = (payload) => {
//   return {
//     type: authType["AUTH_SUCCESS"],
//     payload,
//   };
// };

let base_url = window.location.origin;

export const saveUserDetails = (data) => {
  const user = JSON.stringify(data.data);
  const token = data.data.access_token;
  Cookies.set("token", token);
  Cookies.set("user", user);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  let team = Object.keys(data?.data?.team_roles);
  if (team) {
    function getHighestPriorityRole(roles) {
      if (roles.includes("administrator")) {
        return "administrator";
      } else if (roles.length > 0) {
        return roles[0];
      }
    }

    axios.defaults.headers.common["Team"] = getHighestPriorityRole(team);
    Cookies.set("team", getHighestPriorityRole(team));
  }
};

/**
 * Register a user
 * 
 * @param {object} payLoad {
    name:"black"
    email:"black@gmail.com"
    password:"password"
    password_confirmation:"password"
    phone:"08021415578"
    description:"I am a human being"
    birth_date:"0000-04-17"
    birth_year:"1997"
  }
 * @returns {void}
 */

export const registerUserAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await authService.emailRegistration({ ...payLoad });
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["REGISTER_USER"], payLoad: response });
    if (response?.status === "success") {
      let loggedIn = await dispatch(
        registerLoginAction({
          email: payLoad?.email,
          password: payLoad?.password,
        })
      );
      loggedIn && successHandler(response, true);
    }
    return response;
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
    throw error;
  }
};

/**
 * Login a user
 *
 * @param {object} payLoad {
 * "email": "blue@gmail.com",
 * "password": "blueblue"
 * }
 * @returns {void}
 */
export const loginUserAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await authService.emailLogin({ ...payLoad });
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["LOGIN_USER"], payLoad: response });
    saveUserDetails(response, dispatch);
    // persistLastLogin(res);
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
    throw error;
  }
};

/**
 * Login a user
 *
 * @param {object} payLoad {
 * "email": "blue@gmail.com",
 * "password": "blueblue"
 * }
 * @returns {void}
 */
export const registerLoginAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await authService.emailLogin({ ...payLoad });
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["LOGIN_USER"], payLoad: response });
    saveUserDetails(response, dispatch);
    return true;
  } catch (error) {
    console.log("error", error);
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
    throw error;
  }
};

/**
 * indicate forgot password
 *
 * @param {object} payLoad {email:"",reset_form_link:""}
 * @returns
 */
export const forgotPasswordAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await authService.forgotPassword({
      ...payLoad,
      reset_form_link: `${base_url}/reset-link`,
    });

    dispatch({ type: authType["FORGOT_PASSWORD"], payLoad: response });
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Reset a user password
 * 
 * @param {object} payLoad {
  email:"blue@gmail.com"
  token:"lQf5xwqSmxSIyT22OVfe"
  new_password:"this-is-a-new-password"
  new_password_confirmation:"this-is-a-new-password"
 }
 * @returns 
 */
export const resetPasswordAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await authService.resetPassword({
      ...payLoad,
    });
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["RESET_PASSWORD"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Change a user password
 * 
 * @param {object} payLoad {
  old_password:"blue-blue"
  new_password:"black-blue"
  new_password_confirmation:"black-blue"
 }
 * @returns 
 */

export const changePasswordAction = (payLoad) => async (dispatch) => {
  try {
    const response = await authService.changePassword({
      ...payLoad,
    });
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["CHANGE_PASSWORD"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Verify user email address
 * 
 * @param {object} payLoad {
  email:"black@gmail.com"
  token:"9b3b7bb4453551002f948e9899d28d12"
 }
 * @returns 
 */
// export const verifyUserEmailAction = (payLoad) => {
//   return (dispatch) => {
//     dispatch({ type: LOADING_AUTH, payLoad: true });
//     Axios.get(`auth/verify`, { params: payLoad })
//       .then((res) => {
//         dispatch({ type: LOADING_AUTH, payLoad: false });
//         dispatch({ type: VERIFY_USER_EMAIL, payLoad: res });
//         successHandler(res, true);
//       })
//       .catch((error) => {
//         dispatch({ type: LOADING_AUTH, payLoad: false });
//         errorHandler(error, true);
//       });
//   };
// };

/**
 * Get user details
 *
 * @param {void}
 * @returns
 */
// export const getUserDetailsAction = () => {
//   return (dispatch) => {
//     // dispatch({ type: LOADING_AUTH, payLoad: true });
//     Axios.post(`auth/me`)
//       .then((res) => {
//         // dispatch({ type: LOADING_AUTH, payLoad: false });
//         dispatch({ type: USER_DETAILS, payLoad: res });
//         successHandler(res, true);
//       })
//       .catch((error) => {
//         // dispatch({ type: LOADING_AUTH, payLoad: false });
//         errorHandler(error, true);
//       });
//   };
// };

/**
 * Refresh user token
 *
 * @param {void}
 * @returns
 */
// export const refreshUserTokenAction = () => {
//   return (dispatch) => {
//     dispatch({ type: LOADING_AUTH, payLoad: true });
//     Axios.post(`auth/refresh`)
//       .then((res) => {
//         dispatch({ type: LOADING_AUTH, payLoad: false });
//         dispatch({ type: REFRESHED_TOKEN, payLoad: res });
//         updatePersistedLastLogin(res);
//         successHandler(res, true);
//       })
//       .catch((error) => {
//         dispatch({ type: LOADING_AUTH, payLoad: false });
//         errorHandler(error, true);
//       });
//   };
// };

/**
 * Test user model
 *
 * @param {void}
 * @returns
 */
// export const testUserModelAction = () => {
//   return (dispatch) => {
//     dispatch({ type: LOADING_AUTH, payLoad: true });
//     Axios.get(`auth/test`)
//       .then((res) => {
//         dispatch({ type: LOADING_AUTH, payLoad: false });
//         dispatch({ type: TEST_USER_MODEL, payLoad: res });
//         successHandler(res, true);
//       })
//       .catch((error) => {
//         dispatch({ type: LOADING_AUTH, payLoad: false });
//         errorHandler(error, true);
//       });
//   };
// };

/**
 * Retrieve user details from cache
 *
 * @param {void}
 * @returns {void}
 */
// export const restoreUserFromCacheAction = () => {
//   return (dispatch) => {
//     const data = retrievePersistedLastLogin();
//     if (data) {
//       dispatch({ type: LOGIN_USER, payLoad: data });
//     }
//   };
// };
