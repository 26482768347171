import authType from "./packagesTypes";

const initialState = {
  isLoading: false,
  searching: false,
  approving: false,
  filtering: false,
  updating: false,
  downloading: false,
  allPackages: {},
  singlePackage: {},
  search: [],
};

export default function packagesReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_PACKAGE:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.APPROVING:
      return {
        ...state,
        approving: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    case authType.ALL_PACKAGES:
      return {
        ...state,
        allPackages: action.payLoad?.data,
      };

    case authType.SINGLE_PACKAGE:
      return {
        ...state,
        singlePackage: action.payLoad?.data,
      };

    case authType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_PACKAGE:
      return {
        ...state,
        search: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.FILTER_PACKAGE:
      return {
        ...state,
        allPackages: action.payLoad?.data,
      };
    default:
      return state;
  }
}
