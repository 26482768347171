import React from "react";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";

export default function ShippersRate1({
  handleChange,
  handleBack,
  data,
  handleSubmit,
  submitted,
  handleNext2,
}) {
  const {
    base_fee_per_meter,
    insurance_percentage,
    tax_percentage,
    discount_percentage,
    base_fee,
  } = data;

  return (
    <>
      <div className=" mx-auto bg-white dark:bg-[#121212] sm:w-[630px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200 dark:border-none">
        <div className="border-b-2 py-4 mb-6">
          <p className=" text-base font-medium text-gray-800 dark:text-white flex justify-center ">
            Shipper Rate
          </p>
        </div>
        <form name="form" id="shippers-form" onSubmit={(e) => handleSubmit(e)}>
          <div className="sm:px-10">
            <div>
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg font-bold text-[#151515] dark:text-white">
                  Charge per kilometer (1km) :
                </h3>
                <p className="text-[#4D4D4DB2] dark:text-[#BCBCBC] text-sm">
                  This is the charge per every kilometer travelled for delivery.
                </p>
              </div>
              <Input.Label
                title={"Charge"}
                value={base_fee_per_meter}
                htmlFor={"base_fee_per_meter"}
                name={"base_fee_per_meter"}
                placeholder={"NGN 500"}
                onChange={handleChange}
              />
              {submitted && !base_fee_per_meter && (
                <div className="text-primary text-xs ">Charge is required</div>
              )}
            </div>
            <div>
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg font-bold text-[#151515] dark:text-white">
                  Insurance Rate (%) :
                </h3>
                <p className="text-[#4D4D4DB2] dark:text-[#BCBCBC] text-sm">
                  This is the percentage calculated per declared/ estimated
                  value of customer items for insurance.
                </p>
              </div>
              <Input.Label
                title={"Insurance Percentage"}
                htmlFor={"insurance_percentage"}
                name={"insurance_percentage"}
                value={insurance_percentage}
                placeholder={"Enter Insurance percentage"}
                onChange={handleChange}
              />
              {submitted && !insurance_percentage && (
                <div className="text-primary text-xs ">
                  Insurance rate is required
                </div>
              )}
            </div>
            <div>
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg  font-bold text-[#151515] dark:text-white">
                  Base Charge (NGN) :
                </h3>
                <p className="text-[#4D4D4DB2] dark:text-[#BCBCBC] text-sm">
                  This is a company's profit margin.
                </p>
              </div>
              <Input.Label
                title={"Base Charge"}
                htmlFor={"base_fee"}
                value={base_fee}
                name={"base_fee"}
                placeholder={"Enter base Fee"}
                onChange={handleChange}
              />
              {submitted && !base_fee && (
                <div className="text-primary text-xs ">
                  Base charge is required
                </div>
              )}
            </div>
            <div>
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg  font-bold text-[#151515] dark:text-white">
                  Tax Percentage (%) :
                </h3>
                <p className="text-[#4D4D4DB2] dark:text-[#BCBCBC] text-sm">
                  This is a company's tax margin.
                </p>
              </div>
              <Input.Label
                title={"Tax Percentage"}
                htmlFor={"tax_percentage"}
                name={"tax_percentage"}
                value={tax_percentage}
                placeholder={"Enter tax Percentage"}
                onChange={handleChange}
              />
              {submitted && !tax_percentage && (
                <div className="text-primary text-xs ">
                  Tax Percentage is required
                </div>
              )}
            </div>

            <div className="mb-10">
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg font-bold text-[#151515] dark:text-white">
                  Discount Percentage (%) :
                </h3>
                <p className="text-[#4D4D4DB2] dark:text-[#BCBCBC] text-sm">
                  This is a company's discount margin.
                </p>
              </div>
              <Input.Label
                title={"Discount Percentage"}
                value={discount_percentage}
                htmlFor={"discount_percentage"}
                name={"discount_percentage"}
                placeholder={"Enter discount percentage"}
                onChange={handleChange}
              />
              {submitted && !discount_percentage && (
                <div className="text-primary text-xs ">
                  Discount rate is required
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="flex justify-center mx-auto">
        <Button.Secondary
          onClick={handleBack}
          title={"Previous"}
          className={" w-24 sm:mt-10 mb-7 mr-2 rounded-sm "}
        />
        <Button.Primary
          onClick={handleNext2}
          title={"Next"}
          className={" w-16 sm:mt-10 mb-7 ml-2 rounded-sm "}
        />
      </div>
    </>
  );
}
