import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../../buttons/buttons";
import Input from "../../inputs/inputs";
import { Beat } from "../../../plugins/spinners.plugin";
import EmptyState from "../../../assets/images/emptyState/food.svg";
import {
  rejectHumanVerificationAction,
  verifyHumanVerificationAction,
  showAHumanVerification,
} from "../../../redux/humanVerification/humanVerificationActions";
import { filterHumanVerificationLevel } from "../../../redux/humanVerificationLevel/humanVerificationLevelActions";

export default function ReviewKYCModal({ singleVerification }) {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [kycStatus, setKycStatus] = useState("");

  const { allVerificationLevel, updating } = useSelector(
    (state) => state.humanVerificationLevel
  );

  const [payLoad] = useState({
    end_date: new Date(),
    pagination: 0,
  });

  const [data, setData] = useState({
    user_id: "",
    comment: "",
    level_id: "",
  });

  useEffect(() => {
    dispatch(filterHumanVerificationLevel(payLoad));
  }, [dispatch, payLoad]);

  function handleClose() {
    setIsOpen(false);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (kycStatus === "accepted" && data?.comment) {
      try {
        const response = await dispatch(
          verifyHumanVerificationAction({
            user_id: singleVerification?.user_id,
            comment: data?.comment,
            level_id: data?.level_id,
          })
        );
        if (response?.status === "success") {
          dispatch(showAHumanVerification(singleVerification?.user_id));
          handleClose();
        }
      } catch (error) {}
    } else if (kycStatus === "rejected" && data?.comment) {
      try {
        const response = await dispatch(
          rejectHumanVerificationAction({
            user_id: singleVerification?.user_id,
            comment: data?.comment,
          })
        );

        if (response?.status === "success") {
          dispatch(showAHumanVerification(singleVerification?.user_id));
          handleClose();
        }
      } catch (error) {}
    }
  };

  return (
    <div>
      <Button.Primary
        title={"Review Status"}
        className={"mr-4 mb-2 sm:mb-0"}
        onClick={() => setIsOpen(true)}
      />
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-white dark:bg-[#121212] w-[358px] rounded-sm sm:w-[630px] p-4 sm:p-6 sm:px-8">
                  <form name="form" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4"></div>
                      <div className="">
                        <div className=" text-center  ">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-medium text-black dark:text-white"
                          >
                            KYC Entries
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className=" border-b-2 mt-4"></div>

                      {/* User details */}
                      <div className="mt-3 sm:mt-5 shadow-lg px-4 pb-8 rounded-md">
                        <div className="border-b-2 py-2 border-[#EAEAEA]">
                          <h3 className="text-left font-medium text-lg">
                            User Details
                          </h3>
                        </div>

                        <div className="flex justify-between items-center py-2">
                          <span>Full Name</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {singleVerification?.user?.name}
                          </span>
                        </div>
                        <div className="flex justify-between items-center py-2">
                          <span>Email</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {singleVerification?.user?.email}
                          </span>
                        </div>
                        <div className="flex justify-between items-center py-2">
                          <span>Phone Number</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {singleVerification?.user?.phone}
                          </span>
                        </div>
                        <div className="flex justify-between items-center py-2">
                          <span>Date Created</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {moment(
                              singleVerification?.user?.created_at
                            ).format("MMM DD, YYYY")}
                          </span>
                        </div>
                        <div className="flex justify-between items-center py-2">
                          <span>Country</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {singleVerification?.user?.country}
                          </span>
                        </div>
                        <div className="flex justify-between items-center py-2">
                          <span>State</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {singleVerification?.user?.state}
                          </span>
                        </div>
                        <div className="flex justify-between items-center py-2">
                          <span>City</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {singleVerification?.user?.city}
                          </span>
                        </div>
                      </div>

                      {/* KYC Details */}
                      <div className="mt-3 sm:mt-8 shadow-lg px-4 pb-8 rounded-md">
                        <div className="border-b-2 py-2 border-[#EAEAEA]">
                          <h3 className="text-left font-medium text-lg">
                            KYC Details
                          </h3>
                        </div>

                        <div className="flex justify-between items-center py-2">
                          <span>KYC Document</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {singleVerification?.document_type}
                          </span>
                        </div>

                        <div className="flex justify-between items-center py-2">
                          <span>License No:</span>
                          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
                            {singleVerification?.document_number}
                          </span>
                        </div>

                        <div className="mt-5 sm:mt-5 shadow-lg px-4 rounded-md">
                          <div className="border-b-2 py-2 border-[#EAEAEA]">
                            <h3 className="text-left font-medium text-lg">
                              Identity Card (Images)
                            </h3>
                          </div>

                          <div className="grid grid-cols-4 gap-3 items-center py-2">
                            {singleVerification?.documents?.length > 0 &&
                              singleVerification?.documents?.map(
                                (image, index) => (
                                  <img
                                    key={index}
                                    src={image ? image : EmptyState}
                                    alt="document"
                                    className="h-[94px] h-[94px] object-cover"
                                  />
                                )
                              )}
                          </div>
                        </div>

                        <div className="mt-5 sm:mt-5 shadow-lg px-4 rounded-md">
                          <div className="border-b-2 py-2 border-[#EAEAEA]">
                            <h3 className="text-left font-medium text-lg">
                              Proof Of Life (Images)
                            </h3>
                          </div>

                          <div className="grid grid-cols-4 gap-3 items-center py-2">
                            {singleVerification?.pictures?.length > 0 &&
                              singleVerification?.pictures?.map(
                                (picture, index) => (
                                  <img
                                    key={index}
                                    src={picture ? picture : EmptyState}
                                    alt="user_picture"
                                    className="h-[94px] h-[94px] object-cover"
                                  />
                                )
                              )}
                          </div>
                        </div>
                      </div>

                      {/* KYC Details */}
                      <div className="mt-3 sm:mt-8 shadow-lg px-4 py-4 rounded-md text-left">
                        <div className="border-b-2 py-2 border-[#EAEAEA] mb-6">
                          <h3 className="text-left font-medium text-lg">
                            KYC Details
                          </h3>
                        </div>
                        <Input.Select
                          title={"KYC Status"}
                          name="status"
                          id="status"
                          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                          htmlFor="status"
                          onChange={(e) => setKycStatus(e.target.value)}
                        >
                          <option>Select kyc status for this user</option>
                          <option value={"accepted"}>Accepted</option>
                          <option value={"rejected"}>Rejected</option>
                        </Input.Select>

                        {kycStatus === "accepted" && (
                          <Input.Select
                            title={"KYC Tier"}
                            name="level_id"
                            id="level_id"
                            className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                            htmlFor="level_id"
                            value={data?.level_id}
                            onChange={handleChange}
                          >
                            <option>Select kyc tier for this user</option>
                            {allVerificationLevel?.length > 0 &&
                              allVerificationLevel.map((level, index) => (
                                <option value={level?.id} key={index}>
                                  {level?.title}
                                </option>
                              ))}
                          </Input.Select>
                        )}

                        <div>
                          <Input.TextArea
                            htmlFor={"comment"}
                            title={"Reviewers comment *(comment is required)"}
                            placeholder={
                              "What led to the verification of this user? Pick one comment below."
                            }
                            name="comment"
                            value={data?.comment}
                            defaultValue={singleVerification?.comment}
                            onChange={handleChange}
                          />
                          {submitted && !data?.comment && (
                            <div className="text-primary text-xs mb-4">
                              Reviewers comment is required
                            </div>
                          )}
                        </div>

                        {/* KYC comment samples */}
                        <div className="flex flex-wrap gap-3 mt-3 mb-4">
                          <button
                            type="button"
                            onClick={(e) =>
                              setData((data) => ({
                                ...data,
                                comment: e.target.innerText,
                              }))
                            }
                            className="bg-[#F4F4F4] dark:bg-black hover:bg-[#e2e2e2] text-xs px-2 py-1 rounded-lg"
                          >
                            Your KYC was approved
                          </button>
                          <button
                            type="button"
                            onClick={(e) =>
                              setData((data) => ({
                                ...data,
                                comment: e.target.innerText,
                              }))
                            }
                            className="bg-[#F4F4F4] dark:bg-black hover:bg-[#e2e2e2] text-xs px-2 py-1 rounded-lg"
                          >
                            Your documents are not complete
                          </button>
                          <button
                            type="button"
                            onClick={(e) =>
                              setData((data) => ({
                                ...data,
                                comment: e.target.innerText,
                              }))
                            }
                            className="bg-[#F4F4F4] dark:bg-black hover:bg-[#e2e2e2] text-xs px-2 py-1 rounded-lg"
                          >
                            Your KYC was rejected
                          </button>
                          <button
                            type="button"
                            onClick={(e) =>
                              setData((data) => ({
                                ...data,
                                comment: e.target.innerText,
                              }))
                            }
                            className="bg-[#F4F4F4] dark:bg-black hover:bg-[#e2e2e2] text-xs px-2 py-1 rounded-lg"
                          >
                            Unable to verify documents
                          </button>
                          <button
                            type="button"
                            onClick={(e) =>
                              setData((data) => ({
                                ...data,
                                comment: e.target.innerText,
                              }))
                            }
                            className="bg-[#F4F4F4] dark:bg-black hover:bg-[#e2e2e2] text-xs px-2 py-1 rounded-lg"
                          >
                            Your KYC is pending
                          </button>
                        </div>
                      </div>

                      <div className="flex mt-10 justify-center mb-4">
                        <Button.Primary
                          type="submit"
                          disabled={updating}
                          title={updating ? <Beat /> : "Save Changes"}
                          className="h-10 text-center rounded-sm border border-transparent shadow-sm px-4  bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
