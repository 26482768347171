import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../../plugins/spinners.plugin";
import Button from "../../../../../components/buttons/buttons";
import Table from "../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../components/skeleton/table";
import TableFilter from "../../../../../components/tables/tableFilter";
import { Pagination } from "../../../../../components/pagination/pagination";
import EmptyState from "../../../../../assets/images/emptyState/food.svg";
import FilterFoodDropDown from "../../../../../components/dropDown/filter/filterFood";
import { AllFiltersModal } from "../../../../../components/modals/allFiltersModal";
import {
  filterFoodAction,
  searchFoodAction,
  getAllFoodNoPagination,
} from "../../../../../redux/food/foodActions";
import foodType from "../../../../../redux/food/foodTypes";
import { writeFileWithXLSX } from "../../../../../components/exports/xlsx";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Approve() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    management_approved: true,
  });

  const { allFood, downloading } = useSelector((state) => state.food);
  const { filtering, searching } = useSelector((state) => state.food);

  useEffect(() => {
    dispatch(filterFoodAction(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((food, index) => {
        let show = {
          id: index + 1,
          food: food.food_name,
          discount: <span>NGN {numberWithCommas(food?.food_discount)}</span>,
          amount: <span>NGN {numberWithCommas(food?.food_amount)}</span>,
          availability: (
            <span className="capitalize">{food?.food_operating_status}</span>
          ),
          approval: (
            <span>
              {food?.management_approved ? (
                <span className="text-[#00D220] font-bold">Approved</span>
              ) : (
                <span className="text-primary font-bold">Unapproved</span>
              )}
            </span>
          ),

          foodData: food,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((food, index) => {
        let show = {
          index: index + 1,
          food: food?.food_name,
          discount: food?.food_discount,
          amount: food?.food_amount,
          availability: food?.food_operating_status,
          approval: food?.management_approved ? "Approved" : "Unapproved",
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allFood?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFood]);

  function goToSinglePage(params) {
    let id = params?.show?.foodData?.id;
    navigate(`/admin/food/${id}`, {
      state: { food: params?.show?.foodData },
    });
  }

  function goToCreate() {
    navigate(`/admin/food/create`);
  }

  const handleDownload = async () => {
    const response = await dispatch(
      getAllFoodNoPagination({
        management_approved: true,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Approved_Foods.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allFood?.data);
      setData(dataSet);
      dispatch({
        type: foodType["SEARCH_FOOD"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchFoodAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Food Name",
            accessor: "show.food",
          },
          {
            Header: "Discount",
            accessor: "show.discount",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
          {
            Header: "Availability",
            accessor: "show.availability",
          },
          {
            Header: "Approved",
            accessor: "show.approval",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <TableFilter
        title={"Food"}
        results={allFood?.to}
        totalResults={allFood?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchPlaceholder={"Search food name, id, price..."}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterFoodDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Primary title={"+ Create Food"} onClick={goToCreate} />
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          type="button"
          disabled={!data}
          onClick={handleDownload}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3] dark:border-[#505050]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-3">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Food</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any food yet.
            </p>
          </div>
        )}

        <Pagination data={allFood} route={"admin/food/approved"} />
      </div>
    </div>
  );
}
