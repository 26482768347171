import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getSingleAdvertisment } from "../../../../redux/advertisment/advertismentAction";
import { Switch } from "@headlessui/react";
import moment from "moment";
import EditSlider from "./../../../../components/modals/admin/editSlider";
import {
  approveAdvertisment,
  unApproveAdvertisment,
} from "../../../../redux/advertisment/advertismentAction";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleSlider() {
  const dispatch = useDispatch();
  const location = useLocation();

  const slider = location.state.slider;
  const { singleAdvertisment } = useSelector((state) => state.advertisment);
  useEffect(() => {
    dispatch(getSingleAdvertisment(slider?.id));

    // eslint-disable-next-line
  }, [slider]);

  const [approved, setApproved] = useState(false);
  const [unApproved, setUnApproved] = useState(true);
  const handleApprove = (e) => {
    dispatch(approveAdvertisment(singleAdvertisment?.id));
  };
  const handleUnApprove = (e) => {
    dispatch(unApproveAdvertisment(singleAdvertisment?.id));
  };
  return (
    <div data-test="singleOrder-page">
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Slider Details</h3>
          <EditSlider advert={singleAdvertisment} />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Title
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleAdvertisment?.title}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Created on
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {" "}
              {moment(singleAdvertisment?.created_at).format("DD/MM/YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Visibility
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleAdvertisment?.visibility}
            </p>
          </div>
          <div>
            {singleAdvertisment?.management_approved === 0 ? (
              <Switch.Group as="div">
                <span>
                  <Switch.Description
                    as="p"
                    className="text-sm mb-2 text-[#9CA3AF] dark:text-[#BCBCBC]"
                  >
                    Approve Advertisment
                  </Switch.Description>
                </span>
                <Switch
                  checked={approved}
                  onChange={(e) => {
                    setApproved(e);
                    handleApprove(e);
                  }}
                  className={classNames(
                    approved ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      approved ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            ) : (
              <Switch.Group as="div">
                <span>
                  <Switch.Description
                    as="p"
                    className="text-sm mb-2 text-[#9CA3AF] dark:text-[#BCBCBC]"
                  >
                    Unapprove Advertisement
                  </Switch.Description>
                </span>
                <Switch
                  checked={unApproved}
                  onChange={(e) => {
                    handleUnApprove(e);
                    setUnApproved(e);
                  }}
                  className={classNames(
                    unApproved ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      unApproved ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
