import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { storeANotifier } from "../../../../redux/notifier/notifierAction";

export default function CreateBroadcastSms() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    type: "sms",
    subject: "",
    title: "",
    message: "",
    dispatch_on: "",
    notifier_group_id: '',
  });
  const { subject, title, message, dispatch_on, notifier_group_id } = data;
  const { allNotifierGroup, filtering, loading } = useSelector(
    (state) => state.notifierGroup
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (title && subject && dispatch_on && notifier_group_id) {
      const response = await dispatch(storeANotifier(data));
      if (response?.status === "success") {
        navigate("/admin/settings/broadcast-notification/sms/1");
      }
    }
  };

  return (
    <div>
      <div className="pt-4 sm:pt-2 sm:flex items-center mx-auto">
        <div className=" max-w-4xl mx-auto mt-10">
          <div className="sm:w-[629px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200 dark:border-none bg-white dark:bg-[#121212]">
            <form
              name="form"
              id="business-form"
              className="pb-4"
              onSubmit={handleSubmit}
            >
              <div className="border-b-2 py-4 mb-6">
                <p className=" text-base font-medium text-gray-800 dark:text-white flex justify-center ">
                  Create Sms
                </p>
              </div>
              <div className="sm:px-10">
                <div
                  className={
                    "mt-4" + (submitted && title ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Title"}
                    htmlFor={"title"}
                    value={title}
                    type={"text"}
                    name={"title"}
                    onChange={handleChange}
                    placeholder={"Enter notification title"}
                  />
                  {submitted && !title && (
                    <div className="text-xs text-red-500">
                      Title is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "mt-4" + (submitted && subject ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Sms Subject"}
                    htmlFor={"subject"}
                    value={subject}
                    type={"text"}
                    name={"subject"}
                    onChange={handleChange}
                    placeholder={"Enter subject"}
                  />
                  {submitted && !subject && (
                    <div className="text-xs text-red-500">
                      Sms Subject is required
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="notifier_group_id"
                    className="block text-sm font-medium text-[#151515] dark:text-white"
                  >
                    Recipients
                  </label>
                  <select
                    name="notifier_group_id"
                    onChange={handleChange}
                    className="mt-2 block w-full pl-3 pr-10 text-base bg-white dark:bg-[#121212] focus:outline-none focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  >
                    {filtering ? (
                      <option>Loading...</option>
                    ) : (
                      <option>Select Group</option>
                    )}

                    {allNotifierGroup?.length > 0 &&
                      allNotifierGroup?.map((group, index) => (
                        <option key={index} value={group.id}>
                          {group.title}
                        </option>
                      ))}
                  </select>
                  {submitted && !notifier_group_id && (
                    <div className="text-xs text-red-500">
                      Recipient is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "mt-4" + (submitted && dispatch_on ? " border-red-500" : "")
                  }
                >
                  <Input.DatePicker
                    title={"Schedule Send Date & Time"}
                    name={"dispatch_on"}
                    onChange={handleChange}
                  />
                  {submitted && !dispatch_on && (
                    <div className="text-xs text-red-500">Date is required</div>
                  )}
                </div>

                <div
                  className={
                    "mt-4" + (submitted && message ? " border-red-500" : "")
                  }
                >
                  <Input.TextArea
                    title={"Message"}
                    htmlFor={"message"}
                    value={message}
                    type={"text"}
                    name={"message"}
                    placeholder={"Enter notification message"}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
          </div>
          <Button.Primary
            title={loading ? <Beat /> : "Send Notification"}
            form={"business-form"}
            className={"w-[333px] sm:mt-10 mx-auto py-4 flex justify-center  "}
          />
        </div>
      </div>
    </div>
  );
}
