import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Card from "../../../../components/cards/admin/cards";
import Button from "../../../../components/buttons/buttons";
import Input from "./../../../../components/inputs/inputs";
import { Pagination } from "../../../../components/pagination/pagination";
import TableSkeleton from "../../../../components/skeleton/table";
import { Beat } from "../../../../plugins/spinners.plugin";
import Adjust from "../../../../assets/images/icons/adjust.svg";
import CLOSE from "../../../../assets/images/icons/close.svg";
import {
  getAllStores,
  searchAllStores,
} from "../../../../redux/stores/storeActions";
import { storeArrangments } from "../../../../redux/arrangements/arrangementAction";

export const CheckBox = ({
  setPosition,
  selectId,
  setSelectId,
  store_id,
  position,
  setShowModal,
}) => {
  return (
    <div
      onClick={() => {
        setSelectId(store_id);
        setShowModal(true);
        setPosition(position);
      }}
      className={` w-4 h-4 text-primary bg-gray-100 rounded border
       ${
         selectId === store_id
           ? "border-primary bg-primary"
           : "border-gray-300 dark:border-[#A1A1AA] dark:bg-[#0D0D0D]"
       }`}
    />
  );
};

export default function StoreArrangement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();

  const [input, setInput] = useState({
    search: "",
  });
  const [data, setData] = useState({ position: "" });
  const [selectId, setSelectId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [position, setPosition] = useState(false);

  const { arranging } = useSelector((state) => state.arrangement);
  const { allStores, search } = useSelector((state) => state.store);
  const { isLoading, searching } = useSelector((state) => state.store);
  const [stores, setStores] = useState(allStores?.data);
  const [active_tab, setActive_tab] = useState("all-stores");
  useEffect(() => {
    if (!page) {
      navigate(`/admin/settings/arrangement/store/1`);
    }
  }, [page, navigate]);

  useEffect(() => {
    dispatch(getAllStores(page));
    // eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    setStores(allStores.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  
  const showSearch = () => {
    if (input?.search?.length > 2) {
      setActive_tab("searched");
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      setActive_tab("all-stores");
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchAllStores(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(stores);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setStores(items);
  };
  const handlePosition = () => {
    const lists = {};
    search?.map((list) => {
      lists[list.id] = parseInt(data);
      return list;
    });
    dispatch(storeArrangments({ list: lists }));
    removeSearch();
  };

  const handleSave = async () => {
    // if the page = 1, position number will start from 0,
    // else position number needs to add 1 to account for zero indexing
    const position_number = () => {
      if (page > 1) {
        let last_item = Number((page - 1) * 24);
        return last_item + 1;
      } else {
        return Number((page - 1) * 24);
      }
    };
    const lists = stores.reduce((acc, list, i) => {
      acc[list.id] = i + position_number();
      return acc;
    }, {});

    try {
      const response = await dispatch(storeArrangments({ list: lists }));
      if (response) {
        dispatch(getAllStores(page));
      }
    } catch (error) {}
  };

  return (
    <div className="relative">
      <div>
        <Card.TableFilter
          results={allStores?.to}
          totalResults={allStores?.total}
          onChange={handleChange}
          title={"Stores Arrangement"}
          searchPlaceholder="Store Name, Status or Phone Number"
        >
          {active_tab === "all-stores" && (
            <Button.Primary
              disabled={!data || arranging}
              title={arranging ? <Beat /> : "Save"}
              className=" w-20"
              onClick={handleSave}
            />
          )}
        </Card.TableFilter>

        <div className="border-t border-[#E4E4F3] dark:border-[#505050]" />
        {active_tab === "all-stores" && (
          <div className="bg-white dark:bg-[#121212] rounded-md py-4">
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <table className="sm:w-full sm:inline-table text-left text-[#151515] dark:text-white">
                  <thead className="text-sm font-bold border-b-[1px] dark:border-none">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        S/N
                      </th>
                      <th scope="col" className="py-3 px-6"></th>
                      <th scope="col" className="py-3 px-6">
                        Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Position
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Created On
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Phone Number
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Adjust
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId="store">
                    {(provided) => (
                      <tbody
                        className="store text-sm"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {stores?.map((store, index) => (
                          <Draggable
                            draggableId={store?.store_name}
                            index={index}
                            key={store.id}
                          >
                            {(provided) => (
                              <tr
                                className="bg-white dark:bg-[#121212] border-b dark:border-none text-sm cursor-pointer"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <td className="py-4 px-6">{index + 1}</td>
                                <td className="p-4 w-4">
                                  <div className="flex items-center">
                                    <CheckBox
                                      setPosition={setPosition}
                                      setShowModal={setShowModal}
                                      selectId={selectId}
                                      setSelectId={setSelectId}
                                      position={store?.position}
                                      store_id={store?.id}
                                    />
                                    {/* <input
                                      id="checkbox-table-search-1"
                                      type="checkbox"
                                      className="w-4 h-4 text-primary bg-gray-100 rounded border-gray-300"
                                    />
                                    <label
                                      htmlFor="checkbox-table-search-1"
                                      className="sr-only"
                                    >
                                      checkbox
                                    </label> */}
                                  </div>
                                </td>
                                <td className="py-4 px-6">
                                  {store?.store_name}
                                </td>
                                <td className="py-4 px-6">{store?.position}</td>
                                <td className="py-4 px-6">
                                  {moment(store?.created_at).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>
                                <td className="py-4 px-6">
                                  {store?.store_phone}
                                </td>
                                <td className="py-4 px-6">
                                  {store?.business_approved === true ? (
                                    <span className="text-[#00D220]">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="text-primary">
                                      Inactive
                                    </span>
                                  )}
                                </td>

                                <td
                                  className="py-4 px-6"
                                  {...provided.dragHandleProps}
                                >
                                  {
                                    <img
                                      className="cursor-pointer"
                                      src={Adjust}
                                      alt="ratings"
                                    ></img>
                                  }
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            )}

            <Pagination
              data={allStores}
              route={"admin/settings/arrangement/store"}
            />
          </div>
        )}

        {active_tab === "searched" && (
          <div className="bg-white dark:bg-[#121212] rounded-md py-4">
            {searching ? (
              <TableSkeleton />
            ) : (
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <table className="w-full text-sm text-left text-[#151515]">
                  <thead className=" text-sm font-bold">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        S/N
                      </th>
                      <th scope="col" className="py-3 px-6"></th>
                      <th scope="col" className="py-3 px-6">
                        Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Created On
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Phone Number
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId="store">
                    {(provided) => (
                      <tbody
                        className="store text-sm"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {search?.map((store, index) => (
                          <Draggable
                            draggableId={store?.store_name}
                            index={index}
                            key={store.id}
                          >
                            {(provided) => (
                              <tr
                                className="bg-white dark:bg-[#121212] border-b"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <td className="py-4 px-6">{index + 1}</td>
                                <td className="p-4 w-4">
                                  <div className="flex items-center">
                                    <CheckBox
                                      setPosition={setPosition}
                                      setShowModal={setShowModal}
                                      selectId={selectId}
                                      setSelectId={setSelectId}
                                      position={store?.position}
                                      store_id={store?.store_id}
                                    />
                                    {/* <input
                                      id="checkbox-table-search-1"
                                      type="checkbox"
                                      checked={isChecked}
                                      onChange={() => handleOnChange(store.id)}
                                      className="w-4 h-4 text-primary bg-gray-100 rounded border-gray-300"
                                    />
                                    <label
                                      htmlFor="checkbox-table-search-1"
                                      className="sr-only"
                                    >
                                      checkbox
                                    </label> */}
                                  </div>
                                </td>
                                <td className="py-4 px-6">
                                  {store?.store_name}
                                </td>
                                <td className="py-4 px-6">
                                  {moment(store?.created_at).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>
                                <td className="py-4 px-6">
                                  {store?.store_phone}
                                </td>
                                <td className="py-4 px-6">
                                  {store?.business_approved === true ? (
                                    <span className="text-[#00D220] font-bold">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="text-primary font-bold">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            )}

            <Pagination
              data={allStores}
              route={"/admin/settings/arrangement/store"}
            />
          </div>
        )}
      </div>

      {showModal && (
        <div className="absolute top-0 bottom-0 h-full w-full bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="relative bg-white dark:bg-[#121212] mt-20 mx-auto w-[990px] rounded-md py-5 px-8 flex justify-around">
            <button
              type="button"
              className="text-black block absolute top-5 right-5"
              onClick={() => {
                setShowModal(false);
                setSelectId("");
              }}
            >
              <span className="sr-only">Close</span>
              <img src={CLOSE} alt="close" className=" w-3 h-3" />
            </button>

            <Input.Label
              title={"Current Position"}
              className={"w-80"}
              defaultValue={position}
              disabled
            />

            <Input.Number
              title={"New Position"}
              htmlFor="position"
              name="position"
              onChange={(e) => setData(e.target.value)}
              className={"w-80"}
            />
            <Button.Primary
              title={arranging ? <Beat /> : "Change Position"}
              disabled={arranging}
              className={"h-11 mt-6"}
              onClick={handlePosition}
            />
          </div>
        </div>
      )}
    </div>
  );
}
