import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { restaurantService } from "../../services/restaurant.service";
import restaurantType from "./restaurantTypes";

/**
 * Get all restaurants
 **/

export const getAllRestaurantsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: true });
    const response = await restaurantService.getAllRestaurants(page);
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    dispatch({ type: restaurantType["ALL_RESTAURANTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all restaurants
 **/

export const getAllRestaurantsNoPagination = (payload) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["DOWNLOADING"], payLoad: true });
    const response = await restaurantService.getAllRestaurantsNoPagination(
      payload
    );
    dispatch({ type: restaurantType["DOWNLOADING"], payLoad: false });
    dispatch({
      type: restaurantType["ALL_RESTAURANTS_NO_PAGINATION"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a restaurants
 **/

export const getARestaurantAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: true });
    const response = await restaurantService.getARestaurant(id);
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    dispatch({ type: restaurantType["SINGLE_RESTAURANT"], payLoad: response });
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Invite a restaurant
 **/

export const inviteRestaurantAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: true });
    const response = await restaurantService.inviteRestaurant(id);
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    dispatch({ type: restaurantType["SINGLE_RESTAURANT"], payLoad: response });
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get my restaurant
 **/

export const getMyRestaurantsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: true });
    const response = await restaurantService.getMyRestaurants(id);
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    dispatch({ type: restaurantType["MY_RESTAURANTS"], payLoad: response });
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search restaurants
 **/

export const searchRestaurantsAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: true });
    const response = await restaurantService.searchRestaurants(params);
    dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter restaurants
 **/

export const filterRestaurantsAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["FILTERING"], payLoad: true });
    const response = await restaurantService.filterRestaurants(payload, page);
    dispatch({ type: restaurantType["FILTERING"], payLoad: false });
    dispatch({ type: restaurantType["FILTER_RESTAURANTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve restaurants by self
 **/

export const approveRestaurantBySelfAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["APPROVING"], payLoad: true });
    const response = await restaurantService.approveRestaurantBySelf(id);
    dispatch({ type: restaurantType["APPROVING"], payLoad: false });
    dispatch({
      type: restaurantType["APPROVE_RESTAURANT_BUSINESS"],
      payLoad: false,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: restaurantType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Unapprove restaurants by self
 **/

export const unapproveRestaurantBySelfAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["APPROVING"], payLoad: true });
    const response = await restaurantService.unapproveRestaurantBySelf(id);
    dispatch({ type: restaurantType["APPROVING"], payLoad: false });
    dispatch({
      type: restaurantType["UNAPPROVE_RESTAURANT_BUSINESS"],
      payLoad: false,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: restaurantType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve restaurants by mgt
 **/

export const approveRestaurantByMgtAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["APPROVING"], payLoad: true });
    const response = await restaurantService.approveRestaurantByMgt(id);
    dispatch({ type: restaurantType["APPROVING"], payLoad: false });
    dispatch({
      type: restaurantType["APPROVE_RESTAURANT_MANAGEMENT"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Unapprove restaurants by mgt
 **/

export const unapproveRestaurantByMgtAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["APPROVING"], payLoad: true });
    const response = await restaurantService.unapproveRestaurantByMgt(id);
    dispatch({ type: restaurantType["APPROVING"], payLoad: false });
    dispatch({
      type: restaurantType["UNAPPROVE_RESTAURANT_MANAGEMENT"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Create a restaurant
 **/

export const createRestaurantAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: true });
    const response = await restaurantService.createRestaurant(payLoad);
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_RESTAURANT"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Update a restaurant
 **/

export const upDateRestaurantAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["UPDATING"], payLoad: true });
    const response = await restaurantService.upDateRestaurant({ ...payLoad });
    dispatch({ type: restaurantType["UPDATING"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};
