import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getACatalogue,
  approveACatalogue,
  unApproveACatalogue,
} from "../../../redux/catalogue/catalogueAction";
import Copy from "../../../assets/images/icons/copy-outline.svg";
import { useParams } from "react-router-dom";
import { Switch } from "@headlessui/react";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "../../../components/tables/tableCols2";
import Catalogue from "../../../assets/images/icons/catalogueEmpty.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function SingleCatalogue() {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { catalogue } = useSelector((state) => state.catalogue);
  const { id } = useParams();
  const [enabled, setEnabled] = useState();

  const handleUnApprove = (e) => {
    dispatch(unApproveACatalogue({ id: id }));
  };
  const handleApprove = (e) => {
    dispatch(approveACatalogue({ id: id }));
  };
  useEffect(() => {
    dispatch(getACatalogue(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (catalogue?.id) {
      setEnabled(catalogue?.management_approved);
    }

    // eslint-disable-next-line
  }, [catalogue?.id]);

  const result = [
    catalogue?.foods,
    catalogue?.merchandise,
    catalogue?.stores,
    catalogue?.restaurants,
    catalogue?.packages,
  ];
  let newResult = result?.filter(function (e) {
    return e?.length;
  });

  const newArray = Array.prototype.concat.apply([], newResult);

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast.success("Copied", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((catalogue, index) => {
        let single = {
          id: (
            <div className="flex">
              {catalogue.id}
              <img
                className="ml-2"
                src={Copy}
                alt="copy-icon"
                onClick={() => copyTextToClipboard(catalogue.id)}
              />
            </div>
          ),
          name:
            catalogue?.store_name ||
            catalogue?.food_name ||
            catalogue?.restaurant_name ||
            catalogue?.merchandise_name ||
            catalogue?.package_name,
          status:
            catalogue.management_approved === true ? (
              <span className="text-green-400 font-bold">Approved</span>
            ) : (
              <span className="text-primary font-bold">Unapproved</span>
            ),
          created: (
            <span>{moment(catalogue?.created_at).format("MMM DD, YYYY")}</span>
          ),
          catalogue: catalogue,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }
  useEffect(() => {
    const dataSet = createTableData(newArray);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogue]);
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "single.id",
          },
          {
            Header: "Name",
            accessor: "single.name",
          },
          {
            Header: "Status",
            accessor: "single.status",
          },
          {
            Header: "Created On",
            accessor: "single.created",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium capitalize">Catalogue Details</h3>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Title
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {catalogue?.catalogue_title}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Created On
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {moment(catalogue?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              {catalogue?.management_approved ? "Unapprove" : "Approve"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={enabled}
                onChange={(e) => {
                  setEnabled(e);
                  catalogue?.management_approved
                    ? handleUnApprove()
                    : handleApprove();
                }}
                className={classNames(
                  enabled ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
      </div>
      {/* <CatalogueTable catalogue={catalogue} /> */}
      <div>
        <div className="bg-white dark:bg-[#121212] border-b border-[#E4E4F3] py-4 px-6 mb-1">
          <p>Catalogue Contents</p>
        </div>

        <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-8">
          {data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={() => {}}
            />
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                <img src={Catalogue} alt="orders" />
              </div>
              <p className="font-bold text-xl my-3">Catalogues</p>
              <p className="text-sm text-gray-500">
                You do not have catalogues
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
