import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../inputs/inputs";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as Close } from "../../assets/images/icons/close.svg";
import { Beat } from "../../plugins/spinners.plugin";
import { isAuthenticatedUser } from "../../utils/Auth";
import { claimARewardAction } from "../../redux/reward/rewardActions";

export function ClaimRewardModal(props) {
  const { modalObj } = props;
  const dispatch = useDispatch();
  const isAuth = isAuthenticatedUser();

  const [input, setInput] = useState();

  const { isLoading } = useSelector((state) => state.reward);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };
  console.log("input", input);

  const handleSubmit = async (e) => {
    if (isAuth && modalObj?.id) {
      const response = await dispatch(
        claimARewardAction({ id: modalObj?.id, input })
      );
      if (response) {
        props.onClose();
      }
    }
  };

  return (
    <div data-test="singleFoodModal">
      <Transition show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100 w-full flex justify-center"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative sm:w-[630px] bg-white dark:bg-[#121212] pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg w-full">
                  <div className="sm:block absolute top-3 right-4 sm:pt-2 sm:pr-3">
                    <button
                      type="button"
                      className="bg-[#ffeae5] dark:bg-[#CE2600] rounded-md p-1 sm:p-3 outline-none"
                      onClick={props.onClose}
                    >
                      <span className="sr-only">Close</span>
                      <Close className="w-3 h-3 white-icon" />
                    </button>
                  </div>
                  <div className="px-4 sm:pb-8 sm:pt-3 sm:px-8">
                    <div className="text-left py-4 mb-10">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold text-black dark:text-white"
                      >
                        Reward Claim
                      </Dialog.Title>
                    </div>

                    <div className="text-center">
                      <p className="text-xl mb-5">🎉 Congratulations! 🎉</p>
                      <p className="text-lg mb-4">
                        You’ve just hit {modalObj?.description} 💪 As a reward
                        for your consistency,{" "}
                        <span className="text-[#00D220]">
                          {numberWithCommas(modalObj?.points)} points
                        </span>{" "}
                        have been added to your total reward balance.
                      </p>
                      <p className="text-lg mb-10">
                        Keep the streak alive for even bigger rewards! 🌟 What’s
                        next on your list? Let’s make it happen! 🚀
                      </p>

                      {modalObj?.input_fields &&
                        modalObj?.input_fields?.length > 0 &&
                        modalObj?.input_fields?.map((input, index) => (
                          <div className="text-left" key={index}>
                            {input?.type === "text" && (
                              <Input.Label
                                title={input?.title}
                                type={input?.type}
                                name={input?.name}
                                placeholder={`Enter ${input?.title}`}
                                onChange={handleChange}
                              />
                            )}
                          </div>
                        ))}

                      <button
                        type="button"
                        className="sm:mb-6 w-full inline-flex justify-center h-14 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
                        onClick={handleSubmit}
                      >
                        {isLoading ? (
                          <Beat color={"#ffffff"} />
                        ) : (
                          "Claim Reward Points"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
