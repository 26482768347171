import React from "react";
import CLOSE from "../../../../assets/images/icons/close.svg";
import Logo from "../../../../assets/images/icons/ogwugo-logo.svg";
import WhiteLogo from "../../../../assets/images/icons/ogwugo-white-logo.svg";
import { Dialog } from "@headlessui/react";

export default function EmailVerification({ formWizardRef, props }) {
  return (
    <Dialog.Panel className="relative sm:w-[600px] bg-white dark:bg-darkBg rounded-3xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8  w-full sm:p-8">
      <div className=" sm:block absolute top-2 right-2 pt-4 pr-4">
        <button
          type="button"
          className="bg-[#ffeae5] rounded-lg  p-2 sm:p-3 "
          onClick={props.onClose}
        >
          <span className="sr-only">Close</span>
          <img src={CLOSE} alt="close" className=" w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-16 md:mt-10 sm:mt-10 sm:ml-4 text-left">
          <Dialog.Title
            as="h3"
            className="text-xl font-bold text-black dark:text-white"
          >
            Email Verification
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm font-normal text-black dark:text-[#BCBCBC]">
              Verify Your Email for Ogwugo Account
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-8 sm:ml-4">
        <p className="font-normal text-black dark:text-[#BCBCBC] mb-4">
          Thank you for signing up on Ogwugo!
        </p>
        <p className="font-normal text-black dark:text-[#BCBCBC] mb-4">
          To complete your registration and secure your account, please verify
          your email.
        </p>

        <ol className="my-6">
          <li className="font-normal text-black dark:text-white mb-4">
            Step 1: Open your email inbox.
          </li>
          <li className="font-normal text-black dark:text-white mb-4">
            Step 2: Find the verification email from Ogwugo.
          </li>
          <li className="font-normal text-black dark:text-white mb-4">
            Step 3: Click the verification link inside the email to confirm your
            email address.
          </li>
        </ol>

        <p className="text-[#003FC1] dark:text-primary italic text-base mb-5">
          If you don’t see the email, check your spam or junk folder. If you
          need help, feel free to contact our support team.
        </p>

        <p className="font-normal text-black dark:text-[#BCBCBC] mb-5">
          Thank you for choosing Ogwugo!
        </p>

        <p className="font-normal text-black dark:text-white mb-8">
          - The Ogwugo Team
        </p>

        <div className="flex justify-center mt-10 py-4">
          <img src={Logo} alt="logo" className="max-w-[100px] dark:hidden" />
          <img src={WhiteLogo} alt="logo" className="max-w-[100px] hidden dark:block" />
        </div>
      </div>
    </Dialog.Panel>
  );
}
