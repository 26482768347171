import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
import { XIcon } from "@heroicons/react/outline";
import { Beat } from "../../../plugins/spinners.plugin";
import { history } from "../../../helpers/history";
import { cleanInput } from "../../../helpers/cleanInput";
import { ReactComponent as Upload } from "../../../assets/images/icons/upload.svg";
import restaurantType from "../../../redux/restaurant/restaurantTypes";
import { storeARewardAction } from "../../../redux/reward/rewardActions";

export default function CreateReward() {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const { isLoading } = useSelector((state) => state.reward);
  const [submitted, setSubmitted] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);

  const [input, setInput] = useState({
    contractor: "",
    title: "",
    url: "",
    points: "",
    procedure: "",
    configuration: {
      count: 0,
      validity: "",
    },
    description: "",
    base64_photos: "",
    input_fields: [],
  });
  const {
    contractor,
    url,
    title,
    points,
    description,
    procedure,
    input_fields,
  } = input;

  useEffect(() => {
    dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: [] });
    // eslint-disable-next-line
  }, []);

  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const addFormField3 = () => {
    setInput({
      ...input,
      input_fields: [
        ...input.input_fields,
        {
          title: "",
          name: "",
          type: "",
          required: 1,
        },
      ],
    });
  };

  const handleDynamicChange = (e, index) => {
    const { name, value } = e.target;
    let oldBaseBydelivery = [...input_fields];
    oldBaseBydelivery[index][name] = value;

    setInput({
      ...input,
      input_fields: oldBaseBydelivery,
    });
  };

  const removeFile2 = (i) => {
    let newArr = input_fields.filter((item, index) => index !== i);
    setInput({
      ...input,
      input_fields: newArr,
    });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    let newInput = {
      ...input,
      base64_photos: image64 ? image64 : [],
    };
    cleanInput(newInput);
    if (points && description && description.length > 10 && title) {
      try {
        const response = await dispatch(storeARewardAction({ ...newInput }));
        if (response?.status === "success") {
          history(`/admin/rewards/unapproved/1`);
          setInput({
            contractor: "",
            title: "",
            url: "",
            points: "",
            procedure: "",
            configuration: {
              count: 0,
              validity: "",
            },
            description: "",
            base64_photos: "",
            input_fields: [],
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div data-test="createFood-page">
      {/* <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">
          Food / Create Food
        </h1>
      </div> */}
      <form>
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Create Reward</h3>
          </div>

          <div className="mt-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div>
                <Input.Label
                  title={"Contractor"}
                  type="text"
                  name="contractor"
                  id="contractor"
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="Enter contractor name"
                  onChange={handleChange}
                  subText={"This module defines the type of reward."}
                />
                {submitted && !contractor && (
                  <div className="text-xs text-red-500">
                    Contractor is required
                  </div>
                )}
              </div>
              <Input.Label
                title={"Reward Description "}
                type="text"
                name="description"
                id="description"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Describe reward"
                onChange={handleChange}
                subText="A brief explanation of the reward, displayed to users."
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div>
                <Input.Label
                  title={"Title"}
                  type="text"
                  name="title"
                  id="title"
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="Enter title"
                  onChange={handleChange}
                  subText="A display name for the reward that users will see."
                />
                {submitted && !title && (
                  <div className="text-xs text-red-500">Title is required</div>
                )}
              </div>

              <div>
                <Input.Label
                  title={"URL"}
                  type="text"
                  name="url"
                  id="url"
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="Enter url"
                  onChange={handleChange}
                  subText="The URL users will be directed to for completing reward tasks."
                />
                {submitted && !url && (
                  <div className="text-xs text-red-500">Url is required</div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div>
                <Input.Number
                  title={"Points"}
                  type="number"
                  name="points"
                  id="points"
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="Enter points"
                  onChange={handleChange}
                  subText="The points awarded to the user upon completing the reward task."
                />
                {submitted && !points && (
                  <div className="text-xs text-red-500">Points is required</div>
                )}
              </div>

              <div>
                <Input.Select
                  title={"Procedure"}
                  htmlFor={"procedure"}
                  name={"procedure"}
                  value={procedure}
                  onChange={handleChange}
                  subText="The method of distributing reward points upon completion."
                >
                  <option>Select Procedure Type</option>
                  <option value={"instant"}>Instant</option>
                  <option value={"delayed"}>Delayed</option>
                  <option value={"manual"}>Manual</option>
                </Input.Select>
                {submitted && !procedure && (
                  <div className="text-xs text-red-500">
                    Procedure is required
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <Input.Number
                title={"Configuration Count"}
                type="number"
                name="count"
                id="count"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Configuration Count"
                subText={
                  "The required number of times the task must be performed for completion."
                }
                onChange={(e) =>
                  setInput({
                    ...input,
                    configuration: {
                      ...input.configuration,
                      count: e.target.value,
                    },
                  })
                }
              />
              <Input.DatePicker
                title={"Configuration Validity"}
                name="validity"
                id="validity"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                onChange={(e) =>
                  setInput({
                    ...input,
                    configuration: {
                      ...input.configuration,
                      validity: e.target.value,
                    },
                  })
                }
                subText="The timeframe during which the reward task is valid and can be completed."
              />
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div>
            <div className="border border-gray-300 py-2 px-2 mb-6">
              <h3 className="block text-lg  font-bold text-[#151515] dark:text-white">
                User Required Input :
              </h3>
              <p className="text-[#4D4D4DB2] dark:text-[#BCBCBC] text-sm">
                For rewards validation and claim (optional)
              </p>
            </div>
            {input_fields?.length > 0 &&
              input_fields?.map((element, index) => (
                <div key={index}>
                  <div className="grid grid-cols-2 gap-2">
                    <Input.Label
                      title={"Title"}
                      htmlFor={"title"}
                      name={"title"}
                      placeholder={"Enter title"}
                      defaultValue={element.title || ""}
                      onChange={(e) => handleDynamicChange(e, index)}
                    />
                    <Input.Label
                      title={"Name"}
                      htmlFor={"name"}
                      name={"name"}
                      placeholder={"Enter name"}
                      defaultValue={element.name || ""}
                      onChange={(e) => handleDynamicChange(e, index)}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <Input.Select
                        title={"Type"}
                        htmlFor={"type"}
                        name={"type"}
                        defaultValue={element.type || ""}
                        onChange={(e) => handleDynamicChange(e, index)}
                      >
                        <option>Select Type</option>
                        <option value={"text"}>Text</option>
                        <option value={"textarea"}>Textarea</option>
                        <option value={"select"}>Select</option>
                        <option value={"radio"}>Radio</option>
                        <option value={"checkbox"}>Checkbox</option>
                      </Input.Select>
                      {submitted && !input_fields && (
                        <div className="text-primary text-xs ">
                          Type is required
                        </div>
                      )}
                    </div>
                    <div>
                      <Input.Select
                        title={"Required"}
                        htmlFor={"required"}
                        name={"required"}
                        placeholder={"NGN 500"}
                        defaultValue={element.required || ""}
                        onChange={(e) => handleDynamicChange(e, index)}
                      >
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </Input.Select>
                      {submitted && !input_fields && (
                        <div className="text-primary text-xs ">
                          This is required
                        </div>
                      )}
                    </div>
                  </div>

                  {input_fields?.length > 1 && (
                    <div className="w-full text-right">
                      <button
                        className="text-primary text-xs font-medium"
                        type="button"
                        onClick={() => removeFile2(index)}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              ))}
            <div>
              <p
                className="text-primary text-xs text-right font-medium mb-4 cursor-pointer"
                onClick={() => addFormField3()}
              >
                Add more +
              </p>
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Food Images (200px by 200px)
              </h3>
              <p className="text-sm">
                Accepts .jpg, .png and .jpeg image formats
              </p>
            </div>
          </div>
          {imageUrls?.length === 0 ? (
            <div className="bg-[#FFFBFA] dark:bg-[#121212] text-center border border-dashed border-[#E4E4F3] rounded-[10px] mt-4 p-5 h-[130px]">
              <button
                className="text-primary mx-auto"
                onClick={handleFileClick}
              >
                <Upload className="h-[56px] w-[56px]" />
              </button>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                multiple
                onChange={onImageChange}
                ref={hiddenFileInput}
              />
              <p className="text-[#676565] text-lg text-center mt-1">
                Click here to select a photo
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
              {imageUrls?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="food"
                  />
                  <button
                    type="button"
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeFile(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))}
            </div>
          )}
        </div>

        <div className="my-6 text-center">
          <Button.Primary
            title={isLoading ? <Beat color={"#ffffff"} /> : "Create Reward"}
            onClick={handleSubmit}
            className="px-5"
            type={"button"}
            disabled={isLoading}
          />
        </div>
      </form>
    </div>
  );
}
