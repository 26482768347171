import React, { useState, useEffect } from "react";
import { getUserFromCookies, isAuthenticatedUser } from "../../../utils/Auth";
//components
import Container from "../../../components/container/container";
import Collapse from "../../../components/cards/faqCollapse";
//images and icons
import Banner from "../../../assets/images/pictures/help-banner.jpg";
import BannerDark from "../../../assets/images/pictures/help-banner-dark.png";

// const tabs = [
//   { name: "GENERAL QUESTIONS", href: "status" },
//   { name: "OTHER QUESTIONS", href: "details" },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

const videos = [
  {
    video_id: "1GCoQkWXzKdNnrRrdU0hh2ZF0vU1fzg_k",
    title: "How to register on Ogwugo",
    time: "0:57",
  },
  {
    video_id: "1vsHd3YDI40gXXKjXKHP1w0s80bAU97eT",
    title: "How to create a business on Ogwugo ",
    time: "1:59",
  },
  {
    video_id: "1xuUCfHqbXJL97m-_fMCXA4ckuQSes66J",
    title: "How to create a merchandise",
    time: "1:22",
  },
  {
    video_id: "14gxZn6uOz9upDgqdOHrTE5CcrcSYZK7W",
    title: "How to create a manage your store settings",
    time: "1:28",
  },
];

export default function Help() {
  // const [active_tab, setActive_tab] = useState(false);
  // const [active_tab, setActive_tab] = useState("status");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const user = getUserFromCookies();
  const isAuth = isAuthenticatedUser();

  useEffect(() => {
    if (document.documentElement.classList.contains("dark")) {
      setIsDarkMode(true);
    }
  }, []);

  // const toggle = (tab) => {
  //   setActive_tab(tab.href);
  // };
  return (
    <div data-test="help-page">
      <div
        className="min-h-96 bg-cover no-repeat bg-center relative z-0"
        style={{
          backgroundImage: `url('${isDarkMode ? BannerDark : Banner}')`,
        }}
      >
        <Container>
          <div className="py-16 max-w-lg px-6 mx-auto">
            <h2
              className="text-2xl text-center font-medium mb-5 mt-10"
              style={{ lineHeight: "32px" }}
            >
              Hi {isAuth ? user?.name : ""}, you’ve got questions? We have
              answers
            </h2>
          </div>
        </Container>
      </div>

      <Container>
        <main className="py-8">
          <div className="max-w-3xl mx-auto lg:max-w-7xl">
            <h1 className="sr-only">Help Page</h1>

            <section
              aria-labelledby="video-tutorial-section"
              className="mt-5 mb-16"
            >
              <h3 className="text-xl font-bold mb-5">Quick Start Tutorial</h3>
              <div className="grid sm:grid-cols-3 gap-5 sm:gap-5">
                {videos?.length > 0 &&
                  videos.map((video, index) => (
                    <div key={index}>
                      <div
                       
                        style={{ position: "relative", paddingTop: "56.25%" }}
                      >
                        <iframe
                          src={`https://drive.google.com/file/d/${video?.video_id}/preview`}
                          width="100%"
                          height="100%"
                          style={{ position: "absolute", top: 0, left: 0 }}
                          allow="autoplay"
                          allowFullScreen
                          title={video?.title}
                        />
                      </div>
                      <div className="mt-3">
                        <p>{video?.title}</p>
                        <p>{video?.time} secs</p>
                      </div>
                    </div>
                  ))}
              </div>
            </section>

            <section aria-labelledby="section-1-title">
              {/* Your content */}
              {/* <div>
                <div className="block">
                  <div className="border-b border-gray-200 dark:border-none dark:bg-[#121212] dark:px-4 dark:py-4">
                    <nav
                      className="-mb-px flex space-x-8 overflow-x-auto"
                      aria-label="Tabs"
                    >
                      {tabs.map((tab) => (
                        <button
                          key={tab.name}
                          onClick={() => {
                            toggle(tab);
                          }}
                          className={classNames(
                            tab.href === active_tab
                              ? "border-primary text-primary font-bold"
                              : "border-transparent text-[#BCBCBC] hover:text-gray-700 hover:border-gray-300 dark:hover:text-primary",
                            "whitespace-nowrap py-4 px-2 border-b-2 dark:border-2 font-medium text-sm rounded-md"
                          )}
                          aria-current={
                            tab.href === active_tab ? "page" : undefined
                          }
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </div> */}

              <div className="mt-10">
                <div className="shadow-sm border dark:border-[#505050] rounded-md">
                  <div className="border-b border-gray-200 dark:border-[#505050] rounded-md bg-white dark:bg-[#121212] px-4 py-4 sm:px-6">
                    <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div className="ml-4 mt-2">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                          GENERAL QUESTIONS
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 p-6 sm:ml-4">
                    <Collapse>
                      <Collapse.QAItem>
                        <Collapse.Question
                          title={"What is Ogwugo?"}
                          answerId="q1"
                        ></Collapse.Question>
                        <Collapse.Answer id="q1">
                          <p className="mb-4">
                            Ogwugo is a leading online marketplace and delivery
                            platform that connects customers with a wide range
                            of products, including food, groceries, electronics,
                            household items, and more.
                          </p>
                          <p className="mb-4">
                            Our platform offers convenience and reliability by
                            allowing customers to order products from the
                            comfort of their homes and have them delivered to
                            their doorstep.
                          </p>
                        </Collapse.Answer>
                      </Collapse.QAItem>

                      <Collapse.QAItem>
                        <Collapse.Question
                          title={"How do I sign up as a Customer?"}
                          answerId="q2"
                        ></Collapse.Question>
                        <Collapse.Answer id="q2">
                          <p className="mb-4">
                            Signing up as a customer on ogwugo.com is simple and
                            easy.
                          </p>
                          <ul className="list-disc pl-4">
                            <li className="mb-2">
                              Just visit our website and click on the "Sign Up"
                              button.
                            </li>
                            <li className="mb-4">
                              Follow the prompts to provide your details,
                              including your name, email address, and phone
                              number.
                            </li>
                          </ul>
                          <p className="mb-4">
                            Once registered, you can start browsing through our
                            wide selection of products and place orders for
                            delivery.
                          </p>
                        </Collapse.Answer>
                      </Collapse.QAItem>

                      <Collapse.QAItem>
                        <Collapse.Question
                          title={"In what cities do you operate?"}
                          answerId="q3"
                        ></Collapse.Question>
                        <Collapse.Answer id="q3">
                          <p className="mb-4">
                            Currently, food.ogwugo.com serves customers in Enugu
                            city and Nsukka town, along with their surrounding
                            areas. We have ambitions to extend our reach to
                            additional cities in the southeastern states of
                            Nigeria.
                          </p>
                          <p className="mb-4">
                            While our food delivery services are primarily
                            focused on the southeastern region, all other items
                            on our platform are available for shipment
                            worldwide. Our aim is to enhance accessibility to
                            quality products and groceries, ensuring customers
                            across various regions of the world have convenient
                            access to our services in the near future
                          </p>
                        </Collapse.Answer>
                      </Collapse.QAItem>
                    </Collapse>
                  </div>
                </div>
              </div>

              <div className="mt-16">
                <div className="shadow shadow-sm border dark:border-[#505050] rounded-md">
                  <div className="border-b border-gray-200 dark:border-[#505050] rounded-md bg-white dark:bg-[#121212] px-4 py-4 sm:px-6">
                    <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div className="ml-4 mt-2">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                          OTHER QUESTIONS
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 p-6 sm:ml-4">
                    <Collapse>
                      <Collapse.QAItem>
                        <Collapse.Question
                          title={"How can I become a vendor with Ogwugo?"}
                          answerId="q1"
                        >
                          {/* {(isOpen, onToggle) => {
                              return (
                                <>
                                  {isOpen ? "Open " : "Close "}
                                  <span>
                                    Why do you ask such stupid questions?
                                  </span>
                                </>
                              );
                            }} */}
                        </Collapse.Question>
                        <Collapse.Answer id="q1">
                          <p className="mb-4">
                            If you're interested in becoming a vendor with
                            Ogwugo and selling your products on our platform,
                            please visit our website and click on the "Become a
                            Vendor" link. Follow the instructions to fill out
                            the vendor registration form, and our team will
                            review your application. Upon approval, you can
                            start listing your products and reaching out to our
                            customer base.
                          </p>
                        </Collapse.Answer>
                      </Collapse.QAItem>

                      <Collapse.QAItem>
                        <Collapse.Question
                          title={"How can I sign up to be an O-Pilot?"}
                          answerId="q2"
                        ></Collapse.Question>
                        <Collapse.Answer id="q2">
                          <p className="mb-4">
                            To sign up as an O-Pilot, visit our website and
                            navigate to the "O-Pilot" section. Click on the
                            "Sign Up" button and fill out the application form
                            with your details and qualifications. Alternatively,
                            if you're interested in joining as a third-party
                            delivery agent, please send us an email at
                            shop.ogwugo@gmail.com or text our customer service
                            representatives for more information.
                          </p>
                        </Collapse.Answer>
                      </Collapse.QAItem>

                      <Collapse.QAItem>
                        <Collapse.Question
                          title={"Do you accept third-party riders?"}
                          answerId="q3"
                        ></Collapse.Question>
                        <Collapse.Answer id="q3">
                          <p className="mb-4">
                            Yes, we accept third-party delivery agents to join
                            our team. If you're interested in becoming a
                            delivery agent, please send us an email at
                            shop.ogwugo@gmail.com or text our customer service
                            representatives for more information.
                          </p>
                        </Collapse.Answer>
                      </Collapse.QAItem>

                      <Collapse.QAItem>
                        <Collapse.Question
                          title={
                            "How many days does it take to process a refund?"
                          }
                          answerId="q3"
                        ></Collapse.Question>
                        <Collapse.Answer id="q3">
                          <p className="mb-4">
                            The processing time for refunds may vary depending
                            on the payment method used and the specific
                            circumstances of the refund request. Typically,
                            refunds are processed within 2 to 4 working days
                            from the date the request is approved. However,
                            please note that it may take additional time for the
                            refunded amount to reflect in your account due to
                            bank processing times.
                          </p>
                        </Collapse.Answer>
                      </Collapse.QAItem>

                      <Collapse.QAItem>
                        <Collapse.Question
                          title={"What items can I sell on Ogwugo?"}
                          answerId="q3"
                        ></Collapse.Question>
                        <Collapse.Answer id="q3">
                          <p className="mb-4">
                            Ogwugo.com accepts a wide range of products for sale
                            on our platform, including food, groceries,
                            electronics, household items, routers, house decors,
                            books, event tickets, car parts, kitchen equipment,
                            and more. If you're unsure whether your products are
                            suitable for listing on our platform, please feel
                            free to reach out to our vendor support team for
                            assistance.
                          </p>
                        </Collapse.Answer>
                      </Collapse.QAItem>
                    </Collapse>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </Container>
    </div>
  );
}
