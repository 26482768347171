import React, { useState, useEffect, useMemo, useRef } from "react";
import Card from "../../../../components/cards/admin/cards";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../../../components/buttons/buttons";
import HomeSlider, { HomeNav } from "../../../../components/slider/HomeSlider";
import AddNewSlider from "../../../../components/modals/admin/addNewSlider";
import Table from "../../../../components/tables/tableCols2";
import { useDispatch, useSelector } from "react-redux";
import {
  filterAdvertisments,
  searchAllAdvertisments,
} from "../../../../redux/advertisment/advertismentAction";
import { Link } from "react-router-dom";
import moment from "moment";
import Settings from "../../../../assets/images/icons/settingsEmpty.svg";
import TableSkeleton from "../../../../components/skeleton/table";
import { Pagination } from "../../../../components/pagination/pagination";
import Copy from "../../../../assets/images/icons/copy-outline.svg";
import ADVERTIMG from "../../../../assets/images/pictures/advertImage.jpg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterSliderDropDown from "../../../../components/dropDown/filter/sliderFilter";

export default function Sliders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const advertRef = useRef();
  const { page } = useParams();

  const [isFilterOpen, setFilterState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { allAdvertisments, isLoading, search, searching } = useSelector(
    (state) => state.advertisment
  );

  const [payLoad] = useState({
    end_date: new Date(),
  });
  const advertismentData = allAdvertisments?.data;
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  useEffect(() => {
    if (!page) {
      navigate(`/admin/settings/sliders/1`);
    }
  }, [page, navigate]);
  useEffect(() => {
    dispatch(filterAdvertisments(payLoad, page));

    //eslint-disable-next-line
  }, [page]);

  //to copy text to clipboard
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast.success("Copied", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 25, separator = "...") => {
    if (string?.length <= lengthToShow) return string;

    const sepLen = separator?.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string?.substr(0, frontChars) +
      separator +
      string?.substr(string.length - backChars)
    );
  };
  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((slider) => {
        let single = {
          created: (
            <span>{moment(slider?.created_at).format("DD/MM/YYYY")}</span>
          ),
          link: (
            <div className="flex ">
              {truncateText(slider.url)}
              <img
                className="ml-2"
                src={Copy}
                alt="copy-icon"
                onClick={() => copyTextToClipboard(slider.url)}
              />
            </div>
          ),
          title: slider?.title,

          status:
            slider?.management_approved === 1 ? (
              <p className="font-bold text-sm text-green-500">Active</p>
            ) : (
              <p className="font-bold text-sm text-primary">Inactive</p>
            ),

          slider: slider,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }
  useEffect(() => {
    const dataSet = createTableData(advertismentData);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAdvertisments]);

  const addImageFallback = (event) => {
    event.currentTarget.src = ADVERTIMG;
  };
  const goToSinglePage = (params) => {
    let id = params?.single?.slider?.id;
    navigate(`/admin/settings/slider/${id}`, {
      state: { slider: params?.single?.slider },
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allAdvertisments?.data);
      setData(dataSet);
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchAllAdvertisments(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Status",
            accessor: "single.status",
          },
          {
            Header: "Date",
            accessor: "single.created",
          },
          {
            Header: "Title",
            accessor: "single.title",
          },
          {
            Header: "Link/URL",
            accessor: "single.link",
          },
        ],
      },
    ],
    []
  );
  return (
    <>
      <AddNewSlider show={isOpen} onClose={closeModal} />

      {advertismentData?.length > 0 && (
        <div className="mb-10 bg-white dark:bg-[#121212] py-10 px-6 rounded-lg relative">
          {advertismentData?.length > 0 && <HomeNav advertRef={advertRef} />}

          <HomeSlider advertRef={advertRef} advertisment={advertismentData}>
            {advertismentData?.map((advert, index) => (
              <Link to={advert?.url}>
                <img
                  key={index}
                  src={advert.pictures}
                  alt="advert-img"
                  onError={addImageFallback}
                  className="w-[538px] h-[336px] rounded-xl"
                />
              </Link>
            ))}
          </HomeSlider>
        </div>
      )}
      <div data-test="stores-page">
        <Card.TableFilter
          title={"Sliders"}
          results={allAdvertisments?.to}
          totalResults={allAdvertisments?.total}
          onChange={handleChange}
          filter={
            <AllFiltersModal
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            >
              <FilterSliderDropDown
                isFilterOpen={isFilterOpen}
                setFilterState={setFilterState}
                payLoad={payLoad}
                page={page}
              />
            </AllFiltersModal>
          }
          searchPlaceholder={"Slider Title"}
        >
          <Button.Primary title={"+ Add new slider"} onClick={openModal} />
        </Card.TableFilter>

        <div className="border-b border-[#E4E4F3] dark:border-[#505050]"></div>

        <div className="bg-white dark:bg-[#121212] rounded-md py-4 ">
          {isLoading || searching ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              onClick={goToSinglePage}
              className="w-full"
              rowClass="hover:shadow-md cursor-pointer"
            />
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                <img src={Settings} alt="settinga" />
              </div>
              <p className="font-bold text-xl my-3">Settings</p>
              <p className="text-sm text-gray-500">
                You do not have any Sliders yet
              </p>
            </div>
          )}
          <Pagination
            data={allAdvertisments}
            route={"admin/settings/sliders"}
          />
        </div>
      </div>
    </>
  );
}
