import React from "react";

export default function RestaurantStats({ userStats, submitted }) {
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div>
      <ul className="mt-6">
        <li className="flex px-3 py-4 mt-1 justify-between border-b dark:border-[#505050]">
          <span className="text-xs text-[#344B67] dark:text-[#BCBCBC]">Total Orders</span>
          <span className="text-sm text-[#4D4D4D] dark:text-white font-bold">
            {submitted ? userStats?.stats?.restaurantOrders?.total_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b dark:border-[#505050]">
          <span className="text-xs text-[#344B67] dark:text-[#BCBCBC]">Unshipped Orders</span>
          <span className="text-sm text-[#4D4D4D] dark:text-white font-bold">
            {submitted
              ? userStats?.stats?.restaurantOrders?.unshipped_orders
              : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b dark:border-[#505050]">
          <span className="text-xs text-[#344B67] dark:text-[#BCBCBC]">Shipped Orders</span>
          <span className="text-sm text-[#4D4D4D] dark:text-white font-bold">
            {submitted ? userStats?.stats?.restaurantOrders?.shipped_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b dark:border-[#505050]">
          <span className="text-xs text-[#344B67] dark:text-[#BCBCBC]">Closed Orders</span>
          <span className="text-sm text-[#4D4D4D] dark:text-white font-bold">
            {submitted ? userStats?.stats?.restaurantOrders?.closed_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b dark:border-[#505050]">
          <span className="text-xs text-[#344B67] dark:text-[#BCBCBC]">Delivered Orders</span>
          <span className="text-sm text-[#4D4D4D] dark:text-white font-bold">
            {submitted
              ? userStats?.stats?.restaurantOrders?.delivered_orders
              : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b dark:border-[#505050]">
          <span className="text-xs text-[#344B67] dark:text-[#BCBCBC]">Third Party Orders</span>
          <span className="text-sm text-[#4D4D4D] dark:text-white font-bold">
            {submitted
              ? userStats?.stats?.restaurantOrders?.third_party_orders
              : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b dark:border-[#505050]">
          <span className="text-xs text-[#344B67] dark:text-[#BCBCBC]">Paid Orders</span>
          <span className="text-sm text-[#4D4D4D] dark:text-white font-bold">
            {submitted ? userStats?.stats?.restaurantOrders?.order_paid : 0}
          </span>
        </li>

        <li className="flex px-3 py-4 mt-1 justify-between border-b dark:border-[#505050]">
          <span className="text-xs text-[#344B67] dark:text-[#BCBCBC]">Paid Orders Total</span>
          <span className="text-sm text-[#4D4D4D] dark:text-white font-bold">
            NGN{" "}
            {submitted
              ? numberWithCommas(
                  Number(
                    userStats?.stats?.restaurantOrders?.order_paid_amount.toFixed(
                      2
                    )
                  )
                )
              : 0}
          </span>
        </li>
      </ul>
    </div>
  );
}
