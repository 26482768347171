import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import packageFoodType from "./packageSubscriptionFoodOrderTypes";
import { packageSubscriptionFoodOrderService } from "../../services/packageSubscriptionFoodOrder.service";

/**
 * Get all packages
 **/

export const getAllSubscriptionFoodOrders = () => async (dispatch) => {
  try {
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: true });
    const response =
      await packageSubscriptionFoodOrderService.getAllSubscriptionFoodOrders();
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: false });
    dispatch({ type: packageFoodType["ALL_PACKAGES"], payLoad: response });
  } catch (error) {
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all packages no pagination
 **/

export const getAllSubscriptionFoodOrdersNoPagination =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: packageFoodType["DOWNLOADING"], payLoad: true });
      const response =
        await packageSubscriptionFoodOrderService.getAllSubscriptionFoodOrdersNoPagination(
          payload
        );
      dispatch({ type: packageFoodType["DOWNLOADING"], payLoad: false });
      return response;
    } catch (error) {
      dispatch({ type: packageFoodType["DOWNLOADING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Get a package
 **/

export const getASubscriptionFoodOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: true });
    const response =
      await packageSubscriptionFoodOrderService.getASubscriptionFoodOrder(id);
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: false });
    dispatch({
      type: packageFoodType["SINGLE_PACKAGE_ORDER"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search packages orders
 **/

export const searchSubscriptionFoodOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: packageFoodType["SEARCHING"], payLoad: true });
    const response =
      await packageSubscriptionFoodOrderService.searchSubscriptionFoodOrder(
        payload
      );
    dispatch({ type: packageFoodType["SEARCHING"], payLoad: false });
    dispatch({ type: packageFoodType["SEARCH_PACKAGE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: packageFoodType["SEARCHING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter packages
 **/

export const filterSubscriptionFoodAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: packageFoodType["FILTERING"], payLoad: true });
      const response =
        await packageSubscriptionFoodOrderService.filterSubscriptionFoodOrder(
          payload,
          page
        );
      dispatch({ type: packageFoodType["FILTERING"], payLoad: false });
      dispatch({ type: packageFoodType["FILTER_PACKAGE"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: packageFoodType["FILTERING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Search packages
 **/

export const closeSubscriptionFoodOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: packageFoodType["MARKING"], payLoad: true });
    const response =
      await packageSubscriptionFoodOrderService.closeSubscriptionFoodOrder(
        payload
      );
    dispatch({ type: packageFoodType["MARKING"], payLoad: false });
    // dispatch({ type: packageFoodType["SEARCH_PACKAGE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: packageFoodType["MARKING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Ship package order
 **/

export const shipSubscriptionFoodOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: packageFoodType["SHIPPING"], payLoad: true });
    const response =
      await packageSubscriptionFoodOrderService.shipSubscriptionFoodOrder(
        payload
      );
    dispatch({ type: packageFoodType["SHIPPING"], payLoad: false });
    // dispatch({ type: packageFoodType["SEARCH_PACKAGE"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: packageFoodType["SHIPPING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const getMySubscriptionFoodOrder = () => async (dispatch) => {
  try {
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: true });
    const response =
      await packageSubscriptionFoodOrderService.getMySubscriptionFoodOrder();
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: false });
    dispatch({
      type: packageFoodType["MY_PACKAGES"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: packageFoodType["LOADING_PACKAGE"], payLoad: false });
    errorHandler(error, true);
  }
};
