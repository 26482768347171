import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { cleanInput } from "../../../../helpers/cleanInput";
import { Beat } from "../../../../plugins/spinners.plugin";
import {
  showApplication,
  enhanceApplication,
} from "../../../../redux/applicationSettings/applicationAction";

export default function EnhancedApplicationSettings() {
  const dispatch = useDispatch();
  const { updating, settings } = useSelector((state) => state.application);
  const [data, setData] = useState({
    checkout_service_area_status: settings?.checkout_service_area_status,
    checkout_fee_per_meter_status: settings?.checkout_fee_per_meter_status,
    sms_notification_provider: settings?.sms_notification_provider,
    mail_notification_provider: settings?.mail_notification_provider,
    push_notification_provider: settings?.push_notification_provider,
    order_report_mail_status: settings?.order_report_mail_status,
  });

  useEffect(() => {
    dispatch(showApplication());
  }, [dispatch]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleUpdate = async () => {
    cleanInput(data);
    const response = dispatch(enhanceApplication({ ...data }));
    if (response.status === "success") {
      dispatch(showApplication());
    }
  };
  return (
    <>
      <div className="w-100 mx-auto">
        <div className="mx-auto bg-white dark:bg-[#121212] sm:w-[630px] w-full mt-10 sm:mt-0 sm:border p-6 sm:border-gray-200 dark:border-none">
          <div className="border-b-2 dark:border-[#505050] py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 dark:text-white flex justify-center ">
              Enhanced Application Settings
            </p>
          </div>
          <form>
            <div className=" sm:px-10 mb-10">
              <Input.Select
                title={"Checkout Using Service Area Status"}
                name="checkout_service_area_status"
                htmlFor={"checkout_service_area_status"}
                defaultValue={settings?.checkout_service_area_status || ""}
                onChange={handleChange}
              >
                <option>Select Status</option>
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>
              <Input.Select
                title={"Checkout Using Fee Per Meter Status"}
                name="checkout_fee_per_meter_status"
                htmlFor={"checkout_fee_per_meter_status"}
                defaultValue={settings?.checkout_fee_per_meter_status || ""}
                onChange={handleChange}
              >
                <option>Select Status</option>
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>
              <Input.Select
                title={"Sms Notification Provider"}
                name="sms_notification_provider"
                htmlFor={"sms_notification_provider"}
                defaultValue={settings?.sms_notification_provider || ""}
                onChange={handleChange}
              >
                <option>Select Provider</option>
                <option value={"termii"}>Termii</option>
                <option value={"twilio"}>Twilio</option>
              </Input.Select>
              <Input.Select
                title={"Mail Notification Provider"}
                name="mail_notification_provider"
                htmlFor={"mail_notification_provider"}
                defaultValue={settings?.mail_notification_provider || ""}
                onChange={handleChange}
              >
                <option>Select Provider</option>
                <option value={"default"}>Default</option>
                <option value={"xend"}>Xend</option>
              </Input.Select>
              <Input.Select
                title={"Push Notification Provider"}
                name="push_notification_provider"
                htmlFor={"push_notification_provider"}
                defaultValue={settings?.push_notification_provider || ""}
                onChange={handleChange}
              >
                <option>Select Provider</option>
                <option value={"default"}>Default</option>
                <option value={"expo"}>Expo</option>
                <option value={"firebase"}>Firebase</option>
              </Input.Select>
              <Input.Select
                title={"Order Report Mail Status"}
                name="order_report_mail_status"
                htmlFor={"order_report_mail_status"}
                defaultValue={settings?.order_report_mail_status || ""}
                onChange={handleChange}
              >
                <option>Select Status</option>
                <option value={"available"}>Available</option>
                <option value={"unavailable"}>Unavailable</option>
              </Input.Select>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center mx-auto">
        <Button.Primary
          disabled={updating}
          title={updating ? <Beat color={"#ffffff"} /> : "Update Settings"}
          className={"px-4 rounded-sm sm:mt-10"}
          onClick={handleUpdate}
        />
      </div>
    </>
  );
}
