import React, { useState } from "react";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import Background from "../../../../assets/images/pictures/create-business.jpeg";

export default function CreateBusinessDetails({
  handleChange,
  data,
  handleSubmit,
  submitted,
  Location,
  locationId,
  locationList,
  setLocationList,
  setLocationId,
  handleRegion,
  handleNext,
  business_phone,
  setBusinessPhone,
}) {
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const {
    business_email,
    business_name,
    business_city,
    business_address,
    business_state,
    business_country,
    business_description,
  } = data;
  const [locationLoader, setLocationLoader] = useState(false);
  return (
    <div
      className="pt-4 sm:pt-2 sm:flex items-stretch items-center py-4 max-w-7xl w-full mx-auto sm:px-6 lg:px-6"
      data-test="businessComponent"
    >
      <div className="dark:bg-[#121212] sm:max-w-[550px] flex-1 px-4 sm:px-10 py-10 sm:rounded-tl-[25px] sm:rounded-bl-[25px] sm:border dark:border-none">
        <div className="mb-4">
          <h3 className="text-3xl mb-3 font-semibold text-black dark:text-white">
            Let's work together!
          </h3>
          <p>
            Ogwugo is your dedicated business service agency, helping you
            automate daily operations and grow your customer base. Let’s bring
            your vision to life! 🚀
          </p>
        </div>
        <div className="w-full py-4">
          <form
            name="form"
            id="business-form"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div>
              <div
                className={
                  "mt-4" + (submitted && business_name ? " border-red-500" : "")
                }
              >
                <Input.Label
                  title={"Business Name *"}
                  htmlFor={"business_name"}
                  type={"text"}
                  name={"business_name"}
                  value={business_name}
                  onChange={handleChange}
                  placeholder={"Enter business name"}
                />
                {submitted && !business_name && (
                  <div className="text-xs text-red-500">
                    Business name is required
                  </div>
                )}
              </div>

              <div
                className={
                  "mt-4" +
                  (submitted && business_address ? " border-red-500" : "")
                }
              >
                <Input.Label
                  title={"Business Address *"}
                  htmlFor={"business_address"}
                  type={"text"}
                  value={business_address}
                  name={"business_address"}
                  placeholder={"Enter business address"}
                  onChange={handleChange}
                />
                {submitted && !business_address && (
                  <div className="text-xs text-red-500">
                    Business address is required
                  </div>
                )}
              </div>

              <div className="grid sm:grid-cols-2 gap-4">
                <div
                  className={
                    submitted && business_phone ? " border-red-500" : ""
                  }
                >
                  <Input.Phone
                    defaultCountry="NG"
                    title={"Business phone *"}
                    htmlFor={"business_phone"}
                    onChange={setBusinessPhone}
                    value={business_phone}
                  />
                  {submitted && !business_phone && (
                    <div className="text-xs text-red-500">
                      Business phone is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    submitted && business_email ? " border-red-500" : ""
                  }
                >
                  <Input.Label
                    title={"Business Email *"}
                    htmlFor={"business_email"}
                    type={"email"}
                    value={business_email}
                    name={"business_email"}
                    onChange={handleChange}
                    placeholder={"Enter business email"}
                  />
                  {submitted && !business_email && (
                    <div className="text-xs text-red-500">
                      Business email is required
                    </div>
                  )}
                </div>
              </div>

              <div
                className={
                  "mt-4" +
                  (submitted && business_description ? " border-red-500" : "")
                }
              >
                <Input.TextArea
                  title={"Business Description "}
                  htmlFor={"business_description"}
                  type={"text"}
                  value={business_description}
                  name={"business_description"}
                  onChange={handleChange}
                  placeholder={"Describe your business to us"}
                />
              </div>
              {/* Here, we utilize the location component to get the location data */}
              <Location
                country_id={locationId.country_id}
                state_id={locationId.state_id}
                city_id={locationId.city_id}
                loadingLocation={(value) => {
                  setLocationLoader(value);
                }}
                countryList={(value) => {
                  setLocationList({
                    ...locationList,
                    countryList: value,
                  });
                }}
                stateList={(value) => {
                  setLocationList({
                    ...locationList,
                    stateList: value,
                  });
                }}
                cityList={(value) => {
                  setLocationList({ ...locationList, cityList: value });
                }}
              />
              <div
                className={
                  submitted && business_country ? " border-red-500" : ""
                }
              >
                <label
                  className="block text-sm font-medium text-[#151515] dark:text-white"
                  htmlFor="business_country"
                >
                  Country*
                </label>
                <select
                  name="business_country"
                  className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[#121212]  focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600  sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  onChange={(e) => {
                    setLocationId({
                      ...locationId,
                      country_id: fromJson(e.target.value).id,
                    });
                    handleRegion(e);
                  }}
                >
                  <option>
                    {business_country ? business_country : "Select country"}
                  </option>

                  {locationLoader ? (
                    <option>Loading...</option>
                  ) : (
                    locationList.countryList &&
                    locationList.countryList.length > 0 &&
                    locationList.countryList.map((item, key) => {
                      return (
                        <option
                          key={key}
                          value={toJson({
                            name: item.name,
                            id: item.id,
                          })}
                        >
                          {item.name}
                        </option>
                      );
                    })
                  )}
                </select>

                {submitted && !business_country && (
                  <div className="text-xs text-red-500">
                    Business country is required
                  </div>
                )}
              </div>
              <div className="grid sm:grid-cols-2 gap-4">
                <div
                  className={
                    submitted && business_state ? " border-red-500" : ""
                  }
                >
                  <label
                    htmlFor="business_state"
                    className="block text-sm font-medium text-[#151515] dark:text-white"
                  >
                    {" "}
                    State*
                  </label>
                  <select
                    name="business_state"
                    className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[#121212] focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>
                      {business_state ? business_state : "Select a state"}
                    </option>

                    {locationLoader ? (
                      <option>Loading...</option>
                    ) : (
                      locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })
                    )}
                  </select>
                  {submitted && !business_state && (
                    <div className="text-xs text-red-500">
                      Business state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    submitted && business_city ? " border-red-500" : ""
                  }
                >
                  <label
                    htmlFor="business_city"
                    className="block text-sm font-medium text-[#151515] dark:text-white"
                  >
                    City*{" "}
                  </label>
                  <select
                    name="business_city"
                    className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] dark:bg-[#121212] focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600  sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>
                      {business_city ? business_city : "Select a city"}
                    </option>
                    {locationLoader ? (
                      <option>Loading...</option>
                    ) : (
                      locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })
                    )}
                  </select>
                  {submitted && !business_city && (
                    <div className="text-xs text-red-500">
                      Business city is required
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="items-center flex justify-between gap-4 sm:mt-10">
          <div className="items-center flex gap-2">
            <span className="w-[10px] h-[10px] rounded-full inline-block bg-primary"></span>
            <span className="w-[10px] h-[10px] rounded-full inline-block bg-[#A39D9D] dark:bg-white"></span>
            <span className="text-sm ml-4">Phase 1 of 2</span>
          </div>
          <div>
            <Button.Primary
              onClick={handleNext}
              title={"Continue"}
              className={"mx-auto py-4 min-w-[180px]"}
            />
          </div>
        </div>
      </div>
      <div
        className="hidden sm:block w-full flex-1 rounded-tr-[25px] rounded-br-[25px] bg-cover bg-center"
        style={{ backgroundImage: `url(${Background})` }}
      />
    </div>
  );
}
