import rewardType from "./rewardTypes";

const initialState = {
  isLoading: false,
  approving: false,
  searching: false,
  filtering: false,
  downloading: false,
  updating: false,
  deleting: false,
  allRewards: { data: [] },
  allRewardsNoPagination: {},
  singleReward: {},
  myReward: {},
  search: [],
};

export default function rewardReducer(state = initialState, action) {
  switch (action.type) {
    case rewardType.LOADING_REWARDS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case rewardType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case rewardType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    case rewardType.ALL_REWARDS:
      let newData = {};
      if (action.payLoad?.data === undefined) {
        newData = {};
      } else {
        newData = action.payLoad?.data;
      }
      return {
        ...state,
        allRewards: newData,
      };

    case rewardType.SINGLE_REWARD:
      return {
        ...state,
        singleReward: action.payLoad?.data,
      };

    case rewardType.GET_MY_REWARD:
      return {
        ...state,
        myReward: action.payLoad?.data,
      };

    case rewardType.STORE_REWARD:
      const prevReward = state?.allRewards;
      let newReward;
      if (prevReward?.data?.length > 0) {
        newReward = {
          ...state.allRewards,
          data: [...state.allRewards?.data, action.payLoad?.data],
        };
      } else {
        newReward = action.payLoad?.data;
      }
      return {
        ...state,
        allRewards: newReward,
      };

    case rewardType.APPROVING_REWARD:
      return {
        ...state,
        approving: action.payLoad,
      };

    case rewardType.APPROVE_REWARD_MANAGEMENT:
      let approved = {
        ...state.singleReward,
        management_approved: true,
      };
      return {
        ...state,
        singleReward: approved,
      };

    case rewardType.UNAPPROVE_REWARD_MANAGEMENT:
      let unapproved = {
        ...state.singleReward,
        management_approved: false,
      };
      return {
        ...state,
        singleReward: unapproved,
      };

    case rewardType.CLAIM_REWARD:
      let claimed = {
        ...state.singleReward,
        management_approved: false,
      };
      return {
        ...state,
        singleReward: claimed,
      };

    case rewardType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case rewardType.SEARCH_REWARDS:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case rewardType.FILTERING_REWARDS:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case rewardType.FILTER_REWARDS:
      return {
        ...state,
        allRewards: action.payLoad?.data,
      };

    case rewardType.ALL_REWARDS_NO_PAGINATION:
      return {
        ...state,
        allRewardsNoPagination: action.payLoad?.data,
      };

    case rewardType.DELETING_FOOD:
      return {
        ...state,
        deleting: action.payLoad,
      };

    default:
      return state;
  }
}
