import React, { useMemo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Table from "../../../../components/tables/tableCols2";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import Settings from "../../../../assets/images/icons/settingsEmpty.svg";
import { Pagination } from "../../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import KYCFilterDropDown from '../../../../components/dropDown/filter/kycFilter';
import {
  filterHumanVerificationAction,
  searchHumanVerificationAction,
} from "../../../../redux/humanVerification/humanVerificationActions";

export default function All() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();

  const { filtering, allVerification, searching } = useSelector(
    (state) => state.humanVerification
  );

  // console.log("allVerification", allVerification);
  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  const [payLoad] = useState({
    end_date: new Date(),
  });

  useEffect(() => {
    dispatch(filterHumanVerificationAction(payLoad, page));
  }, [dispatch, page, payLoad]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((verification, index) => {
        let singleRowArray = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          card: verification.document_type,
          number: verification.document_number,
          status: verification.status,
          date: moment(verification.created_at).format("MMM DD, YYYY"),
          address: "address",
          verificationData: verification,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allVerification?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVerification]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };
  const showSearch = (search) => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allVerification?.data);
      setData(dataSet);
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchHumanVerificationAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  function goToSinglePage(params) {
    let id = params?.verificationData?.user_id;
    navigate(`/admin/settings/kyc-verification/single-user/${id}`);
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "num",
          },
          {
            Header: "Identity Card",
            accessor: "card",
          },
          {
            Header: "ID Card Number",
            accessor: "number",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Created On",
            accessor: "date",
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <div>
        <TableFilter
          results={allVerification?.per_page}
          totalResults={allVerification?.total}
          onChange={handleChange}
          title={"KYC Details"}
          payLoad={payLoad}
          page={page}
          filter={
            <AllFiltersModal
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            >
              <KYCFilterDropDown
                isFilterOpen={isFilterOpen}
                setFilterState={setFilterState}
                payLoad={payLoad}
                page={page}
              />
            </AllFiltersModal>
          }
          handleKeyDown={handleKeyDown}
          searchPlaceholder={"user id, document type, verified by..."}
        ></TableFilter>

        <div className="border-b border-[#E4E4F3]" />
        <div className="bg-white dark:bg-[#121212] rounded-md py-4 ">
          {filtering || searching ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={goToSinglePage}
              rowClass="hover:shadow-md cursor-pointer"
            />
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                <img src={Settings} alt="settings" />
              </div>
              <p className="font-bold text-xl my-3">KYC Verification</p>
              <p className="text-sm text-gray-500">
                You do not have any KYC entries yet
              </p>
            </div>
          )}
        </div>

        <Pagination
          data={allVerification}
          route={"/admin/settings/kyc-verification/all"}
        />
      </div>
    </>
  );
}
