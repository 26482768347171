import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { Chart } from "react-google-charts";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { getUserFromCookies } from "../../../../utils/Auth";
import RestaurantStats from "./stats/RestaurantStats";
import EmptyState from "../../../../assets/images/emptyState/stats.svg";
import { getUserStatistics } from "../../../../redux/statistics/statisticsAction";

export const options = {
  curveType: "function",
  legend: "none",
  height: 280,
  series: {
    0: {
      color: "#01d120",
    },
    1: {
      color: "#ce2600",
    },
  },
};

export default function FeatureStatistics() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = getUserFromCookies();
  let business = Object.keys(user?.groups)[0];
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    end_date: "",
    start_date: "",
    user_id: id,
    business_id: business,
  });
  const { end_date, start_date } = data;
  const { userStats, isLoading } = useSelector((state) => state.statistics);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    setSubmitted(true);
    dispatch(getUserStatistics(data));
  };



  return (
    <div data-test="singleOrder-page">
      <div className="grid grid-cols-1 sm:grid-cols-12 gap-8">
        {/* Grid 1 */}
        <div className="sm:col-span-5">
          <div className="border border-[#E4E4F3] dark:border-none h-full bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-center">
              <h3 className="text-lg font-medium">Check statistics</h3>
            </div>
            <div className="flex flex-col 2xl:flex-row justify-between items-end py-6 mt-6">
              <div className="sm:flex w-full sm:w-auto gap-3">
                <Input.DatePicker
                  title={"Date From :"}
                  htmlFor={"end_date"}
                  name={"start_date"}
                  value={start_date}
                  onChange={handleChange}
                />
                <Input.DatePicker
                  title={"Date To :"}
                  htmlFor={"end_date"}
                  name={"end_date"}
                  value={end_date}
                  onChange={handleChange}
                />
              </div>

              <div>
                <Button.Primary
                  title={isLoading ? <Beat color={"#ffffff"} /> : "Check"}
                  disabled={isLoading || (!start_date && !end_date)}
                  className="w-full mb-4 px-5"
                  type={"button"}
                  onClick={handleSubmit}
                />
              </div>
            </div>

            <RestaurantStats userStats={userStats} submitted={submitted} />
          </div>
        </div>
        {/* Grid 2 */}
        <div className="sm:col-span-7">
          <div className="border border-[#E4E4F3] dark:border-none h-full bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-center">
              <h3 className="text-lg font-medium">Overview/Summary</h3>
            </div>

            <div className="h-full flex items-center justify-center">
              {/* <div className="w-full">
                <h2 className="my-2 text-[#1D2129] text-xl">
                  Total Orders vs Month
                </h2>
                <Chart
                  chartType="LineChart"
                  width="100%"
                  data={[
                    ["Age", "Weight"],
                    [4, 5.5],
                    [8, 12],
                  ]}
                  options={options}
                />
              </div> */}
              <div className="py-24 text-center">
                <div className="flex justify-center">
                  <img src={EmptyState} className="w-auto" alt="no data" />
                </div>
                <h3 className="mt-5 text-xl font-bold">Stats</h3>
                <p className="mt-2 text-sm text-[#9CA3AF]">
                  You do not have any report records yet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
