import authType from "./contactTypes";

const initialState = {
  isLoading: false,
  searching: false,
  filtering: false,
  downloading: false,
  user: {},
  allUsers: [],
  searchContact: [],
  singleUser: {},
  userRoles: {},
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_CONTACT:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.ALL_CONTACTS:
      return {
        ...state,
        allUsers: action.payLoad?.data,
      };

    case authType.GET_CONTACT:
      return {
        ...state,
        user: action.payLoad?.data,
      };

    case authType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_CONTACTS:
      return {
        ...state,
        searchUser: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.FILTER_CONTACTS:
      return {
        ...state,
        allUsers: action.payLoad?.data,
      };

    case authType.SINGLE_CONTACT:
      return {
        ...state,
        singleUser: action.payLoad?.data,
      };

    case authType.SHOW_CONTACT_ROLES:
      return {
        ...state,
        userRoles: action.payLoad?.data,
      };
    default:
      return state;
  }
}
