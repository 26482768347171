import errorHandler from "../../handlers/errorHandler";
// import successHandler from "../../handlers/successHandler";
import { userService } from "../../services/user.service";
import userType from "./userTypes";

/**
 * Get all Users
 **/

export const getAllUsersAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: true });
    const response = await userService.getAllUsers(page);
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: userType["ALL_USERS"], payLoad: response });
  } catch (error) {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all Users
 **/

export const getAllUsersNoPagination = (payload) => async (dispatch) => {
  try {
    dispatch({ type: userType["DOWNLOADING"], payLoad: true });
    const response = await userService.getAllUsersNoPagination(payload);
    dispatch({ type: userType["DOWNLOADING"], payLoad: false });
    // dispatch({ type: userType["ALL_USERS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: userType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a User
 **/

export const getAUserAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: true });
    const response = await userService.getAUser(id);
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: userType["SINGLE_USER"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search all Users
 **/

export const searchUsersAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: userType["SEARCHING"], payLoad: true });
    const response = await userService.searchUsers(payload);
    dispatch({ type: userType["SEARCHING"], payLoad: false });
    dispatch({ type: userType["SEARCH_USERS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: userType["SEARCHING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter all Users
 **/

export const filterUsersAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: userType["FILTERING"], payLoad: true });
    const response = await userService.filterUsers(payload, page);
    dispatch({ type: userType["FILTERING"], payLoad: false });
    dispatch({ type: userType["FILTER_USERS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: userType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const getAUsersAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: userType["FILTERING"], payLoad: true });
    const response = await userService.getAUser(payload);
    dispatch({ type: userType["FILTERING"], payLoad: false });
    dispatch({ type: userType["GET_USER"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: userType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const DeleteAUsersAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: true });
    const response = await userService.deleteAUser(payload);
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: userType["GET_USER"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

export const getUserWithRelationAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: userType["LOADING_AUTH"], payLoad: true });
      const response = await userService.getUserWithRelation(payload, page);
      dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
      dispatch({ type: userType["ALL_USERS"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Edit a User
 **/

export const editAUserAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: true });
    const response = await userService.editAUser(payload, page);
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    // dispatch({ type: userType["FILTER_USERS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter all Users
 **/

export const showUserRolesAction = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: true });
    const response = await userService.showUserRoles(user_id);
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: userType["SHOW_USER_ROLES"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: userType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Check a User
 **/

export const checkAUserAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: userType["CHECKING"], payLoad: true });
    const response = await userService.checkAUser(payload, page);
    dispatch({ type: userType["CHECKING"], payLoad: false });
    return response;
  } catch (error) {
    dispatch({ type: userType["CHECKING"], payLoad: false });
  }
};
