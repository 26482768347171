import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApprovedCatalogues from "./approved";
import UnapprovedCatalogues from "./unapproved";

const tops = [
  { name: "Approved", href: "approved" },
  { name: "Unapproved", href: "unapproved" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Catalogue() {
  const navigate = useNavigate();
  const DEFAULT_ACTIVE_TAB = "approved";
  const { active_tab } = useParams();
  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/catalogue/${DEFAULT_ACTIVE_TAB}/1`);
    }
  });

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/catalogue/${tab.href}/1`);
    }
  };

  return (
    <div className="business-page">
      <div className="sm:block">
        <div className="border-b border-gray-200 dark:border-none">
          <nav
            className="-mb-px flex space-x-8 overflow-x-scroll sm:overflow-hidden"
            aria-label="Tabs"
          >
            {tops.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {active_tab === "approved" && <ApprovedCatalogues />}
        {active_tab === "unapproved" && <UnapprovedCatalogues />}
      </div>
    </div>
  );
}
