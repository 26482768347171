import authType from "./addressTypes";

const initialState = {
  isLoading: false,
  creating: false,
  updating: false,
  deleting: false,
  myAddresses: [],
  defaultAddress: {},
  allAddresses: {},
};

export default function addressReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_ADDRESS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.CREATING:
      return {
        ...state,
        creating: action.payLoad,
      };

    case authType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    case authType.DELETING:
      return {
        ...state,
        deleting: action.payLoad,
      };

    case authType.GET_MY_ADDRESSES:
      return {
        ...state,
        myAddresses: action.payLoad?.data,
      };

    case authType.ADD_ADDRESS:
      return {
        ...state,
        myAddresses: {
          ...state?.myAddresses,
          data: [...state?.myAddresses?.data, action.payLoad?.data],
        },
      };

    case authType.DELETE_ADDRESS:
      let delete_address = state?.myAddresses?.data?.filter(
        (item) => item.id !== action.payLoad?.id
      );
      return {
        ...state,
        myAddresses: {
          ...state?.myAddresses,
          data: [...delete_address],
        },
      };

    case authType.UPDATE_ADDRESS:
      let index = state?.myAddresses?.data?.findIndex(
        (item) => item.id === action.payLoad.id
      );
      const editAddresses = [...state?.myAddresses?.data];
      editAddresses[index] = {
        ...editAddresses[index],
        ...action.payLoad?.data,
      };
      return {
        ...state,
        myAddresses: {
          ...state?.myAddresses,
          data: [...editAddresses],
        },
      };
    case authType.DEFAULT_ADDRESS:
      return {
        ...state,
        defaultAddress: action.payLoad,
      };

    case authType.FILTER_ADDRESSES:
      return {
        ...state,
        allAddresses: action.payLoad?.data,
      };

    default:
      return state;
  }
}
