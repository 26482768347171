// Application types

const CONTACT = {
  LOADING_CONTACT: "LOADING_CONTACT",
  ALL_CONTACTS: "ALL_CONTACTS",
  SEARCHING: "SEARCHING",
  SEARCH_CONTACTS: "SEARCH_CONTACTS",
  FILTERING: "FILTERING",
  FILTER_CONTACTS: "FILTER_CONTACTS",
  GET_CONTACT: "GET_CONTACT",
  DOWNLOADING: "DOWNLOADING",
  SINGLE_CONTACT: "SINGLE_CONTACT",
  SHOW_CONTACT_ROLES: "SHOW_CONTACT_ROLES",
};

export default CONTACT;
