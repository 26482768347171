import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../../../../components/buttons/buttons";
import ViewFeature from "../../../../components/modals/feature/viewFeature";
import { getSingleFeatureProduct } from "../../../../redux/feature/featureActions";
import { getARestaurantAction } from "../../../../redux/restaurant/restaurantActions";
import { getSingleStore } from "../../../../redux/stores/storeActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function diff_hours(end_date, start_date, duration) {
  const today = new Date();
  const futureDate = new Date(end_date);
  //check if end date exists then calculate the hours difference
  if (end_date && futureDate > today) {
    const timeDiff = Math.abs(futureDate.getTime() - today.getTime());
    const hoursDiff = Math.ceil(timeDiff / (1000 * 3600));
    return hoursDiff;
  } else if (end_date === null && start_date === null) {
    //if inactive i.e start and end dates = null, return the duration
    return duration;
  } else {
    return 0;
  }
}

export default function FeatureReportDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const featured = location.state.featured;
  const featured_id = featured.id;

  const [open, setOpen] = useState(false);
  const { singleFeatured } = useSelector((state) => state.feature);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { singleStore } = useSelector((state) => state.store);

  useEffect(() => {
    dispatch(getSingleFeatureProduct(featured_id));
  }, [dispatch, featured, featured_id]);

  useEffect(() => {
    if (singleFeatured?.product?.restaurant_id) {
      dispatch(getARestaurantAction(singleFeatured?.product?.restaurant_id));
    } else if (singleFeatured?.product?.store_id) {
      dispatch(getSingleStore(singleFeatured?.product?.store_id));
    }
  }, [dispatch, singleFeatured?.product]);

  function goToSingleMerchandise(merchandise) {
    navigate(`/admin/merchandise/${merchandise?.id}`, {
      state: { merchandise: merchandise },
    });
  }

  function goToSingleFood(food) {
    navigate(`/admin/food/${food?.id}`, {
      state: { food: food },
    });
  }

  function goToSingleRestaurant(food) {
    navigate(`/admin/restaurants/${food?.restaurant_id}`, {});
  }

  function goToSingleStore(merchandise) {
    navigate(`/admin/stores/${merchandise?.store_id}`, {});
  }

  return (
    <div data-test="singleFeature-page">
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
        <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Feature Details</h3>
          <div className="hidden sm:flex gap-4">
            <Button.Primary
              type={"button"}
              title={"View Feature"}
              onClick={() => setOpen(true)}
            />
            <ViewFeature
              feature={singleFeatured}
              isOpen={open}
              setOpen={setOpen}
            />
          </div>
        </div>

        <div className="grid  grid-cols-2 gap-6 sm:grid-cols-4 sm:gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-4">Cost</label>
            <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
              NGN {numberWithCommas(singleFeatured?.featured_amount)}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
            <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
              {singleFeatured?.status}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Duration</label>
            <p className="text-[#151515] dark:text-white text-sm font-bold mt-3">
              {singleFeatured?.featured_duration_in_hours} hours
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Time Left</label>
            <p className="text-[#151515] dark:text-white text-sm font-bold mt-3">
              {diff_hours(
                singleFeatured?.featured_end_date,
                singleFeatured?.featured_start_date,
                singleFeatured?.featured_duration_in_hours
              )}{" "}
              hours
            </p>
          </div>
        </div>
      </div>

      {singleFeatured?.product?.food_amount && (
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">
              Food: {singleFeatured?.product?.food_name}
            </h3>
            <div className="hidden sm:flex gap-4">
              <Button.Primary
                title={"View Food"}
                onClick={() => goToSingleFood(singleFeatured?.product)}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 sm:grid-cols-4 sm:gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Food ID</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {singleFeatured?.product?.id}
              </p>
            </div>

            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Amount</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                NGN {numberWithCommas(singleFeatured?.product?.food_amount)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Quantity</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {numberWithCommas(singleFeatured?.product?.stock)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {singleFeatured?.product?.flag}
              </p>
            </div>

            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Created On</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {moment(singleFeatured?.product?.created_at).format(
                  "DD, MMM YYYY, HH:MM A"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold capitalize">
                {singleFeatured?.product?.visibility}
              </p>
            </div>
          </div>
        </div>
      )}
      {singleFeatured?.product?.merchandise_amount && (
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">
              Merchandise: {singleFeatured?.product?.merchandise_name}
            </h3>
            <div className="hidden sm:flex gap-4">
              <Button.Primary
                title={"View Merchandise"}
                onClick={() => goToSingleMerchandise(singleFeatured?.product)}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 sm:grid-cols-5 sm:gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Merchandise ID
                <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                  {singleFeatured?.product?.id}
                </p>
              </label>
            </div>

            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Amount</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                NGN{" "}
                {numberWithCommas(
                  singleFeatured?.merchandise?.merchandise_amount
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Quantity</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {numberWithCommas(singleFeatured?.product?.stock)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {singleFeatured?.product?.flag}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Approve/Unapprove
              </label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {singleFeatured?.product?.management_approved ? (
                  <span className="text-[#00D220]">Approved</span>
                ) : (
                  <span className="text-primary">Unapproved</span>
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Created On</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {moment(singleFeatured?.product?.created_at).format(
                  "DD, MMM YYYY, HH:MM A"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold capitalize">
                {singleFeatured?.product?.visibility}
              </p>
            </div>
          </div>
        </div>
      )}

      {singleFeatured?.product?.food_amount && (
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Restaurant Details</h3>
            <div className="hidden sm:flex gap-4">
              <Button.Primary
                title={"View Restaurant"}
                onClick={() => goToSingleRestaurant(singleFeatured?.product)}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-4 sm:gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Store Name</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {restaurant?.restaurant_name}
              </p>
            </div>

            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold break-words">
                {restaurant?.restaurant_email}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Phone Number
              </label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {restaurant?.restaurant_phone}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {restaurant?.restaurant_city}
              </p>
            </div>
          </div>
        </div>
      )}
      {singleFeatured?.product?.merchandise_amount && (
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] dark:border-[#505050] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Store Details</h3>
            <div className="hidden sm:flex gap-4">
              <Button.Primary
                title={"View Store"}
                onClick={() => goToSingleStore(singleFeatured?.product)}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 sm:grid-cols-4 sm:gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Store Name</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {singleStore?.store_name}
              </p>
            </div>

            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold break-words">
                {singleStore?.store_email}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                Phone Number
              </label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {singleStore?.store_phone}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
              <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
                {singleStore?.store_address}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
